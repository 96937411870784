import React from "react";
import moment from 'moment';
import orderImg from '../../assets/images/order.png';
import {orderStatusColor} from './../../config/color';
import labIcon from '../../assets/images/icon/test-results.png';

function OrderRow(props){
    return (
        <>
            <div className="card mb-15 p-2 deal-listing">
                <a href={`/my-order/${props.page_type}/${props.data.order_id}`}>
                    <div className="row">
                        <div className="col-md-1 col-3">
                                <div className="deal-image">
                                    {props.page_type === 'lab'
                                        ?
                                        <img className="img-thumbnail" src={labIcon} alt="Lab Test" />
                                        :
                                        <img className="img-thumbnail" src={orderImg} alt="Medicine Order" />
                                    }
                                </div>
                        </div>
                        <div className="col-md-11 col-9">
                            <div className="row">
                                <div className="col-md-8 col-12 p-0">
                                    {/* <a href={`/my-order/${props.data.sub_order_id}`}> */}
                                       <h6 className="mb-0"><span className="link-custom">Order Id: </span>#{props.page_type === 'lab' ? 'OL':'O'}{props.data.order_id}</h6>
                                    {/* </a> */}
                                </div>
                                <div className="col-md-4 col-12 p-0">
                                    <p className="link-custom"><span className="fade-title">Placed on:</span> {moment(props.data.created_at).format("Do MMM YYYY")}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-8 col-12 p-0">
                                    <h4 className="product-price pt-2">₹ {Math.round(props.data.total_amount)} <span className="fade-title text-start link mt-0">({props.data.sub_order_list_count} {props.data.sub_order_list_count === 1 ? 'Item' : 'Items'})</span></h4>
                                   
                                </div>
                                <div className="col-md-4 col-12 p-0">
                                    <p className="link-custom"><span className="fade-title">Status:</span> <span style={{color: orderStatusColor[props.data.order_status_id]}}>{props.data.status_order}</span></p>
                                    {props.data.order_status_id === 4
                                        && props.data.tracking_link
                                        && (
                                            <a href={props.data.tracking_link} target="_blank" rel="noreferrer">
                                                <p><i className="fa fa fa-external-link"></i> &nbsp;Track Order</p>
                                            </a>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </>
    );
}

export default OrderRow;