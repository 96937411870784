import React from "react";
import {
    Card,
    Col,
    Row
  } from 'react-bootstrap';
import {SetMetaData} from './../../helper/page-title';

function TermsConditions(){
    SetMetaData("Terms & Conditions", "", "");

    return (
        <div className="container">
        <Row className="justify-content-center">
            <Col md="9" sm="12">
                <Card className="m-2 mt-4 mb-5">
                    <div className="p-3 static-pages">
                        <h5 className="text-center pt-3">Terms & Conditions</h5>
                        <br/>
                        <p>{process.env.REACT_APP_NAME} (hereinafter referred to as "{process.env.REACT_APP_SHORT_NAME}", "Platform", “App”,  "we", "us", or "our") provides a platform to Order/Buy Medicines & Book Lab Tests.</p>
                        <p>To use our Services on our website ({process.env.REACT_APP_DOMAIN}), you must agree to the following terms and conditions. So, please read the following terms and conditions very carefully before using our Services. Violation of any of these terms in any manner could lead to legal actions, blocking of your account or holding your cashback. If you do not agree to these terms, you may not use the Services.</p>
                        <p>By visiting our Website, you agree that you have read, confirmed and agree to the following terms and conditions.</p>
                        <br/>

                        <p><b>Eligibility:</b> You should have completed 18 years of age and be competent to enter into a contract under Indian contract law. If You are under 18 years of age who is intending to avail the services of the Platform should do so through Your legal guardian in accordance with applicable law.</p>
                        <p>If You are using the Platform on behalf of any corporation, company, institution, association or other entity, the person should be authorized by such corporation, company, institution, association or other entity to use and access the App.</p>
                        <p>{process.env.REACT_APP_NAME} reserves the right to refuse access to use the services offered at the Platform to new users or to terminate access granted to existing Users at any time without any reasons for doing so.</p>
                        <br/>

                        <p><b>Registration and Login:</b> To access some part of the Platform, you may require to register with Us (create an account). While registering, you need to submit some information about yourself. You warrant that all the information provided by you to us is accurate and up-to-date. Also, You must always keep all this information up-to-date.</p>
                        <p>Please keep your email address and Mobile Number always upto date, as we will communicate important information at the email address or Mobile Number provided by you and may require your email address/Mobile Number for re-authentication purposes.</p>
                        <p>When you register yourself with us, you create your password for your account. We keep your password secure and in an encrypted format. We never ask for your password over call or email. You are solely responsible for maintaining the confidentiality and security of your password and account. You understand and agree that you are individually and fully responsible for all actions and postings made from your Account. Notify us as soon as you become aware of any unauthorized use of your account.</p>
                        <p>We do not charge any fee for registration. By registering and using our website, you confirm that your age is 18 or above. If you are the parent or legal guardian of a child under 18 years of age, you provide your consent to your child’s registration with our Website and you agree to be bound by the terms and conditions of our website.</p>
                        <p>You are not allowed to create more than one account.</p>
                        <br/>

                        <p><b>Your Account:</b> You can close your account at any time, you must write an email to us at <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}><b>{process.env.REACT_APP_SUPPORT_EMAIL}</b></a> from your registered email address. Your account will be terminated if you violate any rules or your account is reported by others after proper inspection without any prior notice.</p>
                        <br/>

                        <p><b>Privacy:</b> We respect the privacy of the users and we request all users to respect the privacy of other users. The Privacy Policy included in this website at the bottom (footer) is a part of these Terms.</p>
                        <br/>

                        <p><b>Payment Policy:</b> Payment is an integral part of an online shopping experience. All payments shall be collected through secured payment options. We are using Paytm Payment Gateway for all the Payment Transactions, please read and abide by <a href="https://paytm.com/about-us/our-policies/" target={"_blank"} rel="noreferrer" className="link-danger">Paytm`s Terms and Conditions.</a></p>
                        <p>All payments for purchases made by You on the Platform shall only be in Indian Rupees. There may be limits to the amount of Cash on Delivery payments based on pin codes or the State in India where delivery is to be made. {process.env.REACT_APP_NAME} reserves the right to withdraw or block or limit Your purchases using Cash on Delivery basis transaction history.</p>
                        <p>Issues with Payments - Pending Payments:</p>
                        <p>Payments can sometimes fail or remain in the pending stage. Some of the reasons could be:</p>
                        <div className="description">
                            <ul>
                                <li>Incorrect debit or credit card details are entered.</li>
                                <li>Card may be blocked or card details are no longer valid or need to be updated.</li>
                                <li>The page is accidentally closed or refreshed or backspace is pressed while the transaction is being processed by Your bank.</li>
                                <li>The connection between {process.env.REACT_APP_NAME} and Your bank or issuer failed due to some technical reasons and the transaction could not be completed.</li>
                                <li> Incorrect login or password details are entered.</li>
                                <li><p>Payment amount exceeds the limit mutually agreed to between You and Your bank or issuer.</p></li>
                            </ul>
                        </div>
                        <p>If You face any of the above payment issues, try placing the order again in a short while or contact Your bank or issuer for technical assistance. {process.env.REACT_APP_NAME} is not liable for any loss or inconvenience caused to You when making payment or for any wrongful debits to Your cards or net banking accounts.</p><br/>

                        <p><b>Delivery Policy</b></p>
                        <p><b>Shipping/Delivery Address:</b> The delivery/shipping address must be accurate and serviceable by our logistics service providers. You can make purchases on the Platform from anywhere in the world but at the same time, You must ensure the payment and shipping address is within India. You are responsible for accepting and collecting the package from the delivery address provided by you at the time of placing the Order. In case your delivery address is not serviceable by our logistics service providers, {process.env.REACT_APP_NAME} reserves the right to cancel the Order and refund the Order amount if paid online.</p>
                        <p>The logistics service provider assigned by {process.env.REACT_APP_NAME} will make a maximum of three [3] attempts to deliver Your order. In case You are not reachable, available or do not accept delivery of products in these attempts {process.env.REACT_APP_NAME} reserves the right to cancel the order at its discretion. You may be informed of such cancellation by email or SMS at the email address or mobile number provided to {process.env.REACT_APP_NAME}. The cancelled order is returned to us. You agree not to hold {process.env.REACT_APP_NAME} liable for any cancellation</p>
                        <p>In case there is any dispute regarding the shipment of the product or serviceability for the area not covered by the logistics service providers, {process.env.REACT_APP_NAME} will not be responsible for the non-delivery of the product.</p>
                        <p>Title and risk of loss for all products ordered by You shall pass on to You upon delivery of the products at the shipping address provided by You.</p>

                        <p><b>Multiple Product Orders:</b> In case You order multiple products in one transaction, {process.env.REACT_APP_NAME} would endeavour to ship all products together. However, this may not always be possible due to some product characteristics or logistics issues and You may receive the products separately and at different times. If You purchase multiple products in a single transaction, then all the products would be dispatched to a single shipping address given by You. If You wish to ship products to different addresses, then You should book the orders separately based on the shipping addresses.</p>

                        <p><b>Missing Items:</b> In the event, the product package received by You is missing one or more products or accessories or does not include the freebie or the order is partly delivered, You should inform {process.env.REACT_APP_NAME} through the Customer Care Team/email (<a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}><b>{process.env.REACT_APP_SUPPORT_EMAIL}</b></a>) within 3 days of the delivery date of the package. Missing products claims will not be accepted after 3 days from the delivery date.</p>
                        <p>When a package delivered is missing one or more products or accessories, You are requested to return the original package delivered in accordance with the Return Policy.</p>
                        <p>Note: You are advised to take the video recording of unpacking the Parcel to claim a refund/return.</p>

                        <p><b>Refusal of Delivery:</b> Before accepting delivery of any product, kindly ensure that the product's packaging is not damaged or tampered. If You observe that the package is damaged or tampered, You are requested to refuse to accept delivery and inform {process.env.REACT_APP_NAME} within 3 days of the delivery date.</p>
                        <p>If you frequently refuse to accept the parcel without any valid reason, your account may be blocked without any prior notice.</p>

                        <p><b>Delivery Time:</b> Delivery time will depend upon the logistics service providers. As soon as your order is shipped, we will communicate the tracking link via your registered Email/Mobile Number. You can track your order from the tracking link.</p>
                        <p>Delivery timeframes are just estimates and are not guaranteed delivery timeframes and should not be relied upon as such. Deliveries to certain locations may take longer than expected due to accessibility of the location and serviceability by the logistics service provider.</p>
                        <p>About Delayed Delivery</p>
                        <p>Sometimes, the delivery may take longer due to:</p>
                        <div className="description">
                            <ul>
                                <li>Bad weather</li>
                                <li>Transportation delays</li>
                                <li>Natural calamities</li>
                                <li>Political disruptions</li>
                                <li>Logistics service provider related challenges</li>
                                <li>Product lost in transit</li>
                                <li>Regional or national holidays which are considered as delivery holidays</li>
                                <li><p>Other unforeseen circumstances or events beyond the control of {process.env.REACT_APP_NAME} or logistics service provider.</p></li>
                            </ul>
                        </div>
                        <p>If the estimated delivery date has passed and You still have not received Your order, please contact us and we will take steps to track Your package. In such cases, we may also try to proactively contact You. Please check Your emails and SMS regularly for such updates.</p>
                        <p>{process.env.REACT_APP_NAME} will not compensate for any mental agony or inconvenience or loss caused due to delay in delivery for any reason. You may be informed by email or SMS at the email address or mobile number provided to {process.env.REACT_APP_NAME} if any product in the order is unavailable or is delayed or lost in transit.</p>

                        <p><b>Shipping Charges:</b> You may be required to pay Shipping Charges in addition to the order value amount. The shipping charges, if any, will appear at the time of checkout. All delivery charges are inclusive of service tax unless stated otherwise. Delivery charges will vary according to pin codes as well as the type of product. The shipping charge is non-refundable.</p>

                        <p><b>Open delivery:</b> {process.env.REACT_APP_NAME} reserves the right to provide Open delivery or not. Open delivery service which is provided by {process.env.REACT_APP_NAME} with some Specifics logistics service providers. Open delivery depends upon our logistics service provider’s service dependencies. You can’t open a parcel if the logistics service provider doesn’t allow it and in such an event, You have a right to accept the parcel after paying the amount and thereafter You can use our hassle-free return policy.</p>
                        <p>Please refer to the Return and Refund Policies, if You wish to return the delivered product to {process.env.REACT_APP_NAME}.</p>
                        <br/>

                        <p><b>Return Policy</b></p>
                        <p>All products can not be returned, kindly check the return policy of the product at the time of placing the Order. Also, to return damaged products, we need unpacking videos of the parcel without editing.</p>
                        <p>To return the product, kindly pack the products(record packing videos) and mention the delivery address as mentioned in your Order Details Page. We will arrange pickup for the package and will notify you about it via registered email/Mobile Number.</p>
                        <p>You can make a return request only once per order, so in case you have ordered multiple products at a time and want to return a few, please mark the respective products while making a request for return.</p>
                        <p>You can return the products within 3 days from the delivery time.</p>

                        <p><b>Reasons for Return:</b> Return Policy makes it possible for You to return a product if You receive a damaged or defective product or if the product is significantly different from what was purchased or when the package delivered is missing one or more products or accessories. Return Policy here should be read in conjunction with any specific terms and conditions applicable to a product which can be found on the product descriptions page for returning a product.</p>
                        
                        <p><b>Conditions for Return:</b> In order to return any product sold through the Platform, You are required to comply with the below-mentioned conditions, viz.:</p>
                        <div className="description">
                            <ul>
                                <li>The return has to be in compliance with the Return Policy associated with the product category.</li>
                                <li>{process.env.REACT_APP_NAME} should be notified of the receipt of a damaged or defective product or if the product is significantly different from what was purchased, within the period mentioned in the returns policy.</li>
                                <li> Products should be returned unused, in their original packaging along with the original price tags, labels, packing, barcodes, user manual, warranty card and invoices, accessories, freebies and original boxes defined as essentials. If any product is returned without the essentials, the product shall not be accepted for return and shall be sent back to You.</li>
                                <li>The return packages should be strongly and adequately packaged so that there is no damage to products during transit.</li>
                                <li><p>The returned products are subject to verification and quality checks by us in order to determine the legitimacy of the complaint or return.</p></li>
                            </ul>
                        </div>

                        <p><b>Non-returnable Products:</b> All products cannot be returned. Please read the description and return policy of the product while placing the order. The Product which is marked returnable can only be returned.</p>

                        <p><b>Cancellation of Return Request:</b> A request for return once made can be cancelled by contacting Customer Service. In case the Logistics Service provider arrives to receive the shipment and You want to cancel the request, You may choose to inform the logistics service provider that You do not wish to return the product. You will receive an email or SMS notification at the email address or mobile number provided to {process.env.REACT_APP_NAME} cancelling Your return request.</p>

                        <p><b>Refusal of Return Request:</b> {process.env.REACT_APP_NAME} reserves the right to refuse or cancel any return request.  If the request for returns is not allowed by the Returns Policy, You will not be refunded the payment made or any costs and will not be able to raise a second request for a return for the same product. You agree not to dispute the decision made by {process.env.REACT_APP_NAME} and accept {process.env.REACT_APP_NAME}’s decision regarding the refusal or cancellation and further agree not to hold {process.env.REACT_APP_NAME} liable for any refusal or cancellation.</p>

                        <p><b>Frivolous Complaints:</b> In the event of frivolous or baseless complaints or requests regarding the quality or content of the products, {process.env.REACT_APP_NAME} reserves the right to take necessary legal actions against You and You will be solely liable for all costs incurred by {process.env.REACT_APP_NAME} in this regard.</p>

                        {/* <p><b>Return Shipping Process</b></p>
                        <p><b>Self-send Process:</b> In case of return of products initiated and subsequent courier of the product by You, if it is found that the returned product was not delivered to us or any other designated location specified or the package was empty, the onus shall be on You to prove through submission of proof of delivery from the concerned courier service provider to establish Your claim of return. Self-courier of returns should be initiated within the periods specified in {process.env.REACT_APP_NAME}’s Returns Policy. {process.env.REACT_APP_NAME} is not liable to process the return request until satisfactory proof of delivery is provided to {process.env.REACT_APP_NAME}.</p>
                        <p>In case of damage claims, {process.env.REACT_APP_NAME} may ask for pictures of the damaged product before it is approved or allowed for return.</p>
                        <p>For return shipping managed by {process.env.REACT_APP_NAME}, You will need to hand over the product to the assigned logistics service provider at the time of pick-up. In the event the logistics service provider makes attempts to pick-up the product and You are unavailable or not ready to handover the product, {process.env.REACT_APP_NAME} or logistics service provider will not be held responsible for the delay in pick-up or processing of the quality check by Us and hence the refund. A maximum of 2 attempts will be made by the logistics service provider to pick-up the product to be returned. {process.env.REACT_APP_NAME} will not be liable for the products returned by mistake.</p>
                        <p>In case of any discrepancy in the status of pick-up of a product arranged by {process.env.REACT_APP_NAME}, (where You claim the product has been returned, while our system suggests otherwise) refund will be initiated only if You successfully furnish the courier slip given by the {process.env.REACT_APP_NAME} assigned logistics service provider at the time of the pick-up.</p>
                        <br/> */}

                        <p><b>Order Cancellation Policy</b></p>
                        <p>Cancellation of an order can be done either by {process.env.REACT_APP_NAME} or by You as under:</p>

                        <p><b>Cancellation by {process.env.REACT_APP_NAME}:</b> {process.env.REACT_APP_NAME} has the discretion to cancel an order. Some of the reasons for cancellation are as under (not being an exhaustive list of reasons):</p>
                        <div className="description">
                            <ul>
                                <li>Your failure to comply with any of these terms.</li>
                                <li>Product is unavailable.</li>
                                <li>Technical errors or issues.</li>
                                <li>Any reason identified by {process.env.REACT_APP_NAME}`s credit and fraud avoidance team.</li>
                                <li>Invalid address or wrong address is given in order details.</li>
                                <li>Malpractices used to place the order.</li>
                                <li>Bulk order is placed for the likely commercial resale.</li>
                                <li>Multiple orders are placed for the same product at the same address.</li>
                                <li>Order is undelivered after three (3) attempts.</li>
                                <li><p>Some technical glitch, resulting into incorrect pricing or specifications.</p></li>
                            </ul>
                        </div>
                        <p>Notwithstanding anything contained hereunder, {process.env.REACT_APP_NAME} reserves the right, at its sole discretion, to refuse or cancel any order for any reason whatsoever without any further liability. On such cancellation, You will be sent a notification email or SMS at the email address or mobile number provided to {process.env.REACT_APP_NAME}.</p>

                        <p><b>Cancellation by You:</b> You may cancel an order for any reason, although cancellation by You must be done before the product has been shipped for delivery to You. No cancellation is permitted subsequently.</p>
                        <p>You can cancel an order or part of an order where multiple products have been ordered. This can be done either by calling or emailing the Customer Care Team or in Your account on the Platform through ‘my account’. You should state the reason for such cancellation. {process.env.REACT_APP_NAME} will process the request for cancellation as per the policy in force.</p>
                        <p>If an order has been successfully cancelled, You will be sent a confirmation email or SMS at the email address or mobile number provided to {process.env.REACT_APP_NAME}.</p>
                        <p>{process.env.REACT_APP_NAME} reserves the right to accept or reject requests for order cancellations for any reason whatsoever. You agree not to hold {process.env.REACT_APP_NAME} liable for any rejection of order cancellations.</p>
                        <p>You will receive a refund for Your cancelled orders that have been accepted in accordance with the Refund Policy.</p>
                        <br/>

                        <p><b>Refund Policy</b></p>
                        <p>Refunds are provided for cancellation/return of products, subject to the eligible cancellation/ return as per Return Policy or these terms and conditions, initiated in accordance with the Return or Cancellation Policy.</p>
                        <p>Refund is made for the full amount of the order or part order successfully cancelled that was paid by You for the delivery of the order. The shipping charge is non-refundable.</p>
                        <p>For return requests, the refund of payment is made after the returned product has been received by us and has passed the quality checks. The entire product cost along with any taxes (except the shipping charges) if any will be refunded to You. The shipping charge is non-refundable.</p>
                        <p>If any refund is received by You pending the quality checks, You will hold such amounts in trust on behalf of {process.env.REACT_APP_NAME} until the conclusion of such quality checks unless claimed by {process.env.REACT_APP_NAME} any time before that. If You are in receipt of any wrongful refund or payment, You will transfer such amount back to {process.env.REACT_APP_NAME} within 3 days of You receiving such amount.</p>
                        <p>No refund due to non-deliverability will be applicable to orders placed with Cash on Delivery options. Refund of Cash on Delivery orders returned by You may be subject to levy of a charge which will be deducted from Your refund amount.</p>
                        
                        <p><b>Time Period for Refunds:</b> Refunds are normally processed within 1-6 working days after the completion of quality checks of product returned and further depends on various banking and payment channels.{process.env.REACT_APP_NAME} is not responsible for any errors or delays in refunds due to banks or third party service provider errors or delays.</p>

                        <p><b>Mode of Refund:</b> The mode of refund of payments cannot be changed at any stage as the refund amount is transferred to Your source account. Refunds are paid back to the source of payment.</p>
                        <p>Refund for payments made by modes other than Cash on Delivery, which fails when processed back to the source may be refunded by National Electronic Funds Transfer (“NEFT”) to Your bank account.</p>
                        <p>Refund of orders placed using Cash on Delivery as the payment method will be made to Your bank account via NEFT or to Your wallet account depending on the discretion of {process.env.REACT_APP_NAME}. You will need to update the bank account number and IFSC code to enable us to process a refund to Your account. Refunds cannot be processed to third-party accounts, i.e. the name on Your {process.env.REACT_APP_NAME} account should match with the name of the bank account holder provided for a refund via NEFT. A refund initiation confirmation by email or SMS at the email address or mobile number provided to {process.env.REACT_APP_NAME} will be sent to You.</p><br/>

                        <p><b>Terminated Payment Mode:</b> If the original payment method You used to make the payment (credit or debit card or net banking) is no longer valid, we will issue the refund to another bank account provided by you. When we receive a payment failure notice from Your bank, we will send You an email asking You to call us. We will explain the process to submit another bank account details, and after verification, the money will be refunded. The Bank Account name must match with the name provided to Us at the time of Registration.</p>
                        <p>You explicitly give Your consent to receiving communications (by SMS, email or other mode of communication) sent to You by {process.env.REACT_APP_NAME}, however, You may withdraw Your consent at any time at Your sole discretion.</p><br/>

                        <p><b>Ordering:</b> You confirm that the products ordered by You are purchased for Your internal or personal use and not for re-sale.</p><br/>

                        <p><b>Pricing and Payment:</b> The App/Platform lists availability and pricing information of products on the product display pages of the App/Platform.</p>
                        <p>All prices are inclusive of Value Added Tax and Central Sales Tax unless stated otherwise. Products in Your shopping cart will always reflect the most recent price displayed on the product display page. Placing a product in the shopping cart or wish list does not reserve the price shown at a particular time. It is possible that a product's price may decrease or increase between the time the product is placed in the shopping cart or wish list and the time You actually purchase it. Products that are marked as 'Out of Stock'/’Product Unavailable’ are not available for purchase</p>
                        <p>The price of products offered on the App/Platform is either equal to or lesser than the Maximum Retail Price ("MRP") i.e., the discounted rate prescribed for those products. The MRP and other statutory declarations will be mentioned on the products or on its packaging in accordance with applicable laws. We may or may not specify this information on the App/Platform, and You are advised to independently determine the MRP of such products if You so desire.</p>
                        <p>By purchasing the products using the App/Platform and authorizing the App/Platform to process the payment, You agree, accept and understand that the App/Platform as part of its services, is only a facilitator providing an automated online electronic payment, receiving payment through cash on delivery, collection and remittance facility for the sale and purchase of the products.</p>
                        <p>For more details, please review the Payment Policy applicable to the products. The App/Platform may change the Payment Policy without any notice.</p><br/>

                        <p><b>Returns and Cancellation:</b> Return and cancellation of the products may be initiated in accordance with the App’s policies. Please review the Returns Policy and Cancellation Policy as applicable to the products purchased by You. The App/Platform may change the Returns Policy and Cancellation policy without any notice.</p><br/>

                        <p><b>Taxes:</b> You are responsible for payment of all fees, costs and charges associated with the purchase of products and You agree to bear any and all applicable taxes, Value Added Taxes, cesses, etc. levied on the products on their sale unless otherwise stated.</p><br/>

                        <p><b>No Warranty:</b> All products or services are made available through the App/Platform on an "AS IS" basis and "AS AVAILABLE BASIS" without any warranty of any kind, express, implied, statutory or otherwise, including the implied warranties of title, non-infringement, suitability, accuracy, reliability, completeness, timeliness, performance, safety, merchantability or fitness for a particular purpose or the legality of the products listed or displayed or transacted to the extent permitted by applicable law and {process.env.REACT_APP_NAME} does not accept any liability for any defects, deficiencies, short comings, errors or omissions.</p>
                        <p>The buyer acknowledges that the product images are indicative in nature and the actual product may differ from the image.</p>
                        <p>The buyer acknowledges and understands that the buyer cannot claim any compensation from {process.env.REACT_APP_NAME}, other than a refund for the amount paid by buyer while ordering, for any reason, not limited to but including: (i) if the final product or its quality, quantity or specifications does not meet buyer’s expectation; (ii) there is any special, incidental, indirect or consequential damage caused to the buyer by the product purchased; (iii) opportunities missed by the buyer for availing any offers or discounts on the App for any reason whatsoever; (iv) delivery delays due to any unforeseen circumstances by {process.env.REACT_APP_NAME} or its assigned logistics service providers; (v) deactivation or expiry of a valid voucher, coupon or promo code issued by {process.env.REACT_APP_NAME}; (vi) buyer faces any issues with logistics service provider’s personnel.</p><br/>

                        <p><b>Communications:</b> You will be required to provide a valid email address and phone number in order to avail the services provided by the App/Platform, including and not limited to placing orders for buying the products. By registering Your email address and phone number with the App/Platform, You consent to be contacted by the App/Platform via email, phone calls or SMS notifications for any order or shipment, delivery, payment or offer related updates or otherwise. The App/Platform may notify or communicate with You by email, SMS, phone or by posting notices on the App/Platform or by any other mode of communication.</p><br/>

                        <p><b>Severability:</b> Each of the provisions in these Terms are severable and distinct from the others and if at any time one or more such provision is or becomes invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions of these Terms shall not in any way be affected or impaired thereby.</p><br/>

                        <p><b>Captions:</b> The various captions used in these Terms are for convenience purposes only and may not be used to interpret the provisions thereof. In the case of captions and related text conflicts, the text will prevail.</p><br/>

                        <p><b>Partial Invalidity:</b> If any provision of these Terms is held to be illegal, invalid, or unenforceable under any present or future applicable law, (i) such provision will be fully severable; (ii) these Terms will be construed and enforced as if such illegal, invalid, or unenforceable provision had never comprised a part hereof; (iii) the remaining provisions of these Terms will remain in full force and effect and will not be affected by the illegal, invalid, or unenforceable provision or by its severance here from.</p><br/>

                        <p><b>Force majeure:</b> Notwithstanding anything contained in these Terms or elsewhere, {process.env.REACT_APP_NAME} shall not be held responsible for any loss, damage to You if such loss, the damage is attributable to a Force Majeure Event. A "Force Majeure Event" means any event that is beyond {process.env.REACT_APP_NAME}’s reasonable control and includes, without limitation, fire, flood, explosion, acts of God, civil commotion, strikes, lock outs or industrial action of any kind, riots, insurrection, terrorist attacks, war, acts of government, power failure, sabotage, computer hacking, unauthorised access to computer data and storage device, system failure, virus, attacks, bugs, computer crashes, breach of security and encryption.</p><br/>

                        <p><b>Disputes:</b> All complaints relating to the products purchased from the App are routed through the Customer Care Team of the App/Platform. We will try to resolve the issue.</p><br/>

                        <p><b>Infrastructure:</b> To use the App, You require Internet connectivity or appropriate telecommunication links and the App’s service does not include the provision of any computer system, resource, terminal, server, equipment, mobile or any devices. {process.env.REACT_APP_NAME} shall not have any responsibility or liability for any computer system, resource, terminal, server, equipment, mobile or any devices necessary for User to use and access the App nor shall be liable for any costs incurred on account of Your usage of Internet or appropriate telecommunication links usage. {process.env.REACT_APP_NAME} would not be liable for damages, viruses or other code that may affect the Your computer system, resource, terminal, server, equipment, mobile or any other devices while using the App and {process.env.REACT_APP_NAME} does not warrant that the App will be compatible with all hardware and software used by the User. You must comply with applicable third party terms, if any, such as mobile device agreement or wireless data service agreement etc. while using the App.</p><br/>

                        <p><b>Use of Content:</b> You agree that the rights granted as above are irrevocable during the entire period of protection of the User’s intellectual property rights associated with such content and material. The User agrees to waive the User’s right to be identified as the author of such content and materials and the User’s right to object to derogatory treatment of such content or material. The User agrees to perform all further acts and execute deeds and documents necessary to perfect the above rights granted by the User to {process.env.REACT_APP_NAME}.</p>
                        <p>The User represents and warrants that the User owns or otherwise controls all of the rights to the content and material that the User posts or that the User otherwise provides on or through the App; that, as at the date that the content or material is submitted to the App: (i) the content and material is accurate; (ii) use of the content and material does not breach any applicable Indian law or the App’s policies or guidelines or Additional Terms (iii) the content and material do not breach these Terms and Privacy Policy.</p><br/>

                        <p><b>Third-Party Content:</b> Third-party content and materials may appear on our website or may be accessible via hyperlinks from the website. We are not responsible for and assume no liability whatsoever for any mistakes, misstatements of law, defamation, omissions, falsehood, obscenity, pornography or profanity in the statements, opinions, representations or any other form of content and materials appearing on the website or accessible via hyperlinks from the website.  You are encouraged to read the terms of use and privacy policy of each and every linked website before accessing any of the third-party websites linked to the App and You acknowledge that any risk associated thereof while accessing and using such linked third-party websites solely lies with You and {process.env.REACT_APP_NAME} shall not be responsible in any manner whatsoever.</p><br/>

                        <p><b>Intellectual Property Rights:</b> All content included on the App, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of {process.env.REACT_APP_NAME}, its associates or its content suppliers and is protected by Indian/ International copyright, authors' rights and database right laws. The compilation of all content on the App is the exclusive property of  {process.env.REACT_APP_NAME}, its affiliates, associates or its content suppliers and is protected by laws of Indian/ International copyright and database right laws. All software used on this App is the property of  {process.env.REACT_APP_NAME}, its affiliates, associates or its software suppliers and is protected by laws of India and international copyright authors rights and database laws.</p>
                        <p> {process.env.REACT_APP_NAME} grants You a limited licence to access and make personal use of this App, but not to download (other than page caching) or modify it, or any portion of it, except with express written consent of  {process.env.REACT_APP_NAME}. This licence does not include any resale or commercial use of the contents of the App; any collection and use of any product listings, descriptions, or prices; any derivative use of this App or its contents; any downloading or copying of account information.</p>
                        <p>The trademarks, logos and service marks displayed on the App ("Marks") are the properties of  {process.env.REACT_APP_NAME} or its associates or its content suppliers or users or respective third parties. Users are not permitted to use the Marks without the prior consent of  {process.env.REACT_APP_NAME}, its affiliates, associates or its content suppliers or users or the third party that may own the Marks.</p>
                        <p>You shall not use, copy, reproduce, modify, alter, change, amend, transmit, broadcast, edit, revise, review, adapt, translate, distribute, perform, display, sell or otherwise deal with content or the intellectual property rights of the App or content suppliers or third parties in any mode medium or manner now known or developed in future without authorization from {process.env.REACT_APP_NAME} and on happening of any such event {process.env.REACT_APP_NAME} reserves the right to immediately discontinue the App services to such User without prejudice {process.env.REACT_APP_NAME}’s right to initiate legal action in this regard. You are strictly prohibited from framing or use framing techniques to enclose any content or intellectual property on the App to illegally and unlawfully exploit the content or intellectual property rights owned by  {process.env.REACT_APP_NAME} or content suppliers or a third party as the case is.</p><br/>

                        <p><b>Fee:</b> Users can use and access the App free of charge. However, at any time in future, after duly notifying in advance, {process.env.REACT_APP_NAME} reserves its right to charge the users for the use of the App and provisioning of the services through the App as per applicable Indian law.</p><br/>

                        <p><b>Additional Terms-Conditions:</b> We may offer new or additional services through the website from time to time. Your use of those services may be subject to additional terms and conditions, which you must comply with.</p><br/>

                        <p><b>Disclaimer:</b> You being a User of the App, expressly agree that the use of the App is at Your sole risk. The App and the contents uploaded or provide or shared by other users are made available to the User through the App on an "AS IS" basis and "AS AVAILABLE BASIS" without any warranty of any kind, express, implied, statutory or otherwise, including the implied warranties of title, non-infringement, suitability, accuracy, reliability, completeness, timeliness, performance, safety, merchantability or fitness for a particular purpose or the legality of the products listed or displayed or transacted to the extent permitted by applicable Indian law and {process.env.REACT_APP_NAME} does not accept any liability for any errors or omissions. The offer and acceptance of products through the App is at the User’s sole risk and v accepts no liability for any errors or omissions or defaults or deficiency of any products either offered or accepted by the users through the App.</p>
                        <p>{process.env.REACT_APP_NAME} hereby disclaims any guarantees of exactness as to the finish and appearance of the product as ordered by and delivered to the User. The quality or quantity of any products, information or other material purchased or obtained by You through the App that may not meet Your expectations.</p>
                        <p>The App could also be linked to third party websites and {process.env.REACT_APP_NAME} assumes no responsibility for results obtained from the use of these websites.</p>
                        <p>{process.env.REACT_APP_NAME} makes no assurances that the App is bug-free and User friendly or the safety of the use of the App. {process.env.REACT_APP_NAME} including affiliates, associates and their respective owners, directors, officers, agents, shareholders and partners shall not be liable in any manner with respect to the loss or damage incurred or suffered by You on account of virus attacks or bugs or other software or programmes that may be present or embedded while using or accessing the App.</p>
                        <p>{process.env.REACT_APP_NAME} does not guarantee that the services provided through the App will function without interruption or errors. The operation of the services may be interrupted due to maintenance, updates, system failures, virus attacks, bugs or due to any other technical reasons or otherwise. App disclaims all liability for losses or damages caused by any such interruption or errors in functioning. {process.env.REACT_APP_NAME} also disclaims all liability for any malfunctioning impossibility of access or poor use conditions of the App due to inappropriate equipment, disturbances related to internet services, down time or otherwise.</p>
                        <p>To the maximum extent permitted by applicable law, {process.env.REACT_APP_NAME} will have no liability related to User Content arising under intellectual property rights, libel, privacy, publicity, obscenity or other laws. {process.env.REACT_APP_NAME} also disclaims all liability with respect to the misuse, loss, modification or unavailability of any User content.</p>
                        <p>When the User deletes any kind of information the user must understand that the removed content may continue in backup copies.</p>
                        <p>{process.env.REACT_APP_NAME} will not be liable for any loss that You may incur as a consequence of unauthorized use of the Account or any service or materials, either with or without Your knowledge. {process.env.REACT_APP_NAME} shall not be liable for any third-party product, the advertisement available on e-mail or App with respect to the third-party website or the products and services are for information purposes only.</p><br/>

                        <p><b>Termination:</b> {process.env.REACT_APP_NAME} reserves the right to terminate the services provided to any User without prior notice in the event of any default committed by User including but not limited to the breach of any applicable law, policies, guidelines and conditions of {process.env.REACT_APP_NAME}’s service or Additional Terms or these Terms or the Privacy Policy (including any modifications or additional notifications or instructions).  On such termination, the User’s information and other details shall be black listed by the App and shall be taken into consideration whenever necessary.</p>
                        <p>On termination of the User Account, the User is not eligible to purchase products through the App. Once the User’s account is terminated for any reason whatsoever, the contents, reviews, comments or other material posted on the App by the User shall be removed by {process.env.REACT_APP_NAME}.</p>
                        <p>Post termination of the services provided to the User through the App, the User’s personal information would reside in the App’s database for a certain period of time. It is also clarified that certain terms and conditions of the policies, guidelines and conditions of {process.env.REACT_APP_NAME}’s service or Additional Terms or these Terms and the Privacy Policy continue to be binding on the User and if any User by default or otherwise is found using or dealing with the App post termination of the service provided through the App, the User shall be prosecuted by {process.env.REACT_APP_NAME}. The User shall be also liable to compensate {process.env.REACT_APP_NAME} for any unauthorized use of the App.</p><br/>

                        <p><b>Updation to this Agreement:</b> We reserve the right to update/change this Agreement from time to time, we will notify you about the changes via registered mail if you are registered users. Please do check this agreement on a timely basis in case you miss any updates. If You are not willing to abide by the updated or new rules, you may stop using our services and if you are a registered user, you can communicate the same to us at <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}><b>{process.env.REACT_APP_SUPPORT_EMAIL}</b></a> so we can close your account.</p>
                        <br/>
                        <p><b>Governing law:</b> The terms of this “Terms of Use” document are exclusively based on and subject to the laws of India.</p>
                        <p>Please do store a photocopy of this agreement with you either as a soft copy or hard copy.</p>
                    </div>
                </Card>
            </Col>
        </Row>
        </div>
    )    
}

export default TermsConditions;