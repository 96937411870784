import React, {useState, useEffect} from 'react';
import {
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  Form,
  InputGroup,
  Row
} from 'react-bootstrap';
import '../../assets/css/pages/form.css';
import { SpinnerRoundFilled } from 'spinners-react';
import ValidationHelper from '../../helper/validation';
import {alertResponseMessage} from '../../actions/action-creators/response';
//Axios Call
import {post_api_call, error_api_call} from '../../actions/action-creators/common';
import {SetMetaData} from './../../helper/page-title';

const UpdatePassword = () => {
  SetMetaData("Change Password", "", "");
  //State
  const inputDefaultValue = {
    old_password: '',
    new_password: ''
  };
  const [inputFields, setFields] = useState(inputDefaultValue);
  const [securePassword, setSecurePassword] = useState({old_password: true, new_password: true});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isUpdateProcess, setIsUpdateProcess] = useState(false);
  const [isError, setIsError] = useState('');
  const [redirectUrl, setRedirectUrl] = useState(false);
  //Redirect to Respective Page if error
  useEffect(() => {
    let timer = setTimeout(() => {
        console.log(redirectUrl, 'redirectUrl')
        if(redirectUrl === true){
            window.location.href = '/my-account';
        }
    }, 4000);
    return () => {
      clearTimeout(timer);
    };
  }, [redirectUrl]);// eslint-disable-line react-hooks/exhaustive-deps
  //Update Input Box
  const _onChangeText = (attrName, value) => {
    try{
      setIsError('');
      let fields = {...inputFields};
      value = value.replace(/ /g, '');
      fields[attrName] = value;
      setFields({...fields});
    }catch(e){
      error_api_call({error: `account/update-password|_onChangeText|${JSON.stringify(inputFields)}|${e}`});
    }
  }
  //Input Validation:Use to Toggle Button: Disable-true|false
  useEffect(()=>{
    //Validate Input
    const _validate = () => {
      // if (!didMount) return null;
      let fields = {...inputFields};
      try{
        var validators = {};
        // validators[''] = ;//Submit Mobile Number
        validators['old_password'] = 'required|password|min:6|max:30';
        validators['new_password'] = 'required|password|min:6|max:30';
        const validation = ValidationHelper.validation(fields, validators).error;
        console.log(validation, 'validation')
        setSubmitDisabled(validation.length ? true : false);
        return validation.length ? false : true;
      }catch(e){
        error_api_call({error: `account/update-password|_validate|${JSON.stringify(inputFields)}|${e}`});
      }
    };
    _validate();
    return () => {
    }
  }, [inputFields]);// eslint-disable-line react-hooks/exhaustive-deps
  //Toggle Show-Hide Password
  const _showHidePassword = (field) => {
    setSecurePassword({...securePassword, [field]: !securePassword[field]})
  };
  //Reset Password
  const _updatePassword = async(e) => {
      e.preventDefault();
    //Validation:Already done
    //Show Loader
    try{
        var formData = {...inputFields};
        if(formData.old_password === formData.new_password){
            setIsError("New Passsword can not be same as Old Password");
            return false;
        }
        setIsUpdateProcess(true);
        setIsError('');
        const updatedData = await post_api_call('update-password', formData);
        console.log(updatedData, 'updatedData')
        if(updatedData.status === 200){
            alertResponseMessage({alert_type: 1, message: "Password Changed Successfully"});
            setRedirectUrl(true);
            // window.location.href = '/my-account';
        }else{
            setIsError(updatedData.message);
        }
        setIsUpdateProcess(false);
    }catch(e){
      console.log(e)
      setIsError('Error Changing Password ! Please Try Again');
      setIsUpdateProcess(false);
      error_api_call({error: `account/update-password|_updatePassword|${JSON.stringify(inputFields)}|${e}`});
    }
  }

  return (
    <div className="c-app c-default-layout align-items-center custom-form">
      <Container style={{minHeight: '66vh'}}>
        <Row className="justify-content-center">
          <Col md="4">
            <CardGroup>
              <Card className="p-2" style={{margin: '55px 0px'}}>
                <Card.Body>
                {/* <form name="login" id="login"> */}
                    <h5 className="text-center mb-5">
                      Change Password
                    </h5>
                      <>
                        <div className="mb-3">
                          <InputGroup>
                            <InputGroup.Text><i className="fa fa-lock"></i><em>*</em></InputGroup.Text>
                            <Form.Control 
                              type={securePassword.old_password ? "password" : "text"}
                              placeholder="Old Password" 
                              autoComplete="old-password" 
                              value={inputFields.old_password}
                              onChange={(event) => _onChangeText('old_password', event.target.value)}
                              maxLength={30}
                              readOnly={isUpdateProcess ? true : false}
                            />
                            {inputFields.old_password !== "" &&
                              <i className={`fa icon-eye ms-3 ${securePassword.old_password ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => _showHidePassword('old_password')}></i>
                            }
                          </InputGroup>
                          {/* <p className="input-info">Minimum 6 Characters, Maximum 30 Characters</p> */}
                        </div>
                        <div className="mb-3">
                          <InputGroup>
                              <InputGroup.Text><i className="fa fa-lock"></i><em>*</em></InputGroup.Text>
                            <Form.Control 
                              type={securePassword.new_password ? "password" : "text"}
                              placeholder="New Password" 
                              autoComplete="new-password" 
                              value={inputFields.new_password}
                              onChange={(event) => _onChangeText('new_password', event.target.value)}
                              maxLength={30}
                              readOnly={isUpdateProcess ? true : false}
                            />
                            {inputFields.new_password !== "" &&
                              <i className={`fa icon-eye ms-3 ${securePassword.new_password ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => _showHidePassword('new_password')}></i>
                            }
                          </InputGroup>
                          <p className="input-info">Minimum 6 Characters, Maximum 30 Characters</p>
                        </div>
                      </>
                    <Row className='mt-5'>
                      {isError &&
                        (<Col xs="12" className="text-center">
                            <div className="alert alert-danger" role="alert">
                                {isError}
                            </div>
                        </Col>)
                        }
                      <Col xs="12" className="text-center">
                        {isUpdateProcess ? 
                          <Button
                            title="Submit"
                            disabled={true}
                            color={'primary'}
                          >
                            <SpinnerRoundFilled color="#fff" size={20} />
                            Updating...
                          </Button>
                          : 
                          <Button
                            type="submit"
                            disabled={submitDisabled}
                            onClick={(e) => _updatePassword(e)}
                            color={"primary"}
                            className="px-4"
                          >Change Password</Button>
                        }
                      </Col>
                    </Row>
                  {/* </form> */}
                </Card.Body>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default UpdatePassword
