import React from "react";
import {
    Card,
    Col,
    Row
  } from 'react-bootstrap';
import {SetMetaData} from './../../helper/page-title';

function Faqs(){
    SetMetaData("FAQs-Frequently Asked Questions", "", "");

    return (
        <div className="container">
        <Row className="justify-content-center">
            <Col md="9" sm="12">
                <Card className="m-2 mt-4 mb-5">
                    <div className="p-3 static-pages">
                        <h5 className="text-center pt-3">Frequently Asked Questions</h5>
                        <br/>
                        {/* <p><a href="/faq-account" className="link-danger"><b>Account-Login/Registration</b></a></p> */}
                        {/* <p><a href="/faq-payment" className="link-danger"><b>Payments</b></a></p> */}
                        <p><a href="/faq-shipping" className="link-danger"><b>Shipping</b></a></p>
                        <p><a href="/cancellation-returns" className="link-danger"><b>Cancellation & Returns</b></a></p>
                    </div>
                </Card>
            </Col>
        </Row>
        </div>
    )    
}

export default Faqs;