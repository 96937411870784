import React, { useEffect, useState } from "react";
// import SubFilter from "./sub-filter";
import "./../../assets/css/pages/filter.css";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import {error_api_call} from '../../actions/action-creators/common';

function ProductFilter(props){
    const [toggleAccordian, setToggleAccordian] = useState({});
    const toggleAccordianFunc = (filter_key) => {
        setToggleAccordian({...toggleAccordian, [filter_key]: !toggleAccordian[filter_key]});
    }
    useEffect(() => {
        var toggleAccordianData = {};
        for(var i in props.data){
            toggleAccordianData[props.data[i].filter_key] = true;
        }
        setToggleAccordian({...toggleAccordianData});
    }, [props.data]);
    const handleOnChange = (e, type) => {
        try{
            // console.log(e, type, 'kkk')
            // e.preventDefault();
            if(type === 'select'){
                const {value, name} = e.target;
                // console.log(value, 'value');
                props.handleFilterData(name, "", value);
            }else{
                const {checked, name} = e.target;
                // const name = e.target.name;
                const id = e.target.dataset.id;
                // console.log(checked)
                // console.log(name, 'namenamename')
                // console.log(e.target.dataset.id)
                // return false;
                props.handleFilterData(name, checked, parseInt(id));
            }
            return false;
        }catch(error){
            // console.log(error)
            error_api_call({error: `component/forms/filter|handleOnChange|${error}`});
            return false;
        }
    }
    return (
        <div className="filter-container d-none d-md-block d-lg-block">
            <div className="row">
                <div className="col-12">
                    <div className="filter-title mb-15">
                        <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="15px">
                                <path fillRule="evenodd" fill="rgb(32 66 227)" d="M7.778,12.000 L12.222,12.000 L12.222,10.000 L7.778,10.000 L7.778,12.000 ZM-0.000,-0.000 L-0.000,2.000 L20.000,2.000 L20.000,-0.000 L-0.000,-0.000 ZM3.333,7.000 L16.667,7.000 L16.667,5.000 L3.333,5.000 L3.333,7.000 Z"></path>
                            </svg>
                        </div>
                        <h4>Filter by</h4>
                    </div>
                </div>
            </div>
            <div className="deal-filter-section">
                {props.data.map((filter, index) => (
                    // <React.Fragment key={index}>
                    //     <SubFilter 
                    //         title={filter.title}
                    //         field={filter.field}
                    //         is_open={toggleAccordian[filter.filter_key]}
                    //         filter_key={filter.filter_key}
                    //         toggleAccordianFunc={(filter_key) => toggleAccordianFunc(filter_key)}
                    //         options={filter.options}
                    //     />
                    // </React.Fragment>
                    filter.options.length > 0 && (
                        <Accordion
                            defaultActiveKey={typeof toggleAccordian[filter.filter_key] === "undefined" || toggleAccordian[filter.filter_key] ? 1 : 0}
                            key={index}>
                            {/* <Card className="mb-4"> */}
                            <Accordion.Item className="mb-4" eventKey={1}>
                                <Accordion.Header as={Card.Header} onClick={() => toggleAccordianFunc(filter.filter_key)}>
                                    {/* <Card.Header> */}
                                    <p className="accordian-title">{filter.title}</p>
                                    <i className={`btn fa ${typeof toggleAccordian[filter.filter_key] === "undefined" || toggleAccordian[filter.filter_key] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                            {/* </Card.Header> */}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Card.Body className="accordian-details accordian-fixed filter-scroll">
                                        {filter.type === "select" 
                                        ?
                                            <select
                                                onChange={(e) => handleOnChange(e, filter.type)}
                                                name={filter.filter_key}
                                                value={filter.selected_value}
                                            >
                                                {filter.options.map((option, index) => (
                                                                // data-id={option[filter.field_key]}/> &nbsp;
                                                    <option
                                                        key={filter.filter_key+option[filter.field_key]}
                                                        value={option[filter.field_key]}
                                                        // selected={option[filter.field_key] === filter.selected_value ? true : false}
                                                    >
                                                        {option[filter.field]}
                                                    </option>
                                                ))}
                                            </select>
                                        :
                                            filter.type === "collapse"
                                            ?
                                                filter.options.map((option, index) => (
                                                    <Accordion className="mb-3 collapse-menu" key={filter.filter_key+option[filter.field_key]}>
                                                        <Accordion.Item>
                                                            <Accordion.Header>{option[filter.field]}</Accordion.Header>
                                                            <Accordion.Body className="m-3">
                                                                {option[filter.sub_option_data_key].map((sub_option, sub_index) => (
                                                                    <p className="mb-2" key={filter.field+'_'+sub_option[filter.sub_value_key]}>
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                onChange={(e) => handleOnChange(e, filter.key)}
                                                                                name={filter.sub_title_key}
                                                                                data-id={sub_option[filter.sub_value_key]}
                                                                                checked={typeof filter.checked_data[sub_option[filter.sub_value_key]] !== "undefined"
                                                                                            && filter.checked_data[sub_option[filter.sub_value_key]] === true
                                                                                        ? true
                                                                                        : false}
                                                                            /> &nbsp;
                                                                            <span className="input-label">{sub_option[filter.sub_title_key]}</span>
                                                                            {/* <span className="float-right">({option.count})</span> */}
                                                                        </label>
                                                                    </p>
                                                                ))}
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>            
                                                ))
                                            :
                                            // {/* {props.options.slice(0, 5).map((option, index) => ( */}
                                            <>
                                            {filter.options.map((option, index) => (
                                                <p className="mb-2" key={filter.filter_key+option[filter.field_key]}>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e) => handleOnChange(e, filter.key)}
                                                            name={filter.filter_key}
                                                            data-id={option[filter.field_key]}
                                                            checked={typeof filter.checked_data[option[filter.field_key]] !== "undefined"
                                                                        && filter.checked_data[option[filter.field_key]] === true
                                                                    ? true
                                                                    : false}
                                                        /> &nbsp;
                                                        <span className="input-label">{option[filter.field]}</span>
                                                        {/* <span className="float-right">({option.count})</span> */}
                                                    </label>
                                                </p>
                                            ))}
                                            </>
                                            // {/* {props.options.length > 5 && (
                                            //     <p>+{props.options.length-5} More</p>
                                            // )} */}
                                        }
                                    </Card.Body>
                                </Accordion.Body>
                                </Accordion.Item>
                            {/* </Card> */}
                        </Accordion>
                    )
                ))}
            </div>
        </div>
    );
}

export default ProductFilter;