import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { Provider } from 'react-redux';
// import {createStore, applyMiddleware} from "redux";
// import thunk from 'redux-thunk';
// import reducer from './store';
// import reducer from './actions/reducers';
// import { PersistGate } from 'redux-persist/integration/react';
// const store = createStore(reducer, applyMiddleware(thunk));
import { store } from './actions/persist-store';

ReactDOM.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
      <App/>
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
