import Dropdown from 'react-bootstrap/Dropdown';
import { popperConfigDrop } from "../../../config/popper";
import {LoaderMedium} from './../../loader';
import {calculatePercent, stringToUrl} from './../../../helper/custom-function';
import noImage from './../../../assets/images/no-image.png';
function ProductSingle(props){
    const dropCount = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    return (
        <>
        {props.data.map((data, index) => (
            <div className={`col-md-${props.mdCol ? props.mdCol : 2} col-12 col-sm-6 pe-2 ps-2`} key={index}>
                <div className="product m-md-0 me-2 ms-2" style={{background: 'white'}}>
                    <div className="row">
                        <div className="col-3 col-sm-12">
                            <a href={`/order-medicines/detail/${data.product_id}/${stringToUrl(data.name)}`}>
                                <div className="product-img">
                                    {/* <img src={props.activeData[data.product_id].image} alt={data.name} /> */}
                                    <img src={noImage} alt={data.name} />
                                    {/* {(props.activeData[data.product_id].price !== null && props.activeData[data.product_id].mrp !== null) && (
                                        calculatePercent(props.activeData[data.product_id].price, props.activeData[data.product_id].mrp)
                                    )}	 */}
                                    {(data.price !== null && data.mrp !== null) && (
                                        calculatePercent(data.price, data.mrp)
                                    )}
                                </div>
                            </a>
                        </div>
                        <div className="col-9 col-sm-12">
                            <div className="product-body text-start text-sm-center">
                                {/* <p className="product-category mb-2">{data.brand_name}</p> */}
                                <p className="product-category mb-2">{data.packet_name} of {data.unit_name} {data.unit_type}</p>
                                <h3 className="product-name"><a href={`/order-medicines/detail/${data.product_id}/${stringToUrl(data.name)}`}>{data.name}</a></h3>
                                {/* <div className="custom-dropdown pt-3">
                                    <p className="select-custom"><span>{props.activeData[data.product_id].variation_name}</span><i className="fa fa-chevron-down"></i></p>
                                    <div className="dropdown-content">
                                        {data.variation_data.map((recommend_variation, index) => (
                                            <button
                                                key={`recommend_variation_${recommend_variation.product_variation_id}`}
                                                onClick={() => props.variationOnChange(props.fetchKey, recommend_variation)}>
                                                {recommend_variation.variation_name}
                                            </button>
                                        ))}
                                    </div>
                                </div> */}
                                {/* <div className="measure">
                                    <select>
                                        {data.variation_data.map((recommend_variation, index) => (
                                            <option key={`recommend_variation_${recommend_variation.product_variation_id}`}>{recommend_variation.variation_name}</option>
                                        ))}
                                    </select>
                                </div> */}
                                {/* {(props.activeData[data.product_id].price !== null || props.activeData[data.product_id].mrp !== null) && (
                                    <h4 className="product-price">
                                        ₹{props.activeData[data.product_id].price} {props.activeData[data.product_id].mrp > 0 && props.activeData[data.product_id].mrp > props.activeData[data.product_id].price &&  <del className="product-old-price">₹{props.activeData[data.product_id].mrp}</del>}
                                    </h4>
                                )} */}
                                {(data.price !== null || data.mrp !== null) && (
                                    <h4 className="product-price text-start text-sm-center">
                                        ₹{data.price} {data.mrp > 0 && data.mrp > data.price &&  <del className="product-old-price">₹{data.mrp}</del>}
                                    </h4>
                                )}
                                <div className="add-to-cart text-start text-sm-center ps-0 ps-sm-2">
                                    {(props.itemLoader[data.product_detail_id] 
                                        && props.itemLoader[data.product_detail_id] === true)
                                        ?
                                            <button 
                                                className="add-to-cart-btn"
                                                onClick={(e) => props.addToCart(e, data.product_detail_id, 1)}
                                                disabled={true}
                                            >
                                                <LoaderMedium size={25}/>
                                            </button>
                                        :
                                            <>
                                                {/* Final */}
                                                {props.cartData[data.product_detail_id] > 0
                                                    ?
                                                    <Dropdown className="drop-btn">
                                                        <Dropdown.Toggle variant="success" className="add-to-cart-btn text-start">
                                                            {props.cartData[data.product_detail_id]} Quantity
                                                            {/* {props.cartData[data.product_detail_id] > 1 ? 's' : ''} */}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu popperConfig={popperConfigDrop}>
                                                            <Dropdown.Header>Quantity</Dropdown.Header>
                                                            {dropCount.map((book_count, index_book) => (
                                                                <Dropdown.Item
                                                                    key={`add_count${data.product_detail_id}_${index_book}`}
                                                                    active={props.cartData[data.product_detail_id] === book_count ? true : false}
                                                                    onClick={(e) => props.addToCart(e, data.product_detail_id, book_count)}
                                                                    disabled={props.cartData[data.product_detail_id] === book_count ? true : false}
                                                                >{book_count} Qty</Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    :
                                                    <button 
                                                        className="add-to-cart-btn"
                                                        // onClick={(e) => props.addToCart(e, data.lab_test_m_id, props.activeData[data.lab_test_m_id].product_variation_id, 1)}
                                                        onClick={(e) => props.addToCart(e, data.product_detail_id, 1)}

                                                    >
                                                        <i className="fa fa-shopping-cart"></i>&nbsp;&nbsp;Add to Cart
                                                    </button>
                                                }
                                                {/* <div className="custom-dropdown pt-3">
                                                    <button type="button" className="select-custom add-to-cart-btn"><span>{props.cartData[data.id]}</span><i className="fa fa-chevron-down"></i></button>
                                                    <div className="dropdown-content dropdown-menu">
                                                    <h6 className="dropdown-header">Book For</h6>
                                                        {dropCount.map((book_count, index_book) => (
                                                            <button
                                                                key={`book_count${data.id}_${index_book}`}
                                                                onClick={(e) => props.addToCart(e, data.id, book_count)}>
                                                                {book_count} patient{book_count > 1 ? 's' : ''}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div> */}
                                            </>
                                    }
                                </div>
                                {/* <div className="add-to-cart">
                                    <button className="add-to-cart-btn"><i className="fa fa-shopping-cart"></i> add to cart</button>
                                </div> */}
                                {/* <div className="product-rating">
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                </div> */}
                                {/* <div className="product-btns">
                                    <button className="add-to-wishlist"><i className="fa fa-heart-o"></i><span className="tooltipp">add to wishlist</span></button>
                                    <button className="add-to-compare"><i className="fa fa-exchange"></i><span className="tooltipp">add to compare</span></button>
                                    <button className="quick-view"><i className="fa fa-eye"></i><span className="tooltipp">quick view</span></button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="add-to-cart mt-2"> */}
                        {/* Final */}
                        {/* {props.cartData[props.activeData[data.product_id].variation_detail_id] > 0 && (
                            <div 
                                className="add-to-cart-custom" style={{display: "inline-flex"}}>
                                <div className="cart-btn-wrap">
                                    <button
                                        className="plus"
                                        onClick={(e) => props.addToCart(e, data.product_id, props.activeData[data.product_id].variation_detail_id, props.cartData[props.activeData[data.product_id].variation_detail_id]-1)}
                                    >
                                        <i className="fa fa-minus"></i></button>&nbsp;&nbsp;
                                        <p className="qty">
                                            <span>
                                                {(props.itemLoader[props.activeData[data.product_id].variation_detail_id] 
                                                    && props.itemLoader[props.activeData[data.product_id].variation_detail_id] === true)
                                                    ? <LoaderMedium size={18}/>
                                                    : (<>{props.cartData[props.activeData[data.product_id].variation_detail_id]}</>)
                                                }
                                            </span>
                                    </p>&nbsp;&nbsp;
                                    <button
                                        className="minus"
                                        onClick={(e) => props.addToCart(e, data.product_id, props.activeData[data.product_id].variation_detail_id, props.cartData[props.activeData[data.product_id].variation_detail_id]+1)}
                                    ><i className="fa fa-plus"></i></button>
                                </div>
                            </div>
                        )} */}
                        {/* Final */}
                        {/* {props.cartData[props.activeData[data.product_id].variation_detail_id] === 0 && (
                            <button 
                                className="add-to-cart-btn"
                                onClick={(e) => props.addToCart(e, data.product_id, props.activeData[data.product_id].variation_detail_id, 1)}
                                >
                                    {(props.itemLoader[props.activeData[data.product_id].variation_detail_id] 
                                        && props.itemLoader[props.activeData[data.product_id].variation_detail_id] === true)
                                        ? <LoaderMedium size={25}/>
                                        : (<><i className="fa fa-shopping-cart"></i>&nbsp;&nbsp;Add to Cart</>)
                                    }
                            </button>
                        )} */}
                        {/* <a
                            className="add-to-cart-btn add-to-cart-href p-2"
                            href={`https://wa.me/+919123791215?text=Hi,%20I%20want%20to%20order%20this%20product ${window.location.host}${`/medicine/detail/${data.product_id}/${stringToUrl(data.name)}`}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="fa fa-2x fa-whatsapp"></i>&nbsp;&nbsp;Order Now
                        </a> */}
                    {/* </div> */}
                    {/* <div style={{display: 'flow-root', background: '#f5f5f5'}}>
                        <p className="input-info m-2">{moment(deal.updated_at).fromNow()} by {deal.user_name}</p>
                    </div> */}
                </div>
            </div>
        ))}
        </>
    );
}

export default ProductSingle;