import React, {useState} from "react";
import {
    Row,
    Tabs,
    Tab
} from 'react-bootstrap';
import {SetMetaData} from './../../helper/page-title';
import './../../assets/js/cart';
import './../../assets/css/pages/cart.css';
import MyCart from '../../screens/cart/cart-medicine';
import MyTestCart from '../../screens/cart/cart-test';

function MyCartMain(){
    SetMetaData("My Cart", "", "");
    const [tabKey, setTabKey] = useState("medicine");
    const [medCount, setMedCount] = useState(0);
    const [testCount, setTestCount] = useState(0);
    //Update Cart Count
    const _updateCartCount = (type, count) => {
        try{
            if(count !== ''){
                if(type === 'med'){
                    setMedCount(count);
                }else if(type === 'lab'){
                    setTestCount(count);
                }
            }
        }catch(e){

        }
    }
    return (
        <div className="container mb-5">
            <Row>
                <div className="col-md-12 col-12 col-sm-12">
                    <div className="section-title mt-5">
                    <h3>My Cart</h3>
                    </div>
                </div>
                {/* {pageLoading 
					?
						<div className="text-center mt-5">
							<LoaderMedium size={65} />
						</div>
					: */}
                        <>
                        <div className="col-md-12 col-12 col-sm-12 cart-section">
                            <Tabs
                                activeKey={tabKey}
                                onSelect={(k) => setTabKey(k)}
                                fill
                            >
                                {/* Medicines */}
                                <Tab eventKey="medicine" title={`Medicines ${medCount > 0 ? `(${medCount})` : ''}`}>
                                    <div className="mt-2">
                                        <MyCart
                                            _updateCartCount={(count) => _updateCartCount('med', count)}
                                        />
                                    </div>
                                </Tab>
                                {/* Test */}
                                <Tab eventKey="lab" title={`Lab Test ${testCount > 0 ? `(${testCount})` : ''}`}>
                                    <div className="mt-2">
                                        <MyTestCart
                                            _updateCartCount={(count) => _updateCartCount('lab', count)}
                                        />
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                        </>
                {/* } */}
        </Row>
        </div>
    )    
}

export default MyCartMain;