import React, {useState, useEffect, useRef} from 'react';
import { useDispatch } from 'react-redux';
import {useLocation} from 'react-router-dom';
import {
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  Form,
  InputGroup,
  Row
} from 'react-bootstrap';
import '../../assets/css/pages/form.css';
import { SpinnerRoundFilled } from 'spinners-react';
import ValidationHelper from '../../helper/validation';
//Axios Call
import {login_register} from '../../actions/action-creators/auth';
import {error_api_call} from '../../actions/action-creators/common';
import {SetMetaData} from './../../helper/page-title';

const Login = () => {
  SetMetaData("Login-Register", "", "");
  const location = useLocation();
    const isMounted = useRef(null);
  const [currentStep, setCurrentStep] = useState(1);//1=>Initial,2=>Login,3=>Register,4=>Verification
  const [isMobileUser, setIsMobileUser] = useState(true);
  //Store Data
  // const authReduxData = useSelector(state => state.auth);
  const dispatch = useDispatch()
  //State
  const inputDefaultValue = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    mobile: '',
    user_name: '',
    register_medium: 1//1=>Web
  };
  const [inputFields, setFields] = useState(inputDefaultValue);
  const [securePassword, setsecurePassword] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isLoginProcess, setIsLoginProcess] = useState(false);
  const [isError, setIsError] = useState('');
  const [redirectUri, setRedirectUri] = useState('');
  const formTitle = {
    1: 'Login/Register',
    2: 'Login',
    3: 'Register',
    4: 'Verification'
  };
  //Redirection Url
  useEffect(() => {
    const urlHandling = () => {
      try{
        // const urlQuryString = window.location.search;
        const urlQuryString = location.search;
        const urlParam = new URLSearchParams(urlQuryString);
        const urlRedirect = urlParam.get('redirect_uri');
        console.log(urlRedirect, 'urlRedirect')
        if(urlRedirect !== '' && urlRedirect !== null){
          setRedirectUri(urlRedirect);
        }
      }catch(e){
        error_api_call({error: `auth/login|urlHandling|${e}`});
      }
    }
    urlHandling();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  //Update Input Box
  const _onChangeText = (attrName, value) => {
    try{
      setIsError('');
      let fields = {...inputFields};
      if(currentStep === 3){//Register
        if(attrName === 'mobile' 
          && value.length < 11
        ){
          value = value.replace(/[^0-9]/g, '');
          // fields[attrName] = value;
        }else if(attrName === 'last_name'){
          //Only letter & Space
          value = value.replace(/[^a-zA-Z ]/g, '')
                      .replace('  ', ' ');
        }else{
          if(attrName === 'first_name'){
            value = value.replace(/[^a-zA-Z]/g, '');
          }
          // fields[attrName] = value.replace(' ', '');
          value = value.replace(/ /g, '');
        }
      }else{
        value = value.replace(/ /g, '');
      }
      
      fields[attrName] = value;
      setFields({...fields});
    }catch(e){
      error_api_call({error: `auth/login|_onChangeText|${JSON.stringify(inputFields)}|${e}`});
    }
  }
  //Input Validation:Use to Toggle Button: Disable-true|false
  useEffect(()=>{
    //Validate Input
    const _validate = () => {
      // if (!didMount) return null;
      let fields = {...inputFields};
      try{
        var validators = {};
        var validation = [];
        if(currentStep === 1){//Initial
          if(!((ValidationHelper.validateNumber(fields.user_name)
            && fields.user_name.length === 10)
            || (ValidationHelper.validateEmail(fields.user_name)
                && fields.user_name.length < 101))
          ){
            validation = ["Enter Valid Email/Mobile Number"];
          }
        }else if(currentStep === 2){//Login
          validators['password'] = 'required|password|min:6|max:30';
          validation = ValidationHelper.validation(fields, validators).error;
        }else if(currentStep === 3){//Register
          if(!isMobileUser){
            validators['mobile'] = 'required|digit:10';
          }else{
            validators['email'] = 'optional|email|min:7|max:100';
          }
          validators['first_name'] = 'required|only_alpha|min:2|max:30';
          validators['last_name'] = 'only_alpha_space|min:2|max:30';          
          validators['password'] = 'required|password|min:6|max:30';
          validation = ValidationHelper.validation(fields, validators).error;
        }
        setSubmitDisabled(validation.length ? true : false);
        return validation.length ? false : true;
      }catch(e){
        error_api_call({error: `auth/login|_validate|${JSON.stringify(inputFields)}|${e}`});
      }
    };
    _validate();
    isMounted.current = true;
    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [inputFields]);// eslint-disable-line react-hooks/exhaustive-deps
  //Toggle Show-Hide Password
  const _showHidePass = () => {
    setsecurePassword(!securePassword)
  };
  //Login-Register User
  const _loginRegister = async() => {
    //Validation:Already done
    //Show Loader
    try{
      var url = 'register';
      var formData = {...inputFields};
      if(currentStep === 1){
        url = 'user-check';
      }else if(currentStep === 2){
        url = isMobileUser ? 'login/mobile' : 'login/email';
        formData = isMobileUser ? {mobile: formData.mobile, password: formData.password}
                                : {email: formData.email, password: formData.password};
      }
      setIsLoginProcess(true);
      setIsError('');
      dispatch(login_register(url, formData, cb_login => {
        console.log(cb_login, 'cb_login')
        if (isMounted.current) {
          if(currentStep === 1){
            if(cb_login.status === 404){
              setCurrentStep(3);//Register
              setIsMobileUser(cb_login.is_mobile);
              if(cb_login.is_mobile){
                setFields({...inputFields, mobile: inputFields.user_name})
              }else{
                setFields({...inputFields, email: inputFields.user_name})
              }
            }else if(cb_login.status === 200){//Exists
              setCurrentStep(2);//login
              setIsMobileUser(cb_login.is_mobile);
              if(cb_login.is_mobile){
                setFields({...inputFields, mobile: inputFields.user_name})
              }else{
                setFields({...inputFields, email: inputFields.user_name})
              }
            }else{
              setIsError(cb_login.msg);
            }
          }else{//Login-Register
            if(cb_login.status === 200){
              if(cb_login.data.is_verified === 1
                || cb_login.data.is_verified === 3
              ){
                window.location.href = redirectUri ? `/${redirectUri}` : "/";
              }else{
                window.location.href = redirectUri ? `/verification?redirect_uri=${redirectUri}` : '/verification';
                // setCurrentStep(4);//Verification
              }
            }else{
              setIsError(cb_login.msg);
            }
          }
          setIsLoginProcess(false);   
        }
      }));
    }catch(e){
      console.log(e)
      setIsError('Error Logging In/Registering! Please Try Again');
      setIsLoginProcess(false);
      error_api_call({error: `auth/login|_loginRegister|${JSON.stringify(inputFields)}|${e}`});
    }
  }
  //Forget Password Redirect
  const _forgotPassword = (e) => {
    e.preventDefault();
    if(ValidationHelper.validateNumber(inputFields.user_name)){
      window.location.href = `/reset-password?user_name=${inputFields.user_name}&redirect_uri=${redirectUri}`;
    }else{
      window.location.href = `/reset-password?redirect_uri=${redirectUri}`;
    }
  }

  return (
    <div className="c-app c-default-layout align-items-center custom-form">
      <Container style={{minHeight: '66vh'}}>
        <Row className="justify-content-center">
          <Col md="4">
            <CardGroup>
              <Card className="p-2" style={{margin: '55px 0px'}}>
                <Card.Body>
                <form name="login" id="login">
                    <h5 className="text-center mb-5">
                      {formTitle[currentStep]}
                    </h5>
                    {/* <p className="text-muted text-center">Sign In to your account</p> */}
                    <div className="mb-3">
                      <InputGroup>
                        <InputGroup.Text><i className="fa fa-user"></i><em>*</em></InputGroup.Text>
                        <Form.Control 
                          type="text" 
                          placeholder="Email/Mobile Number" 
                          autoComplete="user_name" 
                          value={inputFields.user_name}
                          onChange={(event) => _onChangeText('user_name', event.target.value)}
                          maxLength={100}
                          readOnly={currentStep !== 1 || isLoginProcess ? true : false}
                        />
                      </InputGroup>
                      <p className="input-info">Space Not Allowed</p>
                    </div>
                    {currentStep === 3 &&
                      <>
                        {/* First Name */}
                        <div className="mb-3">
                          <InputGroup>
                            <InputGroup.Text><i className="fa fa-user"></i><em>*</em></InputGroup.Text>
                            <Form.Control 
                              type={"text"}
                              placeholder="First Name"
                              autoComplete="first-name" 
                              value={inputFields.first_name}
                              onChange={(event) => _onChangeText('first_name', event.target.value)}
                              maxLength={30}
                              readOnly={isLoginProcess ? true : false}
                            />
                          </InputGroup>
                          <p className="input-info">Space Not Allowed,minimum 2 Characters</p>
                        </div>
                        {/* Last Name */}
                        <div className="mb-3">
                          <InputGroup>
                            <InputGroup.Text><i className="fa fa-user"></i>&nbsp;&nbsp;</InputGroup.Text>
                            <Form.Control 
                              type={"text"}
                              placeholder="Last Name"
                              autoComplete="last-name" 
                              value={inputFields.last_name}
                              onChange={(event) => _onChangeText('last_name', event.target.value)}
                              maxLength={30}
                              readOnly={isLoginProcess ? true : false}
                            />
                          </InputGroup>
                          <p className="input-info">Minimum 2 Characters</p>
                        </div>
                        {isMobileUser
                        ?
                          //Email
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1"><i className="fa fa-envelope"></i>&nbsp;&nbsp;</InputGroup.Text>
                            <Form.Control 
                                  type="email" 
                                  placeholder="Email" 
                                  autoComplete="email" 
                                  value={inputFields.email}
                                  onChange={(event) => _onChangeText('email', event.target.value)}
                                  readOnly={isLoginProcess ? true : false}
                              />
                          </InputGroup>
                        :
                          //Mobile Number
                          <InputGroup className="mb-3">
                            <InputGroup.Text><i className="fa fa-user"></i><em>*</em></InputGroup.Text>
                            <Form.Control 
                              type="text" 
                              placeholder="Mobile Number" 
                              autoComplete="mobile-number" 
                              value={inputFields.mobile}
                              onChange={(event) => _onChangeText('mobile', event.target.value)}
                              maxLength={10}
                              readOnly={isLoginProcess ? true : false}
                              // readOnly={currentStep !== 1 ? true : false}
                            />
                          </InputGroup>
                        }
                      </>
                    }
                    {currentStep > 1 &&
                      <>
                        <div className="mb-3">
                          <InputGroup>
                              <InputGroup.Text><i className="fa fa-lock"></i><em>*</em></InputGroup.Text>
                            <Form.Control 
                              type={securePassword ? "password" : "text"}
                              placeholder="Password" 
                              autoComplete="current-password" 
                              value={inputFields.password}
                              onChange={(event) => _onChangeText('password', event.target.value)}
                              maxLength={30}
                              readOnly={isLoginProcess ? true : false}
                            />
                            {inputFields.password !== "" &&
                              <i className={`fa icon-eye ms-3 ${securePassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => _showHidePass()}></i>
                            }
                          </InputGroup>
                          <p className="input-info">Minimum 6 Characters, Maximum 30 Characters</p>
                        </div>
                      </>
                    }
                    <Row className='mt-5'>
                      {isError &&
                        (<Col xs="12" className="text-center">
                            <div className="alert alert-danger" role="alert">
                                {isError}
                            </div>
                        </Col>)
                        }
                      <Col xs="12" className="text-center">
                        {isLoginProcess ? 
                          <Button
                            title="Submit"
                            disabled={true}
                            color={'primary'}
                          >
                            <SpinnerRoundFilled color="#fff" size={20} />
                            {currentStep === 2
                            ? 'Logging...'
                            : currentStep === 3
                              ? 'Registering...'
                              : 'Submiting...'
                            }
                          </Button>
                          : 
                          <Button
                            type="submit"
                            disabled={submitDisabled}
                            onClick={() => _loginRegister()}
                            color={"primary"}
                            className="px-4"
                          >{currentStep === 2
                            ? 'Login'
                            : currentStep === 3
                              ? 'Register'
                              : 'Submit'
                            }</Button>
                        }
                      </Col>
                      {currentStep !== 3 &&
                        <Col xs="12" className="text-right">
                          <p className="link">
                            <button
                              className='btn p-0 link-custom'
                              onClick={(e) => _forgotPassword(e)}
                              disabled={false}
                            >Forgot your password?</button>
                          </p>
                        </Col>
                      }
                      
                      {/* <Col xs="12" className="text-center">
                            <Link to="/register">
                                <p className="link link-highlight">Don`t have acoount? Create one, click here</p>
                            </Link>
                      </Col> */}
                    </Row>
                  </form>
                </Card.Body>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Login
