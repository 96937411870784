import React from "react";
import {
    Card,
    Col,
    Row
  } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import {SetMetaData} from './../../helper/page-title';

function ShippingFaq(){
    SetMetaData("FAQ-Shipping", "", "");

    return (
        <div className="container">
        <Row className="justify-content-center">
            <Col md="9" sm="12">
                <Card className="m-2 mt-4 mb-5">
                    <div className="p-3 static-pages">
                        <h5 className="text-center pt-3">FAQ- Shipping</h5>
                        <br/>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>What are the shipping/delivery charges?</Accordion.Header>
                                <Accordion.Body>
                                If the order value is less than ₹ 1000, there is a shipping charge of ₹ 50. While, orders of Rs 1000 or above are delivered free.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        {/* <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What is the estimated delivery time?</Accordion.Header>
                                <Accordion.Body>
                                Estimated Delivery time depends on Delivery PINCode, You can check the estimation time in <a href="/my-cart" className="link-danger">Your Cart Page</a>. The estimation time shown in <a href="/my-cart" className="link-danger">Your Cart page</a> can get affected due to many reasons like :
                                <div className="description">
                                    <ul>
                                        <li>Product Availability</li>
                                        <li>Bad Weather</li>
                                        <li>Transportation delays</li>
                                        <li>Natural calamities</li>
                                        <li>Political disruptions</li>
                                        <li>Logistics service provider related challenges</li>
                                        <li>Product lost in transit</li>
                                        <li>Regional or national holidays which are considered as delivery holidays</li>
                                        <li>Other unforeseen circumstances or events beyond the control of {process.env.REACT_APP_NAME} or logistics service provider</li>
                                    </ul>
                                </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion> */}
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Are there any hidden costs on items/products sold on {process.env.REACT_APP_NAME}?</Accordion.Header>
                                <Accordion.Body>
                                There are No hidden charges when you make a purchase on {process.env.REACT_APP_NAME}. List prices are final and all-inclusive. The price you see on the product page is exactly what you would pay.<br/><br/>
                                There are Shipping/Delivery charges which are charged depending upon the order value. If the order value is less than ₹ 100, there is a shipping charge of ₹ 50. While, orders of Rs 1000 or above are delivered free.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Why is my Address/PINCode not serviceable?</Accordion.Header>
                                <Accordion.Body>
                                Whether your Address/PINCode can be serviced or not depends on:<br/>
                                <div className="description">
                                    <ul>
                                        <li>Legal restrictions, if any, in shipping particular products to your location</li>
                                        <li>The availability of reliable courier partners in your location</li>
                                    </ul>
                                </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Why is the COD option not offered in my location?</Accordion.Header>
                                <Accordion.Body>
                                Availability of COD depends on the ability of our courier partner servicing your location to accept cash as payment at the time of delivery.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        {/* <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>I need to return an item/product, how do I arrange for a pick-up?</Accordion.Header>
                                <Accordion.Body>
                                Returns are easy. From <a href="/my-order" className="link-danger">your Order details</a> Page You can choose the option to return the products and follow the steps as instructed on the screen.<br/><br/>
                                Contact Us if you face any issue to request for return. Please note that return is allowed for selected products and request for return to be made within 3 days from delivery date.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion> */}
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>Does {process.env.REACT_APP_NAME} deliver internationally?</Accordion.Header>
                                <Accordion.Body>
                                Currently, {process.env.REACT_APP_NAME} doesn't deliver items/products internationally.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </Card>
            </Col>
        </Row>
        </div>
    )    
}

export default ShippingFaq;