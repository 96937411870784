import React from "react";
import {
    Card,
    Col,
    Row
  } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import {SetMetaData} from './../../helper/page-title';

function ShippingFaq(){
    SetMetaData("FAQ-Cancellation & Returns", "", "");

    return (
        <div className="container">
        <Row className="justify-content-center">
            <Col md="9" sm="12">
                <Card className="m-2 mt-4 mb-5">
                    <div className="p-3 static-pages">
                        <h5 className="text-center pt-3">FAQ- Cancellation & Returns</h5>
                        <br/>
                        {/* <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>How can I cancel my order?</Accordion.Header>
                                <Accordion.Body>
                                You can cancel your order before it has been dispatched. We'll refund the full amount you've paid for such a cancellation if paid online. Here's how to cancel your order:
                                <div className="description">
                                    <ul>
                                        <li>Log in to your account and go to My Account{'->'}My Orders</li>
                                        <li>Select the appropriate order</li>
                                        <li>Click on ‘Cancel Order’ for the order you want to cancel, individually</li>
                                        <li>Select the reason and confirm the cancellation</li>
                                    </ul>
                                </div><br/>
                                Once you're done, we'll send you  an email and SMS confirming that your request is being processed. Remember, you won’t be able to cancel an order after it has been dispatched.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion> */}
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>When can I cancel my order?</Accordion.Header>
                                <Accordion.Body>
                                You can cancel your order before it has been dispatched.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Why do I see a disabled cancel link?</Accordion.Header>
                                <Accordion.Body>
                                That means the products from your order have already been shipped and you can't make any cancellations now.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>How will I get my refund when I cancel an order?</Accordion.Header>
                                <Accordion.Body>
                                If the Payment is made online, Once your order has been cancelled, it will take 1-4 business days for your refund to be processed and the amount to be transferred back to the source account. In the case of certain public sector banks, it can take up to 10-15 working days.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Will I get the complete refund for the order I’ve cancelled?</Accordion.Header>
                                <Accordion.Body>
                                Yes. We'll refund the entire amount for a cancelled order if paid online.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Why was my order cancelled by {process.env.REACT_APP_NAME}?</Accordion.Header>
                                <Accordion.Body>
                                Sometimes we cancel orders for various reasons – the product could be out-of-stock, of unacceptable quality, etc. Once an order has been cancelled, the refund will be processed immediately if paid online. In all it will take 3-4 business days for your refund to be processed and the amount to be transferred back to the source account. In the case of certain public sector banks, it can take up to 10-15 working days.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        {/* <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>How do I return the products  Ordered?</Accordion.Header>
                                <Accordion.Body>
                                You can return  the products within 3 days from the delivery date. Here's how to request for return:
                                <div className="description">
                                    <ul>
                                        <li>Log in to your account and go to My Account{'->'}My Orders</li>
                                        <li>Select the appropriate order</li>
                                        <li>Click on Return Order’ for the order  you want to return</li>
                                        <li>Select the product(s) to return</li>
                                        <li>Select the reason and confirm the return</li>
                                    </ul>
                                </div><br/>
                                Once you're done, we'll send you  an email and SMS confirming that your request is being processed. Remember, you won’t be able to return the products after 3 days of delivery. Please note some products can not be returned.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion> */}
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>Will I get the complete refund for the order I’ve returned?</Accordion.Header>
                                <Accordion.Body>
                                Yes. We'll refund the entire amount of a returned product but shipping charge is non-refundable.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>Which products are not eligible for returns?</Accordion.Header>
                                <Accordion.Body>
                                On the product detail page, the return Policy is mentioned. Kindly always check a product's Returns Policy on the product page before placing Order.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>Can items/products be returned after the time period mentioned in the Returns Policy?</Accordion.Header>
                                <Accordion.Body>
                                No, we will not be able to accept returns after the time period mentioned in the Returns Policy.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="10">
                                <Accordion.Header>Do I have to return the freebie when I return a product?</Accordion.Header>
                                <Accordion.Body>
                                Yes, the freebie has to be returned along with the product.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        {/* <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="11">
                                <Accordion.Header>If I request for a replacement, when will I get it?</Accordion.Header>
                                <Accordion.Body>
                                Visit <a href="/my-order" className="link-danger">My Orders</a> to check the status of your replacement.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion> */}
                    </div>
                </Card>
            </Col>
        </Row>
        </div>
    )    
}

export default ShippingFaq;