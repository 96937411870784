const orderStatusColor = {
    1: '',
    2: '',
    3: '',
    4: 'orange',
    5: 'green',
    6: 'red',
    7: '',
    8: '',
    9: 'yellow',
    10: 'red',
    11: 'Orange'
};

export {orderStatusColor};