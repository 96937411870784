import {useEffect} from "react";
const SetMetaData = (title, description, keyword) => {
    useEffect(() => {
      const prevTitle = document.title
      const prevDesc = document.querySelector('meta[name="description"]').getAttribute("content");
      const prevKeyWord = document.querySelector('meta[name="keywords"]').getAttribute("content");;
      document.title = `${title} | ${process.env.REACT_APP_SHORT_NAME}`;
      if(description){
        document.querySelector('meta[name="description"]').setAttribute("content", description);
      }
      if(keyword){
        document.querySelector('meta[name="keywords"]').setAttribute("content", keyword);
      }
    //   document.description = description;
    //   document.keyword = keyword;
      return () => {
        document.title = prevTitle;
        document.querySelector('meta[name="description"]').setAttribute("content", prevDesc);
        document.querySelector('meta[name="keywords"]').setAttribute("content", prevKeyWord);
      }
    })
}

export {SetMetaData};