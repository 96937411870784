import React, {useState, useEffect} from "react";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {
    Row
} from 'react-bootstrap';
import {SetMetaData} from './../../helper/page-title';
import './../../assets/js/cart';
import redirectionGif from './../../assets/images/redirect-loader.gif';

function OrderStatus(){
    SetMetaData("Order Confirmation", "", "");
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');
    const [orderStatus, setOrderStatus] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const [fromPage, setFromPage] = useState('');
    //Redirect to Respective Page if error
    useEffect(() => {
      const time = redirectUrl === '/my-cart' ? 10000 : 5000;
      let timer = setTimeout(() => {
        if(redirectUrl !== ''){
          navigate(redirectUrl, { replace: true });
        }
      }, time);
      return () => {
        clearTimeout(timer);
      };
    }, [redirectUrl]);// eslint-disable-line react-hooks/exhaustive-deps
    //Call Product Filter API
    useEffect(() => {
      //Get Order Status
      const urlQuryString = location.search;
      const urlParam = new URLSearchParams(urlQuryString);
      const orderStatusParam = urlParam.get('status');
      if(orderStatusParam){
        setOrderStatus(orderStatusParam);
        try{
          const page = urlParam.get('page');
          const mode = urlParam.get('mode');
          setPaymentMode(mode);
          setFromPage(page);
          if(orderStatusParam === 'success'){
            setRedirectUrl(page === 'lab' ? `/my-order/lab/${params.sub_order_id}` : `/my-order/medicine/${params.sub_order_id}`);
            // setRedirectUrl(orderStatusParam === 'success' ? `/my-order/${params.sub_order_id}` : '/my-cart')
          }else if(orderStatusParam === 'failed'){
            setRedirectUrl('/my-cart');
          }else{
            navigate('/my-cart', { replace: true });
          }
        }catch(e){
          navigate('/my-cart', { replace: true });
        }
      }else{
        navigate('/my-cart', { replace: true });
      }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
  
    return (
      <div className="container">
        <Row>
            <div className="col-md-12 col-12 col-sm-12">
                <div className="section-title mt-4">
                    <h3>Order Confirmation & Payment</h3>
                </div>
            </div>
            {orderStatus !== '' && (
                <Row className="justify-content-center">
                    <div className="col-md-8 col-lg-8 col-12 col-sm-12">
                    <div className="card pt-4 pb-4 ps-5 pe-5">
                        {orderStatus === 'success'
                        ?
                        <div className="alert alert-success text-center">
                            <p style={{fontSize: 18}}><b>Hurray! Your order #O{fromPage === 'lab' ? 'L' : ''}{params.sub_order_id} has been successfully placed.</b></p>
                            <p><b>Thank You for Shopping with Us.</b></p>
                        </div>
                        :
                          <>
                          {paymentMode === 'online' 
                            ?
                            <div className="alert alert-danger text-center">
                                <p style={{fontSize: 18}}><b>Sorry! Error placing your Order as Payment Failed. Please, place your order again. </b></p>
                                <p>Note: In case the money has been deducted from your account, it will be refunded within 1 to 3 working days.</p>
                            </div>
                            :
                            <div className="alert alert-danger text-center">
                                <p style={{fontSize: 18}}><b>Sorry! Error placing your Order. Please, place your order again. </b></p>
                            </div>
                          }
                          </>
                        }
                        
                        <p className="text-center">You will be redirected shortly</p>
                        <img src={redirectionGif} alt="Redirecting"/>
                    </div>
                    </div>
                </Row>
            )}
        </Row>
      </div>
    )    
}

export default OrderStatus;