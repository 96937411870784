import React, {useEffect} from "react";
import { useDispatch} from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import {update_device_data, auth_token_update} from './actions/action-creators/state-update';
// import './assets/css/bootstrap.min.css';
import './assets/css/bootstrap5/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/font-awesome.min.css';
import './assets/css/LineIcons.4.0.css';
// import './assets/css/animate.css';
// import './assets/css/main.css';
import Header from './components/header';
// import Navbar from './components/navbar';
import {Footer} from './components/footer';
import NotFound from './screens/404';
import Login from './screens/auth/login';
import ResetPassword from './screens/auth/reset-password';
// import Register from './screens/auth/register';
import Verification from "./screens/auth/verification";
import Logout from "./screens/auth/logout";
import MyAddress from "./screens/account/address";
import MyProfile from "./screens/account/profile";
import MyOrder from "./screens/account/order/order";
// import MyOrderDetail from "./screens/account/order/order-detail";
import UpdatePassword from "./screens/account/update-password";
import Checkout from "./screens/checkout/payment";
import OrderStatus from "./screens/checkout/order-status";

//Public
import Home from "./screens/home";
import WrapMaster from './components/wrapper/main';
import ProductList from "./screens/product/list";
// import Navbar from './../components/navbar';
import ProductDetail from "./screens/product/detail";
// Lab Test
import TestList from "./screens/test/list";
import TestDetail from "./screens/test/detail";
// import TestCart from "./screens/cart/cart-test";
import MyCart from "./screens/cart/main";
import MyLabOrderDetail from "./screens/account/order/order-lab-detail";
// Static Pages
import AboutUs from "./screens/static/about-us";
import ContactUs from "./screens/static/contact-us";
import PrivacyPolicy from "./screens/static/privay-policy";
import TermsCondition from "./screens/static/terms-conditions";
import PaymentFaq from "./screens/static/payment-faq";
import ShippingFaq from "./screens/static/shipping-faq";
import ReturnFaq from "./screens/static/return-faq";
import AccountFaq from "./screens/static/account-faq";
import Faq from "./screens/static/faq";
// import DealPost from "./screens/deal/add";
// import CouponPost from "./screens/coupon/add";
// Routes
// import ProtectedRoute from "./routes/protected";
import PrivateRoute from "./routes/private";
// import PublicRoute from "./routes/public";
import AuthRoute from "./routes/auth";
import {LoaderMedium} from './components/loader';

function App() {
    const dispatch = useDispatch();
    // const getDeviceId = () => {
    //     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    //       var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    //       return v.toString(16);
    //     });
    // }
    useEffect(() => {
        //Check if Device Id Set, if not Set New
        if(!localStorage.getItem("device_id")){
            const deviceID = uuidv4();
            //Dispatch and check if it is available in database
            update_device_data({device_id: deviceID});
            // console.log(deviceID, 'deviceID')
        }
        // else{
        //     console.log('nnnnnnnnnnnn')
        // }
        dispatch(auth_token_update());
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
      
    return (
        <>
        <div id="bodyOverlay">
            <LoaderMedium size={65} />
        </div>
        <ReactNotification />
        <Header/>
        {/* <Navbar /> */}
        <div className="wrapper" style={{minHeight: '70vh'}}>
            {/* <div className="container"> */}
            <Router>
                <Routes>
                    <Route path='*' element={<NotFound/>} />
                    {/* Auth: Guest Route */}
                    <Route element={<AuthRoute/>}>
                        <Route exact path='/login' element={<Login/>}/>
                        <Route exact path='/reset-password' element={<ResetPassword/>}/>
                    </Route>
                    {/* Routh For Authenticated User */}
                    <Route element={<PrivateRoute/>}>
                        <Route exact path='/verification' element={<Verification/>}/>
                        <Route exact path='/checkout/address' element={<MyAddress/>}/>
                        <Route exact path='/checkout' element={<Checkout/>}/>
                        <Route exact path='/order-status/:sub_order_id' element={<OrderStatus/>}/>
                        <Route exact path='/my-order/:product_type' element={<MyOrder/>}/>
                        {/* <Route exact path='/my-order/lab' element={<MyOrder/>}/> */}
                        {/* <Route exact path='/my-order/medicine/:sub_order_id' element={<MyOrderDetail/>}/> */}
                        <Route exact path='/my-order/:product_type/:order_id' element={<MyLabOrderDetail/>}/>
                        {/* <Route exact path='/my-order/lab/:order_id' element={<MyLabOrderDetail/>}/> */}
                        <Route exact path='/my-address' element={<MyAddress/>}/>
                        <Route exact path='/my-account' element={<MyProfile/>}/>
                        <Route exact path='/change-password' element={<UpdatePassword/>}/>
                        {/* <Route exact path='/logout' element={<Logout/>}/> */}
                    </Route>
                    {/* <PrivateRoute exact path='/post-deals' component={DealPost} /> */}
                    {/* <PrivateRoute exact path='/post-coupons' component={CouponPost} /> */}
                    {/* Public Route */}
                    {/* <Route path='/' element={<PublicRoute/>} /> */}
                    {/* <Route path='/'>
                        <PublicRoute />
                    </Route> */}
                    {/* <Route element={<NotFound/>} /> */}
                    {/* Public Route */}
                    <Route exact path="/" element={<WrapMaster><Home/></WrapMaster>}/>
                    <Route exact path="/home" element={<WrapMaster><Home/></WrapMaster>}/>
                    <Route exact path="/order-medicines" element={<WrapMaster><ProductList/></WrapMaster>}/>
                    <Route exact path="/order-medicines/:type" element={<WrapMaster><ProductList/></WrapMaster>}/>
                    <Route exact path="/order-medicines/detail/:id/:url" element={<WrapMaster><ProductDetail/></WrapMaster>}/>
                    <Route exact path="/book-lab-test" element={<WrapMaster><TestList/></WrapMaster>}/>
                    <Route exact path="/book-lab-test/:type" element={<WrapMaster><TestList/></WrapMaster>}/>
                    <Route exact path="/book-lab-test/detail/:id/:url" element={<WrapMaster><TestDetail/></WrapMaster>}/>
                    {/* <Route exact path="/my-cart" element={<TestCart />}/> */}
                    <Route exact path="/my-cart" element={<MyCart />}/>
                    <Route exact path='/logout' element={<Logout/>}/>
                    {/* Static Pages */}
                    <Route exact path="/about-us" element={<AboutUs />}/>
                    <Route exact path="/contact-us" element={<ContactUs />}/>
                    <Route exact path="/privacy-policy" element={<PrivacyPolicy />}/>
                    <Route exact path="/terms-conditions" element={<TermsCondition />}/>
                    <Route exact path="/faq-payment" element={<PaymentFaq />}/>
                    <Route exact path="/faq-shipping" element={<ShippingFaq />}/>
                    <Route exact path="/cancellation-returns" element={<ReturnFaq />}/>
                    <Route exact path="/faq-account" element={<AccountFaq />}/>
                    <Route exact path="/faqs" element={<Faq />}/>
                </Routes>
                {/* <PublicRoute /> */}
            </Router>
            {/* </div> */}
        </div>
        <Footer />
        </>
    );
}
  
export default App;