const stringToUrl = (string) => {
    // return string = string ? string.toLowerCase().trim().replace(/\s+/g, '-') : "";
    try{
        if(string){
            string = string.toLowerCase().trim().replace(/[^a-z0-9\s]/gi, ' ');
            string = string.replace(/\s+/g, '-');
        }
        return string = string ?  string : "";
    }catch(e){
        return '';
    }
}

const urlToString = (string) => {
    return string = string ? string.trim().replace('-', ' ') : "";
}

const calculatePercent = (price, mrp, show_text=true) => {
    try{
        var percent = 0;
        if(mrp > 0 && mrp > price){
            const diff = (mrp-price);
            percent = (diff*100)/mrp;
        }
        if(percent > 0){
            return(
                <span className="product-label">
                    {/* <span className="sale">-{percent.toFixed()}%</span> */}
                    <span className="sale">{percent.toFixed()}%{show_text ? ' Off' : ''}</span>
                    {/* <span className="new">NEW</span> */}
                </span>
              );
        }else{
            return "";
        }
    }catch(e){
        return "";
    }
}

const toggleBodyLoader = (type) => {
    try{
        var elementOver = document.getElementById("bodyOverlay");
        // var elementSVG = document.querySelectorAll('#bodyOverlay > svg');

        if(type === 'load'){
            elementOver.classList.add("screen-overlay");
            elementOver.style.display = 'block';
        }else{
            elementOver.classList.remove("screen-overlay");
            elementOver.style.display = 'none';
        }
    }catch(e){
        return "";
    }
}

export {stringToUrl,
        calculatePercent,
        urlToString,
        toggleBodyLoader
    };