import axios from "axios";
import {errorResponse} from "./response";
// import config from './../../config/api';
// import token from './../../config/token';
const apiBaseUrl = process.env.REACT_APP_API_URL;
const headers = {
  'Content-Type': 'application/json',
  'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
};
/*
 * Fetch Details by Get Method
 */
export const get_api_call = async (api, payload, notify_error=true) => {
  // console.log(payload, 'payloadpayload')
  var response = {status : 500, data: {}};
  try{
    // console.log(headers, 'headers')
    // await token.setAxiosHeaderToken();
    const data = await axios.get(`${apiBaseUrl}/${api}`,
                                  {params: payload,
                                  headers});
    return data.data;
  }catch(error){
      console.log(error, 'eeeeeeee')
    // return cb(response);
    if(notify_error){
      return errorResponse({error, alert_error: 1});
    }else{
      return response;
    }
  }
};
/*
 * Fetch Details by Post Method
 */
export const post_api_call = async(api, payload) => {
//   var response = {status : 500, data: {}};
  // console.log(api)
  try{
        // await token.setAxiosHeaderToken();
    // token.setAxiosHeaderToken({}, async cb_token => {
        const data = await axios.post(`${apiBaseUrl}/${api}`, payload, {headers});
        // console.log(data)
        return data.data;
    //   axios({
    //     method: 'POST',
    //     url: `${apiBaseUrl}${api}`,
    //     data: payload,
    //   }).then(res => {
    //     const { data } = res;
    //     if(typeof data.status !== "undefined" 
    //       && data.status === 200
    //     ){
    //       response.status = 200;
    //     }
    //     response.data = data;
    //     return cb(response);
    //   })
    //   .catch(error => {
    //     if (error.response) {
    //       response.data = error.response.data;
    //       response.status = error.response.status;
    //     }
    //     return cb(response);
    //   });
    // });
  }catch(error){
    if(!(typeof payload.fetch_type !== "undefined"
      && payload.fetch_type === 'logout')
    ){
      return errorResponse({error, alert_error: 1});
    }
    console.log(error)
    // return cb(response);
    return;
  }
};
/*
 * Call Error API
 */
export const error_api_call = async(payload) => {
    try{
      await axios.post(`${apiBaseUrl}/app-error`, {app: 'website', error: payload.error}, {headers});
      return 'Api Called';
    }catch(error){
      console.log(error)
      return 'Api Called:Error';
    }
  };