import React, {useState, useEffect, useRef} from 'react';
import { useDispatch } from 'react-redux';
import {useLocation} from 'react-router-dom';
import {
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  Form,
  InputGroup,
  Row
} from 'react-bootstrap';
import '../../assets/css/pages/form.css';
import { SpinnerRoundFilled } from 'spinners-react';
import ValidationHelper from '../../helper/validation';
//Axios Call
import {reset_password} from '../../actions/action-creators/auth';
import {error_api_call} from '../../actions/action-creators/common';
import {SetMetaData} from './../../helper/page-title';

const ResetPassword = () => {
  SetMetaData("Reset Password", "", "");
  const location = useLocation();
    const isMounted = useRef(null);
  const [currentStep, setCurrentStep] = useState(1);//1=>Submit Mobile Number,2=>Reset Password
  //Store Data
  // const authReduxData = useSelector(state => state.auth);
  const dispatch = useDispatch()
  //State
  const inputDefaultValue = {
    password: '',
    mobile_number: '',
    otp: '',
  };
  const [inputFields, setFields] = useState(inputDefaultValue);
  const [secureOtp, setsecureOtp] = useState({otp: true, password: true});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isLoginProcess, setIsLoginProcess] = useState(false);
  const [isError, setIsError] = useState('');
  const [redirectUri, setRedirectUri] = useState('');
  //Redirection Url
  useEffect(() => {
    const urlHandling = () => {
      try{
        // const urlQuryString = window.location.search;
        const urlQuryString = location.search;
        const urlParam = new URLSearchParams(urlQuryString);
        const urlRedirect = urlParam.get('redirect_uri');
        const userNameRedirect = urlParam.get('user_name');
        if(userNameRedirect
          && ValidationHelper.validateNumber(userNameRedirect)
        ){
          setFields({...inputFields, mobile_number: userNameRedirect});
        }
        console.log(urlRedirect, 'urlRedirect')
        if(urlRedirect !== '' && urlRedirect !== null){
          setRedirectUri(urlRedirect);
        }
      }catch(e){
        error_api_call({error: `auth/reset-password|urlHandling|${e}`});
      }
    }
    urlHandling();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  //Update Input Box
  const _onChangeText = (attrName, value) => {
    try{
      setIsError('');
      let fields = {...inputFields};
      if(currentStep === 1){//Submit Mobile Number
        if(attrName === 'mobile_number' 
          && value.length < 11
        ){
          value = value.replace(/[^0-9]/g, '');
          // fields[attrName] = value;
          fields[attrName] = value;
        }
      }else{
        if(attrName === 'otp' 
          && value.length < 7
        ){
          value = value.replace(/[^0-9]/g, '');
        }else{
          value = value.replace(/ /g, '');
        }
        fields[attrName] = value;
      }

      setFields({...fields});
    }catch(e){
      error_api_call({error: `auth/reset-password|_onChangeText|${JSON.stringify(inputFields)}|${e}`});
    }
  }
  //Input Validation:Use to Toggle Button: Disable-true|false
  useEffect(()=>{
    //Validate Input
    const _validate = () => {
      // if (!didMount) return null;
      let fields = {...inputFields};
      try{
        var validators = {mobile_number: 'required|digit:10'};
        var validation = [];
        // validators[''] = ;//Submit Mobile Number
        if(currentStep !== 1){
          validators['password'] = 'required|password|min:6|max:30';
          validators['otp'] = 'required|digit|min:4|max:6';
        }
        console.log(fields, 'fields', validators)
        validation = ValidationHelper.validation(fields, validators).error;
        console.log(validation, 'validation')
        setSubmitDisabled(validation.length ? true : false);
        return validation.length ? false : true;
      }catch(e){
        error_api_call({error: `auth/reset-password|_validate|${JSON.stringify(inputFields)}|${e}`});
      }
    };
    _validate();
    isMounted.current = true;
    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [inputFields]);// eslint-disable-line react-hooks/exhaustive-deps
  //Toggle Show-Hide Password
  const _showHideOtp = (field) => {
    setsecureOtp({...secureOtp, [field]: !secureOtp[field]})
  };
  //Reset Password
  const _resetPassword = async() => {
    //Validation:Already done
    //Show Loader
    try{
      var url = 'reset-password';
      var formData = {...inputFields};
      if(currentStep === 1){
        url = 'reset-password/user-check';
        formData = {mobile_number: formData.mobile_number};
      }
      setIsLoginProcess(true);
      setIsError('');
      dispatch(reset_password(url, formData, cb_reset => {
        console.log(cb_reset, 'cb_reset')
        if (isMounted.current) {
          if(currentStep === 1){
            if(cb_reset.status === 200){//Exists
              setSubmitDisabled(true);
              setCurrentStep(2);//Reset Password
            }else{
              setIsError(cb_reset.msg);
            }
          }else{//Reset Password
            if(cb_reset.status === 200){
              if(cb_reset.data.is_verified === 1){
                window.location.href = redirectUri ? `/${redirectUri}` : "/";
              }else{
                window.location.href = redirectUri ? `/verification?redirect_uri=${redirectUri}` : '/verification';
              }
            }else{
              setIsError(cb_reset.msg);
            }
          }
          setIsLoginProcess(false);   
        }
      }));
    }catch(e){
      console.log(e)
      setIsError('Error Re-setting Password ! Please Try Again');
      setIsLoginProcess(false);
      error_api_call({error: `auth/reset-password|_resetPassword|${JSON.stringify(inputFields)}|${e}`});
    }
  }
  //Forget Password Redirect
  const _loginPageRedirection = (e) => {
    e.preventDefault();
    if(ValidationHelper.validateNumber(inputFields.user_name)){
      window.location.href = `/login?redirect_uri=${redirectUri}&user_name=${inputFields.mobile_number}`;
    }else{
      window.location.href = "/login";
    }
  }

  return (
    <div className="c-app c-default-layout align-items-center custom-form">
      <Container style={{minHeight: '66vh'}}>
        <Row className="justify-content-center">
          <Col md="4">
            <CardGroup>
              <Card className="p-2" style={{margin: '55px 0px'}}>
                <Card.Body>
                <form name="login" id="login">
                    <h5 className="text-center mb-5">
                      Reset Password
                    </h5>
                    {/* <p className="text-muted text-center">Sign In to your account</p> */}
                    <div className="mb-3">
                      <InputGroup>
                        <InputGroup.Text><i className="fa fa-phone"></i><em>*</em></InputGroup.Text>
                        <Form.Control 
                          type="text" 
                          placeholder="Mobile Number" 
                          autoComplete="mobile_number" 
                          value={inputFields.mobile_number}
                          onChange={(event) => _onChangeText('mobile_number', event.target.value)}
                          maxLength={10}
                          readOnly={currentStep !== 1 || isLoginProcess ? true : false}
                        />
                      </InputGroup>
                      {/* <p className="input-info">Space Not Allowed</p> */}
                    </div>
                    {currentStep > 1 &&
                      <>
                        <div className="mb-3">
                          <InputGroup>
                            <InputGroup.Text><i className="fa fa-lock"></i><em>*</em></InputGroup.Text>
                            <Form.Control 
                              type={secureOtp.otp ? "password" : "text"}
                              placeholder="OTP" 
                              autoComplete="current-otp" 
                              value={inputFields.otp}
                              onChange={(event) => _onChangeText('otp', event.target.value)}
                              maxLength={6}
                              readOnly={isLoginProcess ? true : false}
                            />
                            {inputFields.otp !== "" &&
                              <i className={`fa icon-eye ms-3 ${secureOtp.otp ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => _showHideOtp('otp')}></i>
                            }
                          </InputGroup>
                          <p className="input-info">OTP Sent is valid for only 15 Minutes</p>
                        </div>
                        <div className="mb-3">
                          <InputGroup>
                              <InputGroup.Text><i className="fa fa-lock"></i><em>*</em></InputGroup.Text>
                            <Form.Control 
                              type={secureOtp.password ? "password" : "text"}
                              placeholder="Password" 
                              autoComplete="current-password" 
                              value={inputFields.password}
                              onChange={(event) => _onChangeText('password', event.target.value)}
                              maxLength={30}
                              readOnly={isLoginProcess ? true : false}
                            />
                            {inputFields.password !== "" &&
                              <i className={`fa icon-eye ms-3 ${secureOtp.password ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => _showHideOtp('password')}></i>
                            }
                          </InputGroup>
                          <p className="input-info">Minimum 6 Characters, Maximum 30 Characters</p>
                        </div>
                        
                      </>
                    }
                    <Row className='mt-5'>
                      {isError &&
                        (<Col xs="12" className="text-center">
                            <div className="alert alert-danger" role="alert">
                                {isError}
                            </div>
                        </Col>)
                        }
                      <Col xs="12" className="text-center">
                        {isLoginProcess ? 
                          <Button
                            title="Submit"
                            disabled={true}
                            color={'primary'}
                          >
                            <SpinnerRoundFilled color="#fff" size={20} />
                            {currentStep === 2
                            ? 'Updating...'
                            : 'Submiting...'
                            }
                          </Button>
                          : 
                          <Button
                            type="submit"
                            disabled={submitDisabled}
                            onClick={() => _resetPassword()}
                            color={"primary"}
                            className="px-4"
                          >{currentStep === 2
                            ? 'Update Password'
                            : 'Submit'
                            }</Button>
                        }
                      </Col>
                      {currentStep === 1 &&
                        <Col xs="12" className="text-right">
                          <p className="link">
                            <button
                              className='btn p-0 link-custom'
                              onClick={(e) => _loginPageRedirection(e)}
                              disabled={false}
                            >Back to Login Page</button>
                          </p>
                        </Col>
                      }
                      {/* <Col xs="12" className="text-center">
                            <Link to="/register">
                                <p className="link link-highlight">Don`t have acoount? Create one, click here</p>
                            </Link>
                      </Col> */}
                    </Row>
                  </form>
                </Card.Body>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ResetPassword
