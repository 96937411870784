import React from "react";
import {
    Card,
    Col,
    Row
  } from 'react-bootstrap';
import {SetMetaData} from './../../helper/page-title';
import EmailLogo from "./../../assets/images/contact-us.png";

function ContactUs(){
    SetMetaData("Contact Us", "", "");

    return (
        <div className="container">
        <Row className="justify-content-center">
            <Col md="9" sm="12">
                <Card className="m-2 mt-4 mb-5">
                    <div className="p-3 static-pages text-center">
                        <h5 className="pt-3">Contact Us</h5>
                        <br/>
                        <p><img style={{height: 75}} src={EmailLogo} title="Contact Us" alt="Contact Us" /></p>
                        <p>You are always welcome to provide feedback, suggestions and ask queries if you have any.</p>
                        <p>Feel free to contact us. Just Drop an email at <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}><b>{process.env.REACT_APP_SUPPORT_EMAIL}</b></a>, We will get back to you within 24 hrs.</p>
                    </div>
                </Card>
            </Col>
        </Row>
        </div>
    )    
}

export default ContactUs;