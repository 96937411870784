import { combineReducers } from "redux";
import auth from "./auth";
import stateUpdate from "./state-update";

const rootReducer = combineReducers({
  auth,
  stateUpdate
});

export default rootReducer;
