import React , {useState, useEffect, useRef} from 'react';
import { useParams, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {get_api_call, post_api_call, error_api_call} from '../../actions/action-creators/common';
import {update_cart_count} from '../../actions/action-creators/state-update';
import ProductFilter from './../../components/forms/product-filter';
import {LoaderMedium} from './../../components/loader';
import {SetMetaData} from './../../helper/page-title';
import {urlToString} from './../../helper/custom-function';
import TestSingle from './../../components/list/test-single';
import {alertResponseMessage} from '../../actions/action-creators/response';
import noProduct from '../../assets/images/oops.png';
import {toggleBodyLoader} from '../../helper/custom-function';

export default function TestList(props){
	let params = useParams();
	const deviceID = localStorage.getItem("device_id");
	var pageTitle = params.url || 'Lab Test';
	pageTitle = urlToString(pageTitle);
	SetMetaData(pageTitle, "", "");
	const location = useLocation();
	const dispatch = useDispatch();
	const [title, setTitle] = useState(pageTitle);
    const [mainCategoryData, setMainCategoryData] = useState([]);
    const [organCategoryData, setOrganCategoryData] = useState([]);
	const [filterData, setFilterData] = useState({type: '', category: [], is_package: [], organ: []});
	const [orderBy, setOrderBy] = useState('new');
	const [productData, setProductData] = useState([]);
	const [cartData, setCartData] = useState({});
	const [itemLoader, setItemLoader] = useState({});
	const [productPage, setProductPage] = useState(0);
	const [callProAPI, setCallProAPI] = useState(0);
    const [filterCheckedData, setCheckedData] = useState({is_package: {}, organ: {}, category: {}});
	const [queryKeyword, setKeyword] = useState('');
	const [productTotal, setProductTotal] = useState(0);
	//Loading
	const [showProductLoader, setProductLoader] = useState(true);
	const [productLoading, setProductLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const limit = 15;
	const loadMoreProduct= useRef(null);//Button Click
	const productLoader = useRef(null);
	//Get Data for Filter Options
    const fetchAPIList = async(api) => {
		console.log(api);
        try{
            const filteredData = await get_api_call(api, {});
            if(api === 'lab-test-category' && filteredData.status === 200){
                setMainCategoryData(filteredData.data);
            }else if(api === 'lab-test-organ' && filteredData.status === 200){
                setOrganCategoryData(filteredData.data);
            }
			console.log(api, '5555');
        }catch(e){
			error_api_call({error: `test/list|fetchAPIList|${e}`});
        }
    }
	//Update Item Cart Quantity
	const addToCart = async(e, id, qty) => {
		try{
			console.log(id, qty)
			e.preventDefault();
			e.target.disabled = true;
			setItemLoader({...itemLoader, [id]: true});
			//Update Cart Item API Call
			const cartApiData = await post_api_call('update-cart-test', 
													{device_id: deviceID,
														// variation_detail_id,
														id,
														quantity: qty
													});
			if(cartApiData.status === 200){
				//Notify Msg
				const message = cartApiData.message; 
				if(cartApiData.success_status === 1
					|| cartApiData.success_status === 2
				){
					//Success
					alertResponseMessage({alert_type: 1, message});
				}else{
					//Error
					alertResponseMessage({alert_type: 2, message});
				}
				// setCartData({...cartData, [variation_detail_id]: qty});
				setCartData({...cartData, [id]: qty});
				// setCartData({...cartData, [id]: 1});
				if(cartApiData.data.cart_count !== ''){
					dispatch(update_cart_count({cart_count: cartApiData.data.cart_count}, cb_cart => {}));
				}
			}
			setItemLoader({...itemLoader, [id]: false});
			e.target.disabled = false;
		}catch(e){
			// console.log(e)
			e.target.disabled = false;
            alertResponseMessage({alert_type: 2, message: "Error Updating Cart"});
			error_api_call({error: `test/list|addToCart|${e}`});
        }
	}
	//On Checkbox Click & Select Option Change
	const handleFilterData = (field, is_checked, id) => {
        try{
			toggleBodyLoader('load');
			if(field === "category"
				|| field === "organ"
				|| field === "is_package"
			){
				// setTitle('Lab Test');
				var newFilterData = {...filterData};
				var fieldFilterData = [...newFilterData[field]];
				var newCheckedData = {...filterCheckedData};
				var checkedData = {...newCheckedData[field]}
				//If Checked Add, else remove
				if(is_checked){
					fieldFilterData.push(id);
					checkedData[id] = true;
					newCheckedData[field] = checkedData;
					setCheckedData({...newCheckedData});
					// setSubCatCheckedData({...subCatCheckedData, [id]: true});
				}else{
					const index = fieldFilterData.indexOf(id);
					if(index > -1){
						fieldFilterData.splice(index, 1);
						checkedData[id] = false;
						newCheckedData[field] = checkedData;
						setCheckedData({...newCheckedData});
						// setSubCatCheckedData({...subCatCheckedData, [id]: false})
					}
				}
				newFilterData[field] = fieldFilterData;
				setFilterData({...newFilterData});
				modifyAdBarUrl(field, fieldFilterData);
			}else if(field === "sort_by"){
				setOrderBy(id);
				modifyAdBarUrl(field, [id]);
			}
			setPageLoading(true);
			setProductPage(productPage === 1 ? 0 : 1);
        }catch(e){
			alertResponseMessage({alert_type: 2, message: "Error Loading Lab Test! Check back after some times"});
			// console.log(e)
			error_api_call({error: `test/list|handleFilterData|${e}`});
			return false;
        }
    }
	//Modify URL
	const modifyAdBarUrl = (key, value) => {
		try{
			// if(typeof value === 'array'){
			if(value instanceof Array){
				const url = new URL(window.location.href);
				if(value.length === 0){
					url.searchParams.delete(key);
				}else{
					url.searchParams.set(key, value);
				}
				
				window.history.pushState({}, '', url.toString());
			}
		}catch(e){
			// console.log(e)
			error_api_call({error: `test/list|modifyAdBarUrl|${e}`});
			return false;
        }
	};
	//Load More Data when loader is in focus
	const infiniteCustomScroll = () => {
		if(loadMoreProduct.current){
			loadMoreProduct.current.click();
		}
	}
	//Initial Load & Read More Implementation
	useEffect(() => {
        const options = {
           root: null,
           rootMargin: "20px",
           threshold: 1.0
        };
       // initialize IntersectionObserver
       // and attaching to Load More div
        //For All
		const observerAll = new IntersectionObserver(infiniteCustomScroll, options);
        if (productLoader.current) {
            observerAll.observe(productLoader.current)
        }
		const clean = productLoader.current;
        return () => {
            if (clean) {
                observerAll.disconnect(clean);
            }
        }
    }, [productLoader.current]);// eslint-disable-line react-hooks/exhaustive-deps
	//Call Filter Data
	useEffect(() => {
		const urlHandling = () => {
			try{
				//URL Param type Handling
				// const pageUrlId = params.id || '';
				// const urlQuryString = window.location.search;
				const urlQuryString = location.search;
				const urlParam = new URLSearchParams(urlQuryString);
				// const urlType = urlParam.get('type');
				// if(pageUrlId && pageUrlId !== ''){
				if(urlParam.size > 0){
					const filterKeys = ['category', 'organ', 'is_package', 'sort_by', 'keyword'];
					var isFilterTest = 0;
					var checkedDataNew = {...filterCheckedData};
					var filterDataNew = {...filterData};
					urlParam.forEach((value, key) => {
						if(filterKeys.indexOf(key) > -1){
							console.log(value, key);
							if(key !== 'sort_by'
								&& key !== 'keyword'
							){
								isFilterTest = 1;
								if(!isNaN(parseInt(value.replace(',', '')))){
									var urlParamData = value.split(',');
									urlParamData =  urlParamData.map(Number);
									filterDataNew[key] = urlParamData;
									// setFilterData({...filterData, [key]: urlParamData});
									var checkedData = {};
									for(var i in urlParamData){
										checkedData[urlParamData[i]] = true;
									}
									checkedDataNew[key] = checkedData;
									// setCheckedData({...filterCheckedData, [key]: checkedData});
								}
							}else if(key === 'keyword'){
								// const urlTypeQuery = urlParam.get('query');
								if(value && value !== ''){
									setKeyword(value);
									setTitle(`Lab Test-${value}`);
								}else{
									setTitle(`Lab Test`);
								}
							}else{
								setOrderBy(value);
							}
						}
					});
					if(isFilterTest === 1){
						setFilterData({...filterDataNew});
						setCheckedData({...checkedDataNew});
					}
					// else if(urlType === "suggested_for_you"//recommended
					// 	|| urlType === "trending"//trending
					// 	|| urlType === "viewed"//viewed
					// ){
					// 	setFilterData({...filterData, type: [pageUrlId]});
					// }
				}
				setCallProAPI(1);
				setProductPage(productPage === 1 ? 0 : 1);
			}catch(e){
				alertResponseMessage({alert_type: 2, message: "Error Loading Lab Test! Check back after some times"});
				// console.log(e);
				// setProductLoading(false);
				error_api_call({error: `test/list|urlHandling|${e}`});
			}
		}
        // fetchAPIList('category');
		fetchAPIList('lab-test-category');
        fetchAPIList('lab-test-organ');
		urlHandling();
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
	//Call Product Filter API
	useEffect(() => {
		//Get Filtered Products
		const fetchData = async() => {
			try{
				//APi Call
				if(!productLoading){
					setProductLoading(true);
					const proCurrentPage = productPage < 2 ? 1 : productPage;
					if(proCurrentPage === 1){
						setProductData([]);
					}
					const newProductList = await post_api_call('lab-tests', 
																{limit,
																page: proCurrentPage,
																filter: filterData,
																order_by: orderBy,
																keyword: queryKeyword,
																device_id: deviceID
															});
					if(newProductList.status === 200){
						console.log(newProductList)
						const newProductData = proCurrentPage < 2
												? newProductList.data 
												: productData.concat(newProductList.data);
						var cartDetails = {};
						// var loaderData = {};
						// var newProductState = {};
						newProductList.data.forEach(pro_data => {
						// 	newProductState[pro_data.product_id] = {image: pro_data.variation_data[0].thumbnail,
						// 									mrp: pro_data.variation_data[0].mrp,
						// 									price: pro_data.variation_data[0].price,
						// 									// cart_count: pro_data.variation_data[0].added_quant,
						// 									variation_name: pro_data.variation_data[0].variation_name,
						// 									variation_detail_id: pro_data.variation_data[0].variation_detail_id
						// 								};
						// 	pro_data.variation_data.forEach(r_cart => {
								cartDetails[pro_data.id] = pro_data.cart_quantity ? parseInt(pro_data.cart_quantity) : 0;
						// 		// loaderData[r_cart.variation_detail_id] = r_cart.added_quant;
						// 	});
						});
						// setActiveProduct({...activeProduct, ...newProductState});
						setCartData({...cartData, ...cartDetails});
						setProductData(newProductData);
						// setProductPage(proCurrentPage);
						if(proCurrentPage === 1){
							setProductTotal(newProductList.total);
							setPageLoading(false);
						}
					}
					setProductLoading(false);
					toggleLoader(proCurrentPage, newProductList.total);
					if(proCurrentPage === 1){
						toggleBodyLoader('remove');
					}
				}
			}catch(e){
				alertResponseMessage({alert_type: 2, message: "Error Loading Lab Test! Check back after some times"});
				// console.log(e);
				setProductLoading(false);
				error_api_call({error: `test/list|fetchData|${e}`});
			}
		}
		if(callProAPI === 1){
			fetchData();
			// setTimeout(() => {
				// toggleBodyLoader('remove');
			// }, 5000)
			
		}
	}, [productPage]);// eslint-disable-line react-hooks/exhaustive-deps
	//Load More Products
	const loadMore = () => {
		setProductPage(productPage < 2 ? 2 : productPage+1);
	}
	//Show-Hide Item Loader
	const toggleLoader = (current_page, total_page) => {
        try{
            var response = {page_count: 0, is_show: false};
			const totalPage = total_page <= limit
							? 1
							: total_page % limit === 0 
							? total_page/limit 
							: parseInt((total_page/limit))+1;
							// console.log(current_page, 'current_page')
							// console.log(totalPage, 'totalPage')
			if(current_page < totalPage){
				response.is_show = true;
				setProductLoader(true);
			}else{
				setProductLoader(false);
			}
			response.page_count = totalPage;
            return response;
        }catch(e){
            return response;
        }
    }

    return (
        <div className="container">
			<div className={`${filterData.type === '' ? 'col-md-9 offset-md-3' : 'col-md-12'} col-12 col-sm-12`}>
				<div className="section-title mt-4">
					<h3>{title}</h3>
				</div>
			</div>
			<div className="row">
				{productData.length > 1 && (
					<p className="fade-title">Showing {productData.length}/{productTotal} Lab Test</p>
				)}
				{/* Filter */}
				{filterData.type === ''
				&& (<div className="col-md-3 col-lg-3 col-12 col-sm-12">
						<ProductFilter
							data={[{title: "Sort By",
									field: "title",
									field_key: 'id',
									filter_key: "sort_by",
									options: [{id: "new", title: "Relevance"},
												{id: "low", title: "Price Low to High"},
												{id: "high", title: "Price High to Low"}],
									type: "select",
									selected_value: orderBy
									// key: "sort_by"
								},
                                {title: "Test Type",
									field: "title",
									field_key: 'id',
									filter_key: "is_package",
									options: [{id: 0, title: "Single Test"},
												{id: 1, title: "Package"}],
									type: "checkbox",
                                    checked_data: {...filterCheckedData.is_package}
								},
								{title: "Category",
									field: "name",
									field_key: 'id',
									filter_key: "category",
									options: mainCategoryData ,
									type: "checkbox",
									checked_data: {...filterCheckedData.category}
								},
                                {title: "Organ",
									field: "name",
									field_key: 'id',
									filter_key: "organ",
									options: organCategoryData ,
									type: "checkbox",
									checked_data: {...filterCheckedData.organ}
								},
								// {title: "Category",
								// 	field: "category",
								// 	field_key: 'id',
								// 	filter_key: "category",
								// 	options: mainCategoryData ,
								// 	type: "collapse",
								// 	checked_data: subCatCheckedData,
								// 	sub_option_data_key: "sub_category",
								// 	sub_value_key: 'id',
								// 	sub_title_key: "sub_category",
								// 	// key: "sub_category"
								// }
							]}
							handleFilterData={(field, is_checked, id) => handleFilterData(field, is_checked, id)}
						/>
					</div>
				)}
				{/* Product Listing */}
				
				<div className={`col-md-${filterData.type === '' ? 9 : 12} col-12 col-sm-12`}>
					{pageLoading 
					?
						<div className="text-center mt-5">
							<LoaderMedium size={65} />
						</div>
					:
						productData.length > 0 ?
							<>
								<div className="row">
									<TestSingle
										data={productData}
										addToCart={addToCart}
										// activeData={activeProduct}
										cartData={cartData}
										// variationOnChange={variationOnChange}
										itemLoader={itemLoader}
										fetchKey={"product"}
										mdCol={filterData.type === '' ? 3 : 2}
									/>
								</div>
								{showProductLoader && 
									<div className="text-center">
										{/* <button className="btn btn-primary" ref={loadMoreProduct} onClick={() => loadMore()} style={{visibility: "hidden"}}>Load More</button> */}
										<div ref={productLoader}>
											<LoaderMedium />
										</div>
										<button className="btn btn-primary" ref={loadMoreProduct} onClick={() => {loadMore()}}>Load More</button>
									</div>
								}
							</>
						: 
						<div className="row">
							<div className="no-product mt-4">
								<img src={noProduct} alt="No Matching Lab Test Found" />
								<p className="pt-5 title">Sorry, no matching Lab Test found!</p>
								<p className="sub-title ml-2 mr-2">Please try with different filter or try searching for something else</p>
							</div>
						</div>
					}
				</div>
			</div>
        </div>
    )
}
