// import Navbar from './../navbar';

function WrapMaster(props) {
  return (
    <> 
        {/* <Navbar /> */}
        {/* <div className="container"> */}
          {props.children}
        {/* </div> */}
    </>
  );
}

export default WrapMaster;