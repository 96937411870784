import $ from 'jquery';
function checkOffset() {
    const divFixed=$(document).scrollTop()+window.innerHeight;
    const divFooter=$('#footer').offset().top;
    if (divFixed<divFooter) {
      $('#div-float').css('bottom', '-60px');
    } else {
      $('#div-float').css('bottom', (50+(divFixed-divFooter))+'px');
    }
  }
$(document).on( 'ready', function (e) {
    checkOffset();
});
$(document).on( 'scroll', function (e) {
    checkOffset();
});