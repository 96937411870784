import React, {useState, useEffect, useRef} from "react";
import {useParams} from 'react-router-dom';
import {post_api_call, error_api_call} from '../../../actions/action-creators/common';
import {LoaderMedium} from './../../../components/loader';
import ListingRowOrder from './../../../components/list/row-order';
import {
    Row,
} from 'react-bootstrap';
import {SetMetaData} from './../../../helper/page-title';
import {alertResponseMessage} from '../../../actions/action-creators/response';
import './../../../assets/js/cart';
import orderNowImg from '../../../assets/images/order-now.png';

function MyOrder(){
    SetMetaData("My Orders", "", "");
    const params = useParams();
    const [orderData, setOrderData] = useState([]);
	const [orderPage, setOrderPage] = useState(0);
    const [orderLoading, setOrderLoading] = useState(false);
    const [showOrderLoader, setOrderLoader] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    const [totalOrder, setTotalOrder] = useState(0);
    const limit = 10;
    const productLoader = useRef(null);
    const loadMoreProduct= useRef(null);//Button Click
    //Call Product Filter API
	useEffect(() => {
		//Get Filtered Products
		const fetchData = async() => {
			try{
				//APi Call
				if(!orderLoading){
					setOrderLoading(true);
					const proCurrentPage = orderPage < 2 ? 1 : orderPage;
					if(proCurrentPage === 1){
						setOrderData([]);
					}
                    // const api = params.product_type === 'lab' ? 'lab' : 'medicine';
					const newOrderList = await post_api_call(`order/${params.product_type}`,
                                                            {limit,
                                                            page: proCurrentPage
                                                        });
                    console.log(newOrderList, 'newOrderList.status')
					if(newOrderList.status === 200){
						const newOrderData = proCurrentPage < 2
												? newOrderList.data
												: orderData.concat(newOrderList.data);

						setOrderData(newOrderData);
						if(proCurrentPage === 1){
							setPageLoading(false);
                            setTotalOrder(newOrderList.total_order);
                            toggleLoader(proCurrentPage, newOrderList.total_order);
						}else{
                            toggleLoader(proCurrentPage, totalOrder);
                        }
                        setOrderLoading(false);
					}else{
                        setOrderLoader(false);
                        setOrderLoading(false);
                    }
				}
			}catch(e){
				alertResponseMessage({alert_type: 2, message: "Error Loading Data! Check back after some times"});
				console.log(e);
                setOrderLoader(false);
				setOrderLoading(false);
				error_api_call({error: `account/order|fetchData|${e}`});
			}
		}
		// if(callProAPI === 1){
			fetchData();
		// }
	}, [orderPage]);// eslint-disable-line react-hooks/exhaustive-deps
	//Load More Products
	const loadMore = () => {
		setOrderPage(orderPage < 2 ? 2 : orderPage+1);
	}
    //Load More Data when loader is in focus
	const infiniteCustomScroll = () => {
		if(loadMoreProduct.current){
			loadMoreProduct.current.click();
		}
	}
    //Initial Load & Read More Implementation
	useEffect(() => {
        const options = {
           root: null,
           rootMargin: "20px",
           threshold: 1.0
        };
       // initialize IntersectionObserver
       // and attaching to Load More div
        //For All
		const observerAll = new IntersectionObserver(infiniteCustomScroll, options);
        if (productLoader.current) {
            observerAll.observe(productLoader.current)
        }
		const clean = productLoader.current;
        return () => {
            if (clean) {
                observerAll.disconnect(clean);
            }
        }
    }, [productLoader.current]);// eslint-disable-line react-hooks/exhaustive-deps

    //Show-Hide Item Loader
	const toggleLoader = (current_page, total_order_count) => {
        try{
            console.log(current_page, total_order_count, 'ppppppppppppppp')
            var response = {page_count: 0, is_show: false};
			const totalPage = total_order_count <= limit
							? 1
							: total_order_count % limit === 0 
                                ? total_order_count/limit 
                                : parseInt((total_order_count/limit))+1;
							console.log(current_page, 'current_page')
							console.log(totalPage, 'totalPage')
			if(current_page < totalPage){
				response.is_show = true;
				setOrderLoader(true);
			}else{
				setOrderLoader(false);
			}
			response.page_count = totalPage;
            return response;
        }catch(e){
            return response;
        }
    }

    return (
        <div className="container">
            <Row className="justify-content-center">
                <div className="col-md-12 col-12 col-sm-12">
                    <div className="section-title mt-5 mb-4">
                        <h3>My {params.product_type === 'lab' ? 'Lab Test' : 'Medicine'} Orders</h3>
                    </div>
                </div>
                {pageLoading 
					?
						<div className="text-center mt-5">
							<LoaderMedium size={65} />
						</div>
					:
                        <>
                        {orderData.length > 0 ? (
                            <>
                                <div className="col-md-8 col-lg-8 col-12 col-sm-12 mb-5">
                                    <div className="card">
                                        <div>
                                            <h6 className="p-3 pb-0 float-start">Orders</h6>
                                        </div>
                                        <div className="m-3 mt-3 scrollable-div">
                                        <>
                                            {orderData.map((order, index) => (
                                                <React.Fragment key={index}>
                                                    <ListingRowOrder
                                                        data={order}
                                                        page_type={params.product_type}
                                                    />
                                                </React.Fragment>
                                            ))}
                                            {showOrderLoader && 
                                                <div className="text-center">
                                                    <div ref={productLoader}>
                                                        <LoaderMedium />
                                                    </div>
                                                    <button className="btn btn-primary" ref={loadMoreProduct} onClick={() => {loadMore()}}>Load More</button>
                                                </div>
                                            }
                                        </>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ): (
                            <div className="row">
                                <div className="no-product mt-4">
                                    <p className="pt-5 title">You have not placed any orders Yet</p>
                                    <p className="sub-title ml-2 mr-2"><a href={`/${params.product_type === 'lab' ? 'book-lab-test' : '/order-medicines'}`}><img alt="Order Now" src={orderNowImg}/></a></p>
                                </div>
                            </div>
                        )}
                    </>
                }
        </Row>
        </div>
    )    
}

export default MyOrder;