import React, {useState, useEffect} from "react";
// import { useSelector } from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {post_api_call, error_api_call} from '../../actions/action-creators/common';
import {LoaderMedium} from './../../components/loader';
import ListingRow from '../../components/list/row-cart';
// import Modal from 'react-bootstrap/Modal';
import {
    Row
} from 'react-bootstrap';
import {SetMetaData} from './../../helper/page-title';
import {alertResponseMessage} from '../../actions/action-creators/response';
import './../../assets/js/cart';
import redirectionGif from './../../assets/images/redirect-loader.gif';
import processingGif from './../../assets/images/order-processing.gif';

function CheckoutPayment(){
    SetMetaData("Checkout", "", "");
    const deviceID = localStorage.getItem("device_id");
    const location = useLocation();
    const navigate = useNavigate();
    const [addressData, setAddressData] = useState({});
    const [productData, setProductData] = useState([]);
    const [cartData, setCartData] = useState([]);
    const [productLoading, setProductLoading] = useState(false);
    const [priceDetails, setPriceDetails] = useState({});
    const [checkoutDetails, setCheckoutDetails] = useState({});
    const [pageLoading, setPageLoading] = useState(true);
    const [checkoutLoader, setCheckoutLoader] = useState(false);
    const [cartError, setCartError] = useState([]);
    const [checkoutStatus, setCheckoutStatus] = useState(0);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [subOrderId, setSubOrderId] = useState('');
    // const [notesData, setNotesDate] = useState('');
    const [paymentModeRadio, setPaymentMode] = useState('online');
    const [pageType, setPageType] = useState('medicine');
    //Redirect to Respective Page if error
    useEffect(() => {
      let timer = setTimeout(() => {
        if(redirectUrl !== ''){
          navigate(redirectUrl, { replace: true });
        }
      }, 7000);
      return () => {
        clearTimeout(timer);
      };
    }, [redirectUrl]);// eslint-disable-line react-hooks/exhaustive-deps
    //Call Product Filter API
    useEffect(() => {
      const urlQuryString = location.search;
      const urlParam = new URLSearchParams(urlQuryString);
      const redirectedPage = urlParam.get('page') || 'medicine';
      setPageType(redirectedPage);
      //Get Filtered Products
      const fetchData = async(address_id, coupon_code) => {
        try{
          //APi Call
          if(!productLoading){
            setProductLoading(true);
            var orderData = {address_id,
                            device_id: deviceID};
            // const couponCode = localStorage.getItem("coupon_code");
            if(coupon_code){
              orderData.code = coupon_code;
            }

            const newProductList = await post_api_call(`order-creation/${redirectedPage}`, orderData);
                      console.log(newProductList, 'newProductList.status')
            if(newProductList.status === 200){
              setCheckoutStatus(newProductList.success_status);
              // success_status: 1=>success,2=>Error in Delivery Adddress,3=>Error in Cart
              if(newProductList.success_status === 1){
                if(newProductList.data.data === 0){
                  navigate('/my-cart', { replace: true });
                  return false;
                }
                var cartDetails = {};
                newProductList.data.data.forEach(pro_data => {
                  cartDetails[pro_data.id] = pro_data.quantity;
                });
                setCartData({...cartData, ...cartDetails});
                setProductData(newProductList.data.data);
                setPriceDetails(newProductList.data.price_details);
                setCheckoutDetails(newProductList.data.checkout_allowed);
                setAddressData(newProductList.data.address_details);
                setSubOrderId(newProductList.data.reference_id);
              }else if(newProductList.success_status === 2){
                setAddressData(newProductList.data.address_details);
                setCartError(["Sorry! Delivery Address is not Serviceable at this Point of time. Plese select another address or Check back after some times."]);
                setRedirectUrl(`/checkout/address${urlQuryString}`);
              }else if(newProductList.success_status === 3){
                setCartError(newProductList.data.error_msg);
                setRedirectUrl('/my-cart');
              }else{
                setCartError(["Error Loading Product Details! Check back after some times"]);
              }
              setPageLoading(false);
            }else{
              setCartError(["Error Loading Product Details! Check back after some times"]);
            }
            setProductLoading(false);
          }
        }catch(e){
          alertResponseMessage({alert_type: 2, message: "Error Loading Product Details! Check back after some times"});
          console.log(e);
          setProductLoading(false);
          error_api_call({error: `checkout/payment|fetchData|${e}`});
        }
      }
      //Get Address Id
      const addressId = urlParam.get('add_reference_id');
      const couponParam = urlParam.get('coupon_code');
      if(addressId){
        fetchData(addressId, couponParam);
      }else{
        // navigate('/checkout/address', { replace: true });
        navigate('/my-cart', { replace: true });
      }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    //To check date
    const isDate = (val) => {
      // Cross realm comptatible
      return Object.prototype.toString.call(val) === '[object Date]';
    }
    //To check Object
    const isObj = (val) => {
      return typeof val === 'object';
    }
    //Stringify Data
    const stringifyValue = (val) => {
      if (isObj(val) && !isDate(val)) {
        return JSON.stringify(val);
      } else {
        return val;
      }
    }
    //Build Paytm Payment Form
    const buildForm = ({ action, params }) => {
      const form = document.createElement('form')
      form.setAttribute('method', 'post')
      form.setAttribute('action', action)
    
      Object.keys(params).forEach(key => {
        const input = document.createElement('input')
        input.setAttribute('type', 'hidden')
        input.setAttribute('name', key)
        input.setAttribute('value', stringifyValue(params[key]))
        form.appendChild(input)
      })
    
      return form;
    }
    //Redirect to Paytm Gateway
    const paytmPageRedirection = (details) => {
      const form = buildForm(details)
      document.body.appendChild(form)
      form.submit()
      form.remove()
    }
    //checkout
    const checkout = async() => {
        try{
          setCheckoutLoader(true);
          if(paymentModeRadio === 'cod'){
            const placeOrderData = await post_api_call(`place-order/${pageType}/cod`, 
                                                      {reference_id: subOrderId,
                                                        device_id: deviceID,
                                                        // notes: notesData
                                                      });
                                                      // console.log('placeOrderData', placeOrderData.text.original.CHECKSUMHASH)
            console.log(placeOrderData, 'placeOrderData.status')
            // return;
            if(placeOrderData.status === 200){
              //Redirect
              // navigate(`/my-order/lab/details/${subOrderId}?success=true`, { replace: true });
              navigate(`/order-status/${placeOrderData.data.order_id}?status=success&page=${pageType}&mode=cod`, { replace: true });
            }else{
              setCheckoutLoader(false);
              alertResponseMessage({alert_type: 2, message: "Something Went wrong, Please try placing Order Again"});
            }
          }else{
            const placeOrderData = await post_api_call(`payment-paytm/${pageType}`, 
                                                      {reference_id: subOrderId,
                                                        device_id: deviceID,
                                                        // notes: notesData
                                                      });
                                                      // console.log('placeOrderData', placeOrderData.text.original.CHECKSUMHASH)
            // console.log(placeOrderData, 'placeOrderData.status')
            // return;
            if(placeOrderData.status === 200){
              paytmPageRedirection({
                action: process.env.REACT_APP_PAYTM_URL,
                // action: 'https://securegw-stage.paytm.in/theia/processTransaction',
                // action: 'https://securegw.paytm.in/theia/processTransaction',
                params: placeOrderData.data
              });
            }else{
              setCheckoutLoader(false);
            }
          }
        }catch(error){
          setCheckoutLoader(false);
          alertResponseMessage({alert_type: 2, message: "Something Went wrong, Please try after some times"});
          error_api_call({error: `checkout/payment|checkout|${error}`});
        }
    }
    return (
      <div className="container">
        <Row>
          <div className="col-md-12 col-12 col-sm-12">
              <div className="section-title mt-5 mb-5">
              <h3>Order Confirmation & Payment</h3>
              </div>
          </div>
          {pageLoading 
					?
						<div className="text-center mt-5">
							<LoaderMedium size={65} />
						</div>
					:
            <>
              {checkoutLoader === true
              ?
                paymentModeRadio === 'online'
                  ?
                  <Row className="justify-content-center mb-5">
                    <div className="col-md-8 col-lg-8 col-12 col-sm-12">
                      <div className="card pt-4 pb-4 ps-5 pe-5">
                        <div className="alert alert-primary text-center">
                          <p><b>We have reserved the items for You! Please make the payment within 5 minutes to place Order.</b></p>
                        </div>
                        <p className="text-center">You will be redirected shortly to Payment page</p>
                        <img src={redirectionGif} alt="Redirecting"/>
                      </div>
                    </div>
                  </Row>
                  :
                  <Row className="justify-content-center mb-5">
                    <div className="col-md-8 col-lg-8 col-12 col-sm-12">
                      <div className="card pt-4 pb-4 ps-5 pe-5">
                        <div className="alert alert-primary text-center">
                          <p><b>Please do not close this window/page or click the Back button. We are placing your Order.</b></p>
                        </div>
                        {/* <p className="text-center">You will be redirected shortly to Payment page</p> */}
                        <div className="text-center"><img src={processingGif} alt="Processing Order" className="gif-img"/></div>
                      </div>
                    </div>
                  </Row>
              :cartError.length > 0
                ?
                <Row className="justify-content-center">
                  <div className="col-md-8 col-lg-8 col-12 col-sm-12">
                    <div className="card pt-4 pb-4 ps-5 pe-5">
                      <div className="alert alert-danger">
                        {checkoutStatus === 3 && (
                          <p>Please Fix below cart {cartError.length === 1 ? 'error' : 'errors'} (either remove or decrease quantity of product(s) from your cart):</p>
                        )}
                        {cartError.length === 1
                        ?
                          <p>{cartError[0]}</p>
                        :
                          <ul className="ms-2" style={{listStyle: 'disc'}}>
                            {cartError.map((error, error_index) => (
                              <li key={error_index}>{error}</li>
                            ))}
                          </ul>
                        }
                        {checkoutStatus === 2 && (
                          <div>
                          <h6 className="p-3 ps-1 pe-1 pb-0">Delivery Address</h6>
                          <div className="ms-3 mt-2 me-3 me-3 address-card">
                            <p>{addressData.name}</p>
                            <p>{addressData.address_1}</p>
                            <p>{addressData.address_2}</p>
                            {addressData.landmark && (
                              <p>(Landmark: {addressData.landmark})</p>
                            )}
                            <p>{addressData.city}, {addressData.state}-{addressData.pincode}</p>
                            {/* <p><i className='fa fa-phone'></i> {addressData.mobile_number}{addressData.alternative_number ? `,${addressData.alternative_number}` : ''}</p> */}
                          </div>
                          </div>
                        )}
                      </div>
                      {checkoutStatus !== 0 && (
                        <>
                          <p className="text-center">You will be redirected shortly to respective page</p>
                          <img src={redirectionGif} alt="Redirecting"/>
                        </>
                      )}
                    </div>
                  </div>
                </Row>
                :
                productData.length > 0 && (
                  <>
                    <div className="col-md-8 col-lg-8 col-12 col-sm-12">
                        <div className="card">
                            <div>
                              <h6 className="p-3 pb-0 float-start">Total Products: {productData.length}</h6>
                            </div>
                            <div className="m-3 mt-3 scrollable-div">
                              {productData.map((product, index) => (
                                  <React.Fragment key={index}>
                                      <ListingRow
                                          data={product}
                                          cartData={cartData}
                                          display_type={"checkout"}
                                          url={pageType === 'medicine' ? "/order-medicines/detail" : "/book-lab-test/detail"}
                                          urlFetchKey={pageType === 'medicine' ? "product_id" : "id"}
                                          // addCartKey="product_detail_id"
                                      />
                                  </React.Fragment>
                              ))}
                              {/* {pageType === 'medicine'
                                ?
                                <>
                                {productData.map((product, index) => (
                                    <React.Fragment key={index}>
                                        <ListingRow
                                            data={product}
                                            cartData={cartData}
                                            display_type={"checkout"}
                                            url="/order-medicines/detail"
                                            urlFetchKey="product_id"
                                            // addCartKey="product_detail_id"
                                        />
                                    </React.Fragment>
                                ))}
                                </>
                                :
                                <>
                                {productData.map((product, index) => (
                                    <React.Fragment key={index}>
                                        <ListingRow
                                            data={product}
                                            cartData={cartData}
                                            display_type={"checkout"}
                                            url="/book-lab-test/detail"
                                            urlFetchKey="id"
                                            // addCartKey="id"
                                        />
                                    </React.Fragment>
                                ))}
                                </>
                              } */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-12 col-sm-12">
                        <div className="checkout-section pb-2">
                            <div className="card pb-4">
                                <h6 className="p-3 ps-1 pe-1 pb-0 float-start">Delivery Address</h6>
                                <div className="ms-3 mt-2 me-3 me-3 address-card">
                                  <p><b>{addressData.name}</b></p>
                                  <p>{addressData.address_1}</p>
                                  <p>{addressData.address_2}</p>
                                  {addressData.landmark && (
                                    <p>(Landmark: {addressData.landmark})</p>
                                  )}
                                  <p>{addressData.city}, {addressData.state}-{addressData.pincode}</p>
                                  {/* <p><i className='fa fa-phone'></i> {addressData.mobile_number}{addressData.alternative_number ? `,${addressData.alternative_number}` : ''}</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="checkout-section mb-2">
                            <div className="card">
                            <h6 className="p-3 ps-1 pe-1 pb-0 float-start">Price Details</h6>
                            <div className="ms-3 mt-2 me-3 me-3">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>Cart Price ({productData.length} items)</td>
                                            <td>₹{priceDetails.all_total}</td>
                                        </tr>
                                        {priceDetails.discount > 0 && (
                                            <tr>
                                                <td>Discount</td>
                                                <td> − ₹{priceDetails.discount}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td>Delivery Charges</td>
                                            <td style={priceDetails.delivery === 0 ? {color: 'green'} : null}>{priceDetails.delivery === 0 ? 'Free' : `₹${priceDetails.delivery}`}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Total Amount</b></td>
                                            <td><b>₹{priceDetails.total_amount}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="checkout-btn">
                              {/* <div className="mb-3">
                                <InputGroup>
                                  <InputGroup.Text><i className="fa fa-commenting"></i></InputGroup.Text>
                                  <Form.Control
                                    as="textarea" 
                                    type={"text"}
                                    placeholder="Any notes for the seller? Type here"
                                    autoComplete="notes-new" 
                                    value={notesData}
                                    onChange={(event) => setNotesDate(event.target.value)}
                                    maxLength={200}
                                  />
                                </InputGroup>
                              </div> */}
                              {/* <p>
                                <input type={"radio"} defaultChecked/> Pay Online
                              </p>
                              <button
                                className="btn btn-primary"
                                disabled={checkoutDetails.is_checkout === 0
                                            || checkoutLoader
                                            || cartError.length > 0  ? true : false}
                                onClick={() => checkout()}>
                                Confirm & Pay <b>₹{priceDetails.total_amount}</b>
                              </button>
                              <p className="mt-2">By clicking above "Confirm & Pay", you agree to our <a href="/terms-conditions" className="link-danger" target={"_blank"}>Terms & Conditions</a>, <a href="/cancellation-returns" className="link-danger" target={"_blank"}>Cancellation & Returns Policy</a> and <a href="/payments" className="link-danger" target={"_blank"}>Payments Policy</a></p> */}
                            </div>
                            </div>
                        </div>
                        <div className="checkout-section mb-5">
                            <div className="card">
                            <h6 className="p-3 ps-1 pe-1 pb-0 float-start">Payment Mode</h6>
                            <div className="checkout-btn">
                              <div className="row mb-3 checkout-mode">
                                <div className="col-6">
                                  <label><input
                                    type={"radio"}
                                    value={"online"}
                                    // defaultChecked
                                    name="payment"
                                    checked={paymentModeRadio === "online"}
                                    onChange={(event) => setPaymentMode(event.target.value)}
                                  /> Pay Online</label>
                                </div>
                                <div className="col-6">
                                  <label><input
                                    type={"radio"}
                                    name="payment"
                                    value={"cod"}
                                    checked={paymentModeRadio === "cod"}
                                    onChange={(event) => setPaymentMode(event.target.value)}
                                  /> COD</label>
                                </div>
                              </div>
                              <button
                                className="btn btn-primary"
                                disabled={checkoutDetails.is_checkout === 0
                                            || checkoutLoader
                                            || cartError.length > 0  ? true : false}
                                onClick={() => checkout()}>
                                Confirm & Pay <b>₹{priceDetails.total_amount}</b> {paymentModeRadio === 'cod' ? 'on Delivery' : ''}
                              </button>
                              <p className="mt-2">By clicking above "Confirm & Pay", you agree to our <a href="/terms-conditions" className="link-danger" target={"_blank"}>Terms & Conditions</a>, <a href="/cancellation-returns" className="link-danger" target={"_blank"}>Cancellation & Returns Policy</a> and <a href="/payments" className="link-danger" target={"_blank"}>Payments Policy</a></p>
                            </div>
                            </div>
                        </div>
                    </div>
                  </>
                )
              }
            </>
          }
        </Row>
      </div>
    )    
}

export default CheckoutPayment;