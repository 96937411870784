import React, {useState, useEffect} from "react";
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {error_api_call, get_api_call, post_api_call} from '../../../actions/action-creators/common';
import {LoaderMedium} from '../../../components/loader';
import ListingRow from '../../../components/list/order/row';
// import Modal from 'react-bootstrap/Modal';
import {
    Button,
    Col,
    Form,
    InputGroup,
    Row,
    Modal
  } from 'react-bootstrap';
import Select from 'react-select';
import {SetMetaData} from '../../../helper/page-title';
import {alertResponseMessage} from '../../../actions/action-creators/response';
import '../../../assets/js/cart';
import {orderStatusColor} from '../../../config/color';
import { SpinnerRoundFilled } from 'spinners-react';

function MyLabOrderDetail(){
    SetMetaData("Order Details", "", "");
    const cancelInputValue = {
        cancel_reason_id: '',
        notes: '',
    };
    const params = useParams();
    const [productData, setProductData] = useState([]);
    const [addressData, setAddressData] = useState({});
    // const [trackingData, setTrackingData] = useState({});
    const [deliveryData, setDeliveryData] = useState({});
    const [userData, setUserData] = useState({});
    const [productLoading, setProductLoading] = useState(false);
    const [orderDetails, setOrderDetails] = useState({});
    const [pageLoading, setPageLoading] = useState(true);
    const [toggleModalState, setToggleModal] = useState({cancel: false});
    const [isCancelProcess, setIsCancelProcess] = useState(false);
    const [isErrorCancel, setIsErrorCancel] = useState('');
    const [inputCancelFields, setCancelFields] = useState(cancelInputValue);
    const [cancellationReasonData, setCancellationReasonData] = useState([]);
    //Get Ordered Products
    const fetchData = async() => {
        try{
            //APi Call
            if(!productLoading){
                setProductLoading(true);
                const orderData = await get_api_call(`order/${params.product_type}/${params.order_id}`);
                console.log(orderData, 'orderData.status')
                if(orderData.status === 200){
                    setOrderDetails(orderData.data);
                    setProductData(orderData.data.order_details);
                    setAddressData(orderData.data.address_details);
                    setUserData(orderData.data.user_details);
                    // setProductData(orderData.data.product_details);
                    // setAddressData(orderData.data.address_details);
                    // setOrderDetails(orderData.data.order_details);
                    // setTrackingData(orderData.data.delivery_track_details);
                    // setDeliveryData(orderData.data.delivery_details);
                }
                setPageLoading(false);
                setProductLoading(false);
            }
        }catch(e){
            alertResponseMessage({alert_type: 2, message: "Error Loading Order Details! Check back after some times"});
            // console.log(e);
            setProductLoading(false);
            error_api_call({error: `account/order-details|fetchData|${e}`});
        }
    }
	useEffect(() => {
		// if(callProAPI === 1){
			fetchData();
		// }
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
    //Toggle Modal
    const toggleModal = async(type, state, data=null) => {
        if(state && type === 'cancel'){
            setIsErrorCancel('');
            setCancelFields({...cancelInputValue});
            if(cancellationReasonData.length === 0){
                const reasonList = await get_api_call('reason/6');
                if(reasonList.status === 200){
                    setCancellationReasonData(reasonList.data);
                }
            }
        }

        setToggleModal({...toggleModalState, [type]: state});
    }
    //Cancel Input Handelling
    const _onChangeCancelInput = (attrName, value) => {
        try{
            setIsErrorCancel('');
            let fields = {...inputCancelFields};
            if(attrName === 'notes' 
                && value.length > 240
            ){
               return false;
            }

            fields[attrName] = value;
            setCancelFields({...fields});
        }catch(e){
            error_api_call({error: `account/order-detail|_onChangeCancelInput|${JSON.stringify(inputCancelFields)}|${e}`});
        }
    }
    //Cancellation Request
    const _cancelRequest = async() => {
        try{
          setIsCancelProcess(true);
          setIsErrorCancel('');
            var formData = {...inputCancelFields};
            if(formData.cancel_reason_id !== ""){
                formData.reason_id = formData.cancel_reason_id.id;
            }
            formData.notes = formData.notes.trim();
            formData.order_id = params.order_id;
            const cancelRequestPost = await post_api_call('order-cancel', formData);
            // console.log(cancelRequestPost, 'cancelRequestPost.status')
            if(cancelRequestPost.status === 200){
              toggleModal('cancel', false);
              alertResponseMessage({alert_type: 1, message: "Order Updated Successfully"});
              fetchData();
            }else{
                setIsErrorCancel(cancelRequestPost.message);
            }
          setIsCancelProcess(false);
        }catch(e){
        //   console.log(e)
          setIsErrorCancel('Error Processing Your Request! Please Try Again');
          setIsCancelProcess(false);
          error_api_call({error: `account/order-detail|_cancelRequest|${JSON.stringify(inputCancelFields)}|${e}`});
        }
      }

    return (
        <>
            <div className="container">
                <Row>
                    <div className="col-md-12 col-12 col-sm-12">
                        <div className="section-title mb-4 mt-5">
                        <h3>Order Details</h3>
                        </div>
                    </div>
                    {pageLoading 
                        ?
                            <div className="text-center mt-5">
                                <LoaderMedium size={65} />
                            </div>
                        :
                        <>
                            {productData.length > 0 && (
                                <>
                                    <div className="col-md-8 col-lg-8 col-12 col-sm-12 mb-5">
                                        <div className="card">
                                            <div>
                                                <div className="float-start">
                                                    <p className="p-3 pb-0 mb-0">Order Id: <b>#{params.product_type === 'lab' ? `OL${orderDetails.order_id}` : `O${orderDetails.order_id}`}</b></p>
                                                    <p className="p-3 pb-0 mb-0">Total {productData.length > 1 ? 'Items' : 'Item' }:<span className="fade-title"><b> {productData.length}</b></span></p>
                                                </div>
                                                <div className="float-start float-md-end">
                                                    <p className="p-3 pb-0 mb-0">Placed On: <span className="fade-title"><b>{moment(orderDetails.created_at).format("Do MMM YYYY")}</b></span></p>
                                                    <p className="p-3 pb-0 mb-0">Status: <span className="fade-title" style={{color: orderStatusColor[orderDetails.order_status_id]}}><b>{orderDetails.status_order}</b></span> 
                                                        {orderDetails.order_status_id === 5 && deliveryData && (<> on {moment(deliveryData.created_at).format("Do MMM YYYY")}</>)}</p>
                                                    {/* {orderDetails.order_status_id === 4
                                                    && trackingData
                                                    && typeof trackingData.url !== "undefined"
                                                    && trackingData.url
                                                    && (
                                                        <a href={trackingData.url} target="_blank" rel="noreferrer">
                                                            <p className="p-3 pb-0 mb-0"><i className="fa fa fa-external-link"></i> &nbsp;Track Order</p>
                                                        </a>
                                                    )} */}
                                                    {/* {orderDetails.order_status_id < 4 && (
                                                        <p className="p-3 pb-0 mb-0 text-center">
                                                            <button
                                                                className="btn btn-outline-danger p-2"
                                                                style={{fontSize: 15}}
                                                                // disabled={checkoutDetails.is_checkout === 0
                                                                //             || checkoutLoader
                                                                //             || cartError.length > 0  ? true : false}
                                                                onClick={() => toggleModal('cancel', true)}
                                                            >
                                                                Cancel this Order
                                                            </button>
                                                        </p>
                                                    )} */}
                                                </div>
                                                {/* <h6 className="p-3 pb-0 float-start">Total {productData.length > 1 ? 'Items' : 'Item' }: {productData.length}</h6> */}
                                            </div>
                                            {/* <div className="m-3 mt-3 alert alert-info address-card">
                                                <p><i className="fa fa-info-circle"></i> &nbsp;Order can not be cancelled <b>after Shipped/Dispatched</b></p>
                                                <p><i className="fa fa-info-circle"></i> &nbsp;For any order related queries, <a href={`https://wa.me/${orderDetails.helpline_number}/?text=Hi, I need help for Order Id: *O${orderDetails.order_id}*`} target="_blank" rel="noreferrer">Whatsapp here</a></p>
                                            </div> */}
                                            <div className="m-3 mt-3 scrollable-div">
                                            <>
                                                {productData.map((product, index) => (
                                                    <React.Fragment key={index}>
                                                        <ListingRow
                                                            data={product}
                                                            display_type={params.product_type === 'lab' ? "lab_order_detail" : "med_order_detail" }
                                                            url={params.product_type === 'lab' ? '/book-lab-test/detail/' : '/order-medicines/detail/' }
                                                            fetchUrlIdKey={params.product_type === 'lab' ? "lab_test_master_id" : 'product_id'}
                                                        />
                                                    </React.Fragment>
                                                ))}
                                            </>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-12 col-sm-12 mb-5">
                                        <div className="checkout-section pb-2">
                                            <div className="card pb-4">
                                                <h6 className="p-3 ps-1 pe-1 pb-0 float-start">Delivery Address</h6>
                                                <div className="ms-3 mt-2 me-3 me-3 address-card">
                                                    <p>{addressData.name}</p>
                                                    <p>{addressData.address_1}</p>
                                                    <p>{addressData.address_2}</p>
                                                    {addressData.landmark && (
                                                    <p>(Landmark: {addressData.landmark})</p>
                                                    )}
                                                     <p>{addressData.city}</p>
                                                     <p>{addressData.state} {addressData.pincode}</p>
                                                    <p><i className='fa fa-phone'></i>
                                                    &nbsp;{userData.mobile_number}
                                                    {/* {addressData.mobile_number} */}
                                                    {addressData.alternative_number !== "" && (
                                                        <>, {addressData.alternative_number}</>
                                                    )}
                                                </p>
                                                </div>                                            
                                            </div>
                                        </div>
                                        <div className="checkout-section">
                                            <div className="card">
                                            <h6 className="p-3 ps-1 pe-1 pb-0 float-start">Price Details</h6>
                                            <div className="ms-3 mt-2 me-3 me-3">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Item Price ({productData.length} {productData.length > 1 ? 'Items' : 'Item'})</td>
                                                            <td>₹{orderDetails.total_amount-orderDetails.shipping_amount-orderDetails.discount_amount}</td>
                                                        </tr>
                                                        {orderDetails.discount_amount > 0 && (
                                                            <tr>
                                                                <td>Discount</td>
                                                                <td> − ₹{orderDetails.discount_amount}</td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td>Delivery Charges</td>
                                                            <td style={orderDetails.shipping_amount === 0 ? {color: 'green'} : null}>{orderDetails.shipping_amount === 0 ? 'Free' : `₹${orderDetails.shipping_amount}`}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Total Amount</b></td>
                                                            <td><b>₹{orderDetails.total_amount}</b></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* <div className="checkout-btn">
                                                {checkoutLoader === true
                                                    ?
                                                        <button
                                                            className="btn btn-primary"
                                                            disabled={true}>
                                                            Checkout <LoaderMedium size={18} color={'#fff'}/>
                                                        </button>
                                                    :   <button
                                                            className="btn btn-primary"
                                                            disabled={checkoutDetails.is_checkout === 0
                                                                        || checkoutLoader
                                                                        || cartError.length > 0  ? true : false}
                                                            onClick={() => checkout()}>
                                                            Checkout
                                                        </button>
                                                }                                            
                                                {cartError.length > 0
                                                    ? <p className="error mb-0 pt-1">Please fix error(s) in the cart to Checkout</p>
                                                    : checkoutDetails.is_checkout !== 1 && <p className="error mb-0 pt-1">To Checkout, the Cart Price should be greater than <b>₹{checkoutDetails.minimum_checkout_amount}</b></p>
                                                }
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    }
            </Row>
            </div>
            {productData.length > 0 
                && orderDetails.order_status_id < 4
                && (
                    // {/* Cancellation  Request*/}
                    <Modal
                        onHide={() => toggleModal('cancel', false)}
                        backdrop="static"
                        keyboard={false}
                        show={toggleModalState.cancel}
                        size="xs"
                        aria-labelledby="contained-modal-title-vcenter"
                        // centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter" className="font-20">
                                Cancellation Request
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="text-center">
                            <div style={{padding: '20px 10px 0px'}}>
                                <h6 className="mb-4" style={{color: 'red'}}>Are you sure! you want to cancel this order ?</h6>
                                {/* Reason */}
                                {cancellationReasonData.length > 0 && (
                                    <div className="mb-4 mt-3">
                                        <InputGroup>
                                            <InputGroup.Text><i className="fa fa-question-circle" style={{fontSize: 12}}></i></InputGroup.Text>
                                            <div className='select-address'>
                                            <Select
                                                options={[...cancellationReasonData, {id: "", reason: "Other"}]}
                                                getOptionLabel={(option)=>option.reason}
                                                getOptionValue={(option)=>option.id}
                                                onChange={(e) => _onChangeCancelInput('cancel_reason_id', e)}
                                                isClearable={false}
                                                // styles={CustomStyle}
                                                placeholder="Select Cancellation Reason"
                                                value={inputCancelFields.cancel_reason_id}
                                                // menuColor='red'
                                            />
                                            </div>
                                        </InputGroup>
                                    </div>
                                )}
                                {/* Message */}
                                <div className="mb-3">
                                    <InputGroup>
                                        <InputGroup.Text><i className="fa fa-commenting"></i></InputGroup.Text>
                                        <Form.Control
                                            as="textarea" 
                                            type={"text"}
                                            placeholder="Any message for us?"
                                            autoComplete="notes-new" 
                                            value={inputCancelFields.notes}
                                            onChange={(event) => _onChangeCancelInput('notes', event.target.value)}
                                            maxLength={240}
                                            readOnly={isCancelProcess ? true : false}
                                            rows={4}
                                        />
                                    </InputGroup>
                                </div>
                                    
                                <Row className='mt-5'>
                                {isErrorCancel !== '' &&
                                    (<Col xs="12" className="text-center">
                                        <div className="alert alert-danger" role="alert">
                                            {isErrorCancel}
                                        </div>
                                    </Col>)
                                }
                                {/* <Col xs="12" className="text-center">
                                    <Button
                                        title="Submit"
                                        disabled={isCancelProcess}
                                        color={'primary'}
                                        onClick={() => _cancelRequest()}
                                    >
                                        {isCancelProcess
                                            ? 
                                                <>
                                                    <SpinnerRoundFilled color="#fff" size={20} />
                                                    Requesting...
                                                </>
                                            : 'Request Cancellation'
                                        }
                                    </Button>
                                </Col> */}
                                </Row>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {/* <a target="_blank"><p className="visit-link text-center m-0">Visit</p></a> */}
                            <p>
                                <button
                                    title="Submit"
                                    disabled={isCancelProcess}
                                    className="btn btn-outline-primary"
                                    onClick={() => toggleModal('cancel', false)}
                                >
                                    No, Close
                                </button>
                            </p>
                            <Button
                                title="Submit"
                                disabled={isCancelProcess}
                                color={'primary'}
                                onClick={() => _cancelRequest()}
                            >
                                {isCancelProcess
                                    ? 
                                        <>
                                            <SpinnerRoundFilled color="#fff" size={20} />
                                            Requesting...
                                        </>
                                    : 'Yes, Cancel Order'
                                }
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )
            }
        </>
    )    
}

export default MyLabOrderDetail;