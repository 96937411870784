import React from "react";
import {
    Card,
    Col,
    Row
  } from 'react-bootstrap';
import {SetMetaData} from './../../helper/page-title';

function PrivacyPolicy(){
    SetMetaData("Privacy Policy", "", "");

    return (
        <div className="container">
        <Row className="justify-content-center">
            <Col md="9" sm="12">
                <Card className="m-2 mt-4 mb-5">
                    <div className="p-3 static-pages">
                        <h5 className="text-center pt-3">Privacy Policy</h5>
                        <br/>
                        <p>This Privacy Policy describes how {process.env.REACT_APP_NAME} (collectively “{process.env.REACT_APP_SHORT_NAME}, we, our, us”) collect, use, share or otherwise process your personal information through the {process.env.REACT_APP_SHORT_NAME} website {process.env.REACT_APP_DOMAIN}, its mobile application, and m-site (hereinafter referred to as the “Platform”).</p>
                        <p>We respect the privacy of our users/visitors and intend to protect the privacy of our users/visitors.</p>
                        <p>While you may be able to browse certain sections of the Platform without registering with us, however, please note we do not offer any product or service under this Platform outside India. Your personal information will primarily be stored and processed in India and may have data protection laws that are different from those that apply in the country in which you are located. By visiting this Platform, providing your information or availing out product/service, you expressly agree to be bound by the terms and conditions of this Privacy Policy, the <a href="/terms-conditions" className="link-danger">Terms of Use</a> and the applicable service/product terms and conditions, and agree to be governed by the laws of India including but not limited to the laws applicable to data protection and privacy. If you do not agree please do not use or access our Platform.</p><br/>

                        <p><b>Personal Information:</b> Use of our Platform is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. We do not knowingly solicit or collect personal information from children under the age of 18 years. If you have shared any personal information of children under the age of 18 years, you represent that you have the authority to do so and permit us to use the information in accordance with this Privacy Policy.</p>
                        <p>At the time of registration, we ask you for some personally identifiable information such as name, email address, delivery address and mobile number. Where possible, we indicate which fields are required and which fields are optional. You always have the option to not provide information by choosing not to use a particular service, product or feature on the Platform. We also collect email addresses of users who are willing to receive alerts about sales/deals/offers/coupons.</p>
                        <p>We collect information about your transaction done at the Paytm gateway for Reference and Refund purposes.</p>
                        <p>We retain the information as necessary to resolve disputes, provide customer support, troubleshoot problems or for internal research and analysis as permitted by law.</p>
                        <p>With your consent, we may have access to your SMS, instant messages, contacts in your directory, location, camera, photo gallery and device information. We may also request you to provide your PAN, GST Number, Government issued ID cards/number and Know-Your-Customer (KYC) details to: (i) check your eligibility for certain products and services including but not limited to credit and payment products; (ii) issue GST invoice for the products and services purchased for your business requirements; (iii) enhance your experience on the Platform and provide you access to the products and services being offered by us, sellers, affiliates or lending partners. You understand that your access to these products/services may be affected in the event consent is not provided to us.</p>
                        <p>We do NOT trade or sell your Personal Information in any manner. We do not distribute your email address and mobile number to anyone. Your information is always safe with Us as it is stored in an encrypted format on a secure server.</p><br/>

                        <p><b>Non-Personal Information:</b> We may collect your IP address to track authentication and to discourage misuse of our services. We may also track your buying behaviour, preferences, and other information that you choose to provide on our Platform. We use this information to do internal research on our users' demographics, interests, and behaviour to better understand, protect and serve our users. This information is compiled and analysed on an aggregated basis.</p><br/>

                        <p><b>Cookies & Session Data:</b> Cookies are small pieces of information that are stored by your browser on your computer's hard drive. Cookies allow Web servers to recognize your computer each time you return to our website, we use cookies to authenticate registered and logged in users.</p>
                        <p>We use cookies from third-party partners such as Google Analytics for marketing and analytical purposes. Google Analytics help us understand how our customers use the site. You can read more about how Google uses your personal information here: <a href="https://www.google.com/intl/en/policies/privacy/" className="link-danger" target={"_blank"} rel="noreferrer" >https://www.google.com/intl/en/policies/privacy/</a>. You can opt out of Google Analytics here: <a href="https://tools.google.com/dlpage/gaoptout" className="link-danger" target={"_blank"} rel="noreferrer" >https://tools.google.com/dlpage/gaoptout</a>.</p>
                        <p>It is your right to allow or block cookies in your browser. However, blocking {process.env.REACT_APP_DOMAIN} cookies may disable certain features on our Website and may make it impossible for you to use certain services available on our Website.</p><br/>

                        <p><b>Sharing of personal information:</b> We may disclose your personal information such as delivery address and mobile number to third parties, such as logistics partners, payment partners.</p>
                        <p>We may disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal processes. We may disclose personal information to law enforcement agencies, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms of Use or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.</p><br/>

                        <p><b>Security Precautions:</b> We maintain reasonable physical, electronic and procedural safeguards to protect your information. Whenever you access your account information, we offer the use of a secure server. Once your information is in our possession, we adhere to our security guidelines to protect it against unauthorized access. However, by using the Platform, the users accept the inherent security implications of data transmission over the internet and the World Wide Web which cannot always be guaranteed as completely secure, and therefore, there would always remain certain inherent risks regarding the use of the Platform. Users are responsible for ensuring the protection of login and password records for their accounts.</p><br/>

                        <p><b>Advertisements on Platform:</b> We use third-party advertising companies to serve ads when you visit our Platform. These companies may use information (not including your name, address, email address, or telephone number) about your visits to this and other websites in order to provide advertisements about goods and services of interest to you.</p><br/>

                        <p><b>Your Consent:</b> By visiting our Platform or by providing your information, you consent to the collection, use, storage, disclosure and otherwise processing of your information (including sensitive personal information) on the Platform in accordance with this Privacy Policy. If you disclose to us any personal information relating to other people, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.</p><br/>
                        
                        <p>The privacy policy may be updated or reviewed on a timely basis to protect your privacy when we introduce new services. Do check our privacy policy regularly.</p>
                        <p>In case you have any queries, write to us at <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}><b>{process.env.REACT_APP_SUPPORT_EMAIL}</b></a>.</p>
                    </div>
                </Card>
            </Col>
        </Row>
        </div>
    )    
}

export default PrivacyPolicy;