import React from "react";
import {stringToUrl} from '../../../helper/custom-function';
// import {LoaderMedium} from '../../loader';
import { calculatePercent } from "../../../helper/custom-function";
import noImage from '../../../assets/images/no-image.png';
import labIcon from '../../../assets/images/icon/test-results.png';

function ListingRow(props){
    const dropCount = [1, 2, 3, 4, 5];
    return (
        <>
            <div className="card mb-15 p-2 cart-listing">
                <div className="row">
                    <div className="col-md-1 col-3">
                        <a href={`${props.url}${props.data[props.fetchUrlIdKey]}/${stringToUrl(props.data.name)}`} target="_blank" rel="noreferrer">
                            <div className="deal-image">
                                {(props.display_type === 'lab_order_detail')
                                    ?
                                        <>
                                            <img className="img-thumbnail" src={labIcon} alt="Lab Test"/>
                                        {/* {props.data.thumbnail !== null && props.data.thumbnail !== ''
                                            ? <img className="img-thumbnail" src={props.data.thumbnail} alt={props.data.title} />
                                            : <img className="img-thumbnail" src={noImage} alt={props.data.title} />
                                        } */}
                                        </>
                                    :
                                        <img className="img-thumbnail" src={noImage} alt="order"/>
                                }
                            </div>
                        </a>
                    </div>
                    <div className="col-md-11 col-9">
                        <div className="row">
                            <div className="col-md-11 col-10 p-0">
                                <a href={`${props.url}${props.data[props.fetchUrlIdKey]}/${stringToUrl(props.data.name)}`} target="_blank" rel="noreferrer">
                                    <h6 className="mb-0">{props.data.name}</h6>
                                </a>
                                <h6 className="fade-title text-lef text-start">
                                    {props.display_type === 'cart_test' && (<>{props.data.lab_name}</>)}
                                    {props.display_type === 'med_order_detail' && (<>{props.data.packet_name} of {props.data.unit_name} {props.data.unit_type}</>)}
                                </h6>
                            </div>
                            {/* <div className="col-md-1 col-2 display-table deal-share">
                                {props.display_type === 'cart' && (
                                    <a href={`/book-lab-test/detail/${props.data.id}/${stringToUrl(props.data.name)}`} target="_blank" rel="noreferrer">
                                        <span><i className="fa fa fa-external-link"></i></span>
                                    </a>
                                )}
                            </div> */}
                            
                            <div className="col-md-11 col-10">
                                <div className="row">
                                    <div className="col-md-8 col-12 p-0">
                                        <p className="fade-title text-start pt-3" style={{fontWeight: 600}}>{props.data.measure}</p>
                                        <h4 className="product-price pt-2">₹{props.data.price}{" "}
                                        {props.data.mrp > 0 && props.data.mrp > props.data.price &&  <del className="product-old-price">₹{props.data.mrp}</del>}
                                        {calculatePercent(props.data.price, props.data.mrp, true)}
                                        </h4>
                                    </div>

                                    <div className="col-md-4 col-12 p-0">
                                        Quantity: <b>{props.data.quantity}</b>
                                        {/* {props.display_type !== 'cart' && (
                                            Quantity: <b>{props.data.quantity}</b></>
                                        )} */}
                                    </div>
                                </div>
                            </div>
                           
                            <div className="col-md-1 col-2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ListingRow;