var ValidationHelper = {
    validation: (data, info) => {
      var error = [];
        //Loop through All sent Data
        for(var i in info){
          if(info.hasOwnProperty(i)){
              var field = i;
              var feature = info[i];
              //Split the value by |
              var featureArray = feature.split('|');
              //Loop through all feature required
              for(var j in featureArray){
                //Get the value of minimum digits required info if any
                var valueInfo = featureArray[j].split(':');
                var infoFirst = valueInfo[0];
                var infoSecond = '';
                if(valueInfo.length > 1){
                  infoFirst = valueInfo[0];
                  infoSecond = valueInfo[1];
                }
  
                var validationCheck = ValidationHelper.vlidationCheck(data, field, infoFirst, infoSecond);
                if(validationCheck.msg){
                  error.push(validationCheck.msg);
                  break;
                }
  
                // console.log(validationCheck);
              }
          }
        }
      var response = {};
      response.error = error;
      return response;
    },
    vlidationCheck: (data, field, feature, f_val) => {
      var msg = '';
      if(f_val){
        f_val = parseInt(f_val);
      }
      // console.log(data);
      var fieldValue = data[field];
      var fieldName = ValidationHelper.titleCase(field.replace('_', ' '));
      switch (feature) {
        case 'required':
          // if(fieldValue.toString().replace(/ /g, '') == ''){
          if(typeof fieldValue === "undefined" || fieldValue.trim() === ''){
            msg = fieldName+' is Required';
          }
          break;
        case 'multiRequired':
          if(fieldValue.length === 0 || fieldValue === ""){
            msg = fieldName+' is Required';
          }
          break;
        case 'min':
          // if(fieldValue != ''  && fieldValue.toString().replace(/ /g, '').length < f_val){
          if(fieldValue !== ''  && fieldValue.toString().length < f_val){
            msg = fieldName+' should be Minimun '+f_val+' Characters';
          }
          break;
        case 'max':
          // if(fieldValue != '' && fieldValue.toString().replace(/ /g, '').length > f_val){
          if(fieldValue !== '' && fieldValue.toString().length > f_val){
            msg = fieldName+' should be less than '+f_val+' Characters';
          }
          break;
        case 'digit':
          if(fieldValue !== ''  && !ValidationHelper.validateNumber(fieldValue)){
            msg = fieldName+' should only contain digits';
          }else if(f_val !== "" && (fieldValue !== '' && fieldValue.length !== f_val)){
            // console.log(fieldValue, fieldValue.length, 'kkkkkkkkkkkkkkkkkkkkk')
            msg = fieldName+' should be '+f_val+' digits';
          }
          break;
        case 'email':
          if(fieldValue !== '' && !ValidationHelper.validateEmail(fieldValue)){
            msg = fieldName+' should be Valid';
          }
          break;
        case 'password':
            if(fieldValue !== '' && !ValidationHelper.validateSpace(fieldValue)){
              msg = fieldName+' should not contain Space';
            }
          break;
        case 'commaSeparatedMax':
          if(fieldValue.trim() !== ''){
            const commaString = fieldValue.split(',');
            var arrayLength = 0;
            for(var k in commaString){
              if(commaString[k].trim().length > f_val){
                msg = `${fieldName} should be less than ${f_val+1} Characters`;
                break;
              }else if(commaString[k].trim().length){
                arrayLength++;
              }
            }
            if(!arrayLength){
              msg = `${fieldName} should be less than ${f_val+1} Characters`;
            }
          }
          break;
        case 'name':
            // if(fieldValue != '' && !ValidationHelper.validateName(fieldValue)){
            // if(fieldValue != ''){
            //   console.log('dsdsdsds', fieldValue);
            //   msg = fieldName+' should be Valid';
            // }
            break;
        default:
          break;
      }
      var response = {};
      response.msg = msg;
      return response;
    },
    validateEmail: (email) => {
      /* eslint-disable no-useless-escape */
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validateNumber: (number) => { 
      var re = /^[0-9]+$/;
      return re.test(number);
    },
    // validateName: (name) => { 
    //   var re = /^[A-Za-z][A-Za-z .]*$/;
    //   // var re = /^[A-Za-z]+$/;
    //   // var re = /^(([.](?=[^.]|$))|([ ](?=[^ ]|$)))*$/; //consecutive space and dot
    //   return re.test(name);
    // },
    validateSpace: (string) => {
      // var re = /\s/g;
      // return re.test(string);
      return string.indexOf(' ') === -1;
    },
    titleCase: str => {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
          // Assign it back to the array
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
      // Directly return the joined string
      return splitStr.join(' '); 
    },
    imageUrl: (url) => { 
      // var re = '([^\\s]+(\\.(?i)(jpe?g|png|gif))$)';
      const re = /(https?:\/\/.*\.(?:png|jpg|jpeg))/i;
      return re.test(url);
    },
    url: (url) => { 
      // var re = '([^\\s]+(\\.(?i)(jpe?g|png|gif))$)';
      const re = /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi;
      return re.test(url);
    },

  };
  
  // module.exports = ValidationHelper;
  export default ValidationHelper;