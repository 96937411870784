import React, {useState, useEffect, useRef} from "react";
import { useDispatch, useSelector } from 'react-redux';
import {post_api_call, error_api_call, get_api_call} from '../../actions/action-creators/common';
import {LoaderMedium} from './../../components/loader';
import ListingRow from '../../components/list/row-cart';
import ValidationHelper from './../../helper/validation';
// import Modal from 'react-bootstrap/Modal';
import {
    Row,
} from 'react-bootstrap';
import {update_cart_count, update_pincode} from '../../actions/action-creators/state-update';
import {SetMetaData} from './../../helper/page-title';
import {alertResponseMessage} from '../../actions/action-creators/response';
// import './../../assets/js/cart';
import shopNowImg from '../../assets/images/shop-now.png';

function MyTestCart(props){
    SetMetaData("My Cart", "", "");
    const dispatch = useDispatch();
    const deviceID = localStorage.getItem("device_id");
    const pincodeReduxData = useSelector(state => state.stateUpdate);
    // const authReduxData = useSelector(state => state.auth);
    const [productData, setProductData] = useState([]);
    const [cartData, setCartData] = useState([]);
    const [itemLoader, setItemLoader] = useState({});
	const [productPage, setProductPage] = useState(0);
    const [productTotal, setProductTotal] = useState(0);
    const [productLoading, setProductLoading] = useState(false);
    const [priceDetails, setPriceDetails] = useState({});
    const [checkoutDetails, setCheckoutDetails] = useState({});
    const [showProductLoader, setProductLoader] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    const [checkoutLoader, setCheckoutLoader] = useState(false);
    const [cartError, setCartError] = useState([]);
    const [delvPincode, setPincode] = useState('');
    const [pincodeProcessing, setPincodeProcessing] = useState(false);
    const [pincodeDetails, setPincodeDetails] = useState({is_initial: 1,
                                                        is_servicable: -1,
                                                        message: 'To Know if your Pincode is serviceable, Enter your Pincode & click on Check button'});
    //Coupon
    const [couponCode, setCouponCode] = useState("");
    const [couponDetails, setCouponDetails] = useState({success_status: 0});
    const [couponProcessing, setCouponProcessing] = useState(false);
    const [cartLabCount, setCartLabCount] = useState(0);
    const limit = 10;
    const productLoader = useRef(null);
    const loadMoreProduct= useRef(null);//Button Click
    useEffect(() => {
        if(pincodeReduxData.pincode_data !== null){
            const pincodeData = JSON.parse(pincodeReduxData.pincode_data);
            if(pincodeData.pincode !== ''){
                setPincode(pincodeData.pincode);
                if(pincodeDetails.is_initial === 1){
                    console.log('yes yrsr yts 4444')
                    checkDeliveryPincode(pincodeData.pincode);
                }
            }
        }
    }, [pincodeReduxData]);// eslint-disable-line react-hooks/exhaustive-deps
    //Call Product Filter API
	useEffect(() => {
		//Get Filtered Products
		const fetchData = async() => {
			try{
				//APi Call
				if(!productLoading){
					setProductLoading(true);
					const proCurrentPage = productPage < 2 ? 1 : productPage;
					if(proCurrentPage === 1){
						setProductData([]);
					}
					const newProductList = await post_api_call('cart/lab', 
																{limit,
																page: proCurrentPage,
																device_id: deviceID
															});

					if(newProductList.status === 200){
						const newProductData = proCurrentPage < 2
												? newProductList.data.data
												: productData.concat(newProductList.data.data);
						var cartDetails = {};
						newProductList.data.data.forEach(pro_data => {
							cartDetails[pro_data.id] = pro_data.quantity ? pro_data.quantity : 0;
						});
						setCartData({...cartData, ...cartDetails});
						setProductData(newProductData);
						if(proCurrentPage === 1){
                            setCartError(newProductList.data.error_msg);
							// setProductTotal(newProductList.data.cart_count);
                            setProductTotal(newProductList.data.cart_lab_count);
                            setPriceDetails(newProductList.data.price_details);
                            setCheckoutDetails(newProductList.data.checkout_allowed);
							setPageLoading(false);
                            dispatch(update_cart_count({cart_count: newProductList.data.cart_count}, cb_cart => {}));
                            props._updateCartCount(newProductList.data.cart_lab_count);
                            if(newProductList.data.cart_lab_count !== ''){
                                setCartLabCount(newProductList.data.cart_lab_count);
                            }
						}
                        setProductLoading(false);
                        toggleLoader(proCurrentPage, newProductList.data.cart_count);
					}else{
                        setProductLoader(false);
                        setProductLoading(false);
                    }
				}
			}catch(e){
				alertResponseMessage({alert_type: 2, message: "Error Loading your Cart! Check back after some times"});
				console.log(e);
                setProductLoader(false);
				setProductLoading(false);
				error_api_call({error: `cart/cart-test|fetchData|${e}`});
			}
		}
		// if(callProAPI === 1){
			fetchData();
		// }
	}, [productPage]);// eslint-disable-line react-hooks/exhaustive-deps
	//Load More Products
	const loadMore = () => {
		setProductPage(productPage < 2 ? 2 : productPage+1);
	}
    //Show-Hide Item Loader
	const toggleLoader = (current_page, total_page) => {
        try{
            var response = {page_count: 0, is_show: false};
			const totalPage = total_page <= limit
							? 1
							: total_page % limit === 0 
							? total_page/limit 
							: parseInt((total_page/limit))+1;

			if(current_page < totalPage){
				response.is_show = true;
				setProductLoader(true);
			}else{
				setProductLoader(false);
			}
			response.page_count = totalPage;
            return response;
        }catch(e){
            return response;
        }
    }
    //Load More Data when loader is in focus
	const infiniteCustomScroll = () => {
		if(loadMoreProduct.current){
			loadMoreProduct.current.click();
		}
	}
    //Initial Load & Read More Implementation
	useEffect(() => {
        const options = {
           root: null,
           rootMargin: "20px",
           threshold: 1.0
        };
       // initialize IntersectionObserver
       // and attaching to Load More div
        //For All
		const observerAll = new IntersectionObserver(infiniteCustomScroll, options);
        if (productLoader.current) {
            observerAll.observe(productLoader.current)
        }
		const clean = productLoader.current;
        return () => {
            if (clean) {
                observerAll.disconnect(clean);
            }
        }
    }, [productLoader.current]);// eslint-disable-line react-hooks/exhaustive-deps
    //Update Item Cart Quantity
    const addToCart = async(e, id, qty) => {
		e.preventDefault();
		try{
			setItemLoader({...itemLoader, [id]: true});
			//Update Cart Item API Call
			const cartApiData = await post_api_call('update-cart-test', 
													{device_id: deviceID,
														// variation_detail_id,
														id,
														quantity: qty
													});
			if(cartApiData.status === 200){
				//Notify Msg
				const message = cartApiData.message;
                getPriceData();
				if(cartApiData.success_status === 1
					|| cartApiData.success_status === 2
				){
					//Success
					alertResponseMessage({alert_type: 1, message});
				}else{
					//Error
					alertResponseMessage({alert_type: 2, message});
				}

                if(cartError.length > 0){
                    setProductPage(productPage === 1 ? 0 : 1);
                }else{
                    setCartData({...cartData, [id]: qty});
                    if(cartApiData.data.cart_count !== ''){
                        // setProductTotal(cartApiData.data.cart_count);
                        setProductTotal(cartApiData.data.cart_lab_count);
                        dispatch(update_cart_count({cart_count: cartApiData.data.cart_count}, cb_cart => {}));
                        props._updateCartCount(cartApiData.data.cart_lab_count);
                        if(cartApiData.data.cart_lab_count !== ''){
                            setCartLabCount(cartApiData.data.cart_lab_count);
                        }
                        // console.log(cartApiData.data.cart_lab_count, 'cartApiData.data.cart_lab_count')
                    }
                }
			}
			setItemLoader({...itemLoader, [id]: false});
		}catch(e){
			// console.log(e)
			// e.target.disabled = false;
            alertResponseMessage({alert_type: 2, message: "Error Updating Cart"});
			error_api_call({error: `cart/my-cart|addToCart|${e}`});
        }
	}
    //Get Cart Price & Checkout Details
    const getPriceData = async(access_type='') => {
		try{
            var code = '';
            if(access_type === 'coupon'
                || (access_type !== 'remove_coupon' && couponDetails.success_status === 1)

            ){
                setCouponProcessing(true);
                code = couponCode;
            }else{
                setCouponCode('');
                setCouponDetails({success_status: 0});
            }
			// e.preventDefault();
			// e.target.disabled = true;
			// setItemLoader({...itemLoader, [product_detail_id]: true});
            setCheckoutLoader(true);
			const cartPriceApiData = await post_api_call('cart-price-details/lab', 
													{device_id: deviceID,
                                                    code});
                                                    console.log(cartPriceApiData, 'cartPriceApiData')
			if(cartPriceApiData.status === 200){
				//Notify Msg
				// const message = cartPriceApiData.message;
                // if(access_type !== 'coupon'){
                //     alertResponseMessage({alert_type: 1, message});
                // }
                setPriceDetails(cartPriceApiData.data.price_details);
                setCheckoutDetails(cartPriceApiData.data.checkout_allowed);
                if(code !== ''){
                    setCouponDetails(cartPriceApiData.data.coupon_details);
                }
				// setCartData({...cartData, [product_detail_id]: qty});
				// setCartData({...cartData, [product_detail_id]: cartPriceApiData.data.quantity});
				// if(cartPriceApiData.data.cart_count !== ''){
                //     setProductTotal(cartPriceApiData.data.cart_count);
				// 	dispatch(update_cart_count({cart_count: cartPriceApiData.data.cart_count}, cb_cart => {}));
				// }
                setCheckoutLoader(false);
			}
            if(access_type === 'coupon'
            || couponDetails.success_status === 1){
                setCouponProcessing(false);
            }
			// setItemLoader({...itemLoader, [product_detail_id]: false});
			// e.target.disabled = false;
		}catch(e){
			console.log(e)
			// e.target.disabled = false;
            if(couponDetails.success_status === 1){
                setCouponProcessing(false);
            }
            if(access_type === 'coupon'){
                setCouponProcessing(false);
                setCouponDetails({...couponDetails, message: 'Sorry! Error Applying Coupon Code'});
            }else{
                alertResponseMessage({alert_type: 2, message: "Sorry! Error Updating Cart Price Details"});
            }
			error_api_call({error: `cart/cart-test|getPriceData|${e}`});
        }
	}
    //Code On Change
    const codeHandelling = (e) => {
        e.preventDefault();
        setCouponCode(e.target.value.trim());
        if(couponDetails.success_status !== 0){
            setCouponDetails({success_status: 0});
        }
    }
    //Customize input:Pincode
    const validatePincode = (value) => {
        if(ValidationHelper.validateNumber(value.trim())
            && value.length < 7
        ){
            setPincode(value);
        }
        if(pincodeDetails.message !== ''){
            setPincodeDetails({is_initial: 0,
                                is_servicable: -1,
                                message: ''});
        }
    }
    //Check Pincode
    const checkDeliveryPincode = async(pincode='') => {
        try{
            pincode = pincode === '' ? delvPincode : pincode;
            // e.preventDefault();
            if(!pincodeProcessing
                && ValidationHelper.validateNumber(pincode.trim())
                && pincode.length === 6
            ){
                setPincodeProcessing(true);
                setPincodeDetails({is_initial: 0,
                                    is_servicable: -1,
                                    message: ''});
                if(pincode < 100000){
                    setPincodeDetails({is_initial: 0,
                                    is_servicable: 0,
                                    message: 'Sorry! Your Pincode is not Serviceable'});
                }else{
                    const pincodeApiData = await get_api_call(`check-serviceable-pincode/${pincode}`);
                    console.log(pincodeApiData, 'pincodeApiData')
                    if(pincodeApiData.status === 200){
                        setPincodeDetails({is_initial: 0,
                                            is_servicable: pincodeApiData.is_servicable,
                                            message: pincodeApiData.is_servicable === 1
                                                        ? pincodeApiData.estimated_date !== '' 
                                                            ? `Hurray! Your Pincode is Serviceable (Estimated Date: ${pincodeApiData.estimated_date})`
                                                            : 'Hurray! Your Pincode is Serviceable'
                                                        : 'Sorry! Your Pincode is not Serviceable'});
                        dispatch(update_pincode({data: {is_servicable: pincodeApiData.is_servicable, pincode}}, cb_pincode => {}));
                    }
                }
                setPincodeProcessing(false);
            }
        }catch(error){
            setPincodeProcessing(false);
            alertResponseMessage({alert_type: 2, message: "Sorry! Error Checking Pincode"});
			error_api_call({error: `cart/cart-test|checkDeliveryPincode|${error}`});
        }
    }
    //checkout
    const checkout = () => {
        try{
            //Check Pincode is Serviceable
            if(pincodeDetails.is_initial === 0
                && pincodeDetails.is_servicable === 0
            ){
                alertResponseMessage({alert_type: 2, message: `Sorry! Your Pincode(${delvPincode}) is not Serviceable`});
            }else{
                const isAuth = localStorage.getItem("token") ? true : false;
                // const authData = JSON.parse(authReduxData);
                const redirectedCouponCode = couponDetails.success_status === 1 && couponCode !== '' ? couponCode : '';
                var redirectionUri = '';
                //Redirect Login Page
                if(isAuth === false){
                    redirectionUri = `/login?redirect_uri=checkout/address?page=medicine`;
                    if(redirectedCouponCode){
                        redirectionUri = `${redirectionUri}&coupon_code=${redirectedCouponCode}`;
                    }
                    window.location.href = redirectionUri;
                }else{
                    //Open Address Page
                    redirectionUri = `/checkout/address?page=lab`;
                    if(redirectedCouponCode){
                        redirectionUri = `${redirectionUri}&coupon_code=${redirectedCouponCode}`;
                    }
                    window.location.href = redirectionUri;
                }
            }
        }catch(error){
            alertResponseMessage({alert_type: 2, message: "Something Went wrong, Please try after some times"});
			error_api_call({error: `cart/my-cart|checkout|${error}`});
        }
    }

    return (
        // <div className="container">
            <Row>
                {/* <div className="col-md-12 col-12 col-sm-12">
                    <div className="section-title mt-5">
                    <h3>My Cart {productTotal > 0 
                                    ? <>({productTotal})</>
                                    : '' }</h3>
                    </div>
                </div> */}
                {pageLoading 
					?
						<div className="text-center mt-5">
							<LoaderMedium size={65} />
						</div>
					:
                        <>
                        {cartError.length > 0 && (
                            <div className="col-md-12 col-12 col-sm-12">
                                <div className="alert alert-danger">
                                    {/* <p>Please Fix {cartError.length === 1 ? 'this error' : 'these errors'} (either remove or decrease quantity of product(s) from your cart):</p> */}
                                    <p>Please Fix {cartError.length === 1 ? 'this error' : 'these errors'}:</p>
                                    <ul className="ms-2" style={{listStyle: 'disc'}}>
                                    {cartError.map((error, error_index) => (
                                        <li key={error_index}>{error}</li>
                                    ))}
                                    </ul>
                                </div>
                            </div>
                        )}
                        {productData.length > 0 ? (
                            <>
                                <div className="col-md-8 col-lg-8 col-12 col-sm-12">
                                    <div className="card">
                                        <div>
                                            {productTotal >= productData.length && (
                                                <h6 className="p-3 pb-0 float-start">Total Products: {productData.length}/{productTotal}</h6>
                                            )}
                                            <div className="float-end text-end">
                                                <div className="search-box-wrap">
                                                    <input
                                                        type="text"
                                                        className="form-control me-2"
                                                        placeholder="Enter Pincode"
                                                        onChange={(e) => validatePincode(e.target.value)}
                                                        readOnly={pincodeProcessing ? true : false}
                                                        value={delvPincode}/>
                                                    <button
                                                        className="btn btn-outline-danger"
                                                        onClick={() => checkDeliveryPincode()}
                                                        disabled={pincodeProcessing
                                                                    || delvPincode.length < 6 ? true : false}
                                                    >
                                                        {pincodeProcessing
                                                        ? <LoaderMedium size={20} />
                                                        : 'Check'}
                                                    </button>
                                                </div>
                                                {/* <p className="mb-1 pe-3 ps-1">To Know Expected delivery date, Enter your Pincode & click on Update button</p> */}
                                                <p className="mb-1 pe-3 ps-1"
                                                    style={{color: pincodeDetails.is_servicable === 0 
                                                            ? 'red'
                                                            : pincodeDetails.is_servicable === 1
                                                                ? 'green'
                                                                : ''}}
                                                >{pincodeDetails.message !== ''
                                                    ? pincodeDetails.message
                                                    : 'To Know if your Pincode is serviceable, Enter your Pincode & click on Check button'
                                                }</p>
                                            </div>
                                        </div>
                                        <div className="m-3 mt-3 scrollable-div">
                                        <>
                                            {productData.map((product, index) => (
                                                <React.Fragment key={index}>
                                                    <ListingRow
                                                        data={product}
                                                        cartData={cartData}
                                                        addToCart={addToCart}
                                                        // title={product.title}
                                                        // description={product.description}
                                                        // icon={product.icon_file_name}
                                                        // image={null}
                                                        // id={product.id}
                                                        // is_product={true}
                                                        // expiry_date={product.expiry_date}
                                                        // code={product.product_code}
                                                        // show_desc={toggleCDesc[product.id]}
                                                        // toggle_desc={(id) => toggleDesc('product', id)}
                                                        // toggle_modal={(data) => toggleModal('product', true, data)}
                                                        // url={product.url}
                                                        display_type={"cart_test"}
                                                        itemLoader={itemLoader}
                                                        url="/book-lab-test/detail"
                                                        urlFetchKey="id"
                                                        addCartKey="id"
                                                    />
                                                </React.Fragment>
                                            ))}
                                            {showProductLoader && 
                                                <div className="text-center">
                                                    {/* <button className="btn btn-primary" ref={loadMoreProduct} onClick={() => loadMore()} style={{visibility: "hidden"}}>Load More</button> */}
                                                    <div ref={productLoader}>
                                                        <LoaderMedium />
                                                    </div>
                                                    <button className="btn btn-primary" ref={loadMoreProduct} onClick={() => {loadMore()}}>Load More</button>
                                                </div>
                                            }

                                            {/* {(showOfferLoader || showCouponLoader) && (
                                                <div className="text-center">
                                                    <button className="btn btn-primary" ref={loadMoreAll} onClick={() => loadMore(0)} style={{visibility: "hidden"}}>Load More</button>
                                                    <div ref={allLoader}>
                                                        <LoaderMedium />
                                                    </div>
                                                </div>
                                            )} */}
                                        </>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12 col-sm-12 mb-5">
                                    <div className="checkout-section pb-2">
                                        <div className="card pb-4">
                                            <h6 className="p-3 ps-1 pe-1 pb-0 float-start">Coupon</h6>
                                            <div style={{display: "inline-flex"}} className="ms-3">
                                                <input
                                                    type="text"
                                                    className="form-control me-2"
                                                    placeholder="Enter Coupon Code"
                                                    value={couponCode}
                                                    onChange={(e) => codeHandelling(e)}
                                                    readOnly={couponProcessing 
                                                                || couponDetails.success_status === 1 
                                                                || cartError.length > 0
                                                            ? true : false}/>
                                                {couponDetails.success_status === 1
                                                ? <button
                                                    className="btn btn-outline-danger"
                                                    style={{width: 'auto'}}
                                                    disabled={couponProcessing ? true : false}
                                                    onClick={() => getPriceData('remove_coupon')}
                                                >
                                                    {couponProcessing
                                                    ? <LoaderMedium size={20} />
                                                    : 'Remove'}
                                                </button>
                                                : <button
                                                    className="btn btn-outline-danger"
                                                    style={{width: 'auto'}}
                                                    disabled={couponCode.trim() === ''
                                                                || couponProcessing
                                                                || cartError.length > 0 
                                                            ? true : false}
                                                    onClick={() => getPriceData('coupon')}
                                                >
                                                    {couponProcessing
                                                    ? <LoaderMedium size={20} />
                                                    : 'Apply'}
                                                </button>
                                                }
                                            </div>
                                            
                                            {cartError.length > 0 
                                            ?
                                                <p className="mb-0 pt-1 ms-3 error">Please fix error(s) in the cart to Apply coupon code</p>
                                            : couponCode !== '' && !couponProcessing && couponDetails.success_status !== 0 && (
                                                <p className="mb-0 pt-1 ms-3"
                                                    style={{color: couponDetails.success_status === 1 ? 'green' : 'red'}}>
                                                {couponDetails.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="checkout-section">
                                        <div className="card">
                                        <h6 className="p-3 ps-1 pe-1 pb-0 float-start">Price Details</h6>
                                        <div className="ms-3 mt-2 me-3 me-3">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        {/* <td>Cart Price ({productTotal} items)</td> */}
                                                        <td>Cart Price ({cartLabCount} {cartLabCount > 0 ? 'items' : 'item'})</td>
                                                        <td>₹{priceDetails.all_total}</td>
                                                    </tr>
                                                    {priceDetails.discount > 0 && (
                                                        <tr>
                                                            <td>Discount</td>
                                                            <td> − ₹{priceDetails.discount}</td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td>Delivery Charges</td>
                                                        <td style={priceDetails.delivery === 0 ? {color: 'green'} : null}>{priceDetails.delivery === 0 ? 'Free' : `₹${priceDetails.delivery}`}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Total Amount</b></td>
                                                        <td><b>₹{priceDetails.total_amount}</b></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="checkout-btn">
                                            {checkoutLoader === true
                                                ?
                                                    <button
                                                        className="btn btn-primary"
                                                        disabled={true}>
                                                        Checkout <LoaderMedium size={18} color={'#fff'}/>
                                                    </button>
                                                :   <button
                                                        className="btn btn-primary"
                                                        disabled={checkoutDetails.is_checkout === 0
                                                                    || checkoutLoader
                                                                    || cartError.length > 0  ? true : false}
                                                        onClick={() => checkout()}>
                                                        Checkout
                                                    </button>
                                            }                                            
                                            {cartError.length > 0
                                                ? <p className="error mb-0 pt-1">Please fix error(s) in the cart to Checkout</p>
                                                : checkoutDetails.is_checkout !== 1 && <p className="error mb-0 pt-1">To Checkout, the Cart Price should be greater than <b>₹{checkoutDetails.minimum_checkout_amount}</b></p>
                                            }
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ):(
                            <div className="row">
                                <div className="no-product mt-4">
                                    <p className="pt-5 title">Your cart is Empty</p>
                                    <p className="sub-title ml-2 mr-2"><a href="/book-lab-test"><img alt="Order Now" src={shopNowImg}/></a></p>
                                </div>
                            </div>
                        )}
                        </>
                }
            </Row>
        // </div>
    )    
}

export default MyTestCart;