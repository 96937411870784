import React from "react";
import {
    Card,
    Col,
    Row
  } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import {SetMetaData} from './../../helper/page-title';

function AccountFaq(){
    SetMetaData("FAQ-Account", "", "");

    return (
        <div className="container">
        <Row className="justify-content-center">
            <Col md="9" sm="12">
                <Card className="m-2 mt-4 mb-5">
                    <div className="p-3 static-pages">
                        <h5 className="text-center pt-3">FAQ- Account</h5>
                        <br/>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>How can I create an account on {process.env.REACT_APP_NAME}?</Accordion.Header>
                                <Accordion.Body>
                                <a href="/login" className="link-danger">In the Login/Register Page</a>, Enter Your Mobile Number and Click on the “Submit” button. In the next step, Enter Your Name, Email & Password to Create your account on {process.env.REACT_APP_NAME}.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>I forgot my {process.env.REACT_APP_NAME} account password. How do I reset it?</Accordion.Header>
                                <Accordion.Body>
                                <a href="login" className="link-danger">In the Login/Register Page</a>, Click on Forgot your Password? Enter your registered Mobile Number, OTP and New Password to reset your password.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>How can I change the password for my {process.env.REACT_APP_NAME} account?</Accordion.Header>
                                <Accordion.Body>
                                To change the password for your {process.env.REACT_APP_NAME} account, follow these simple steps:
                                <div className="description">
                                    <ul>
                                        <li>Log in to your {process.env.REACT_APP_NAME} account</li>
                                        <li>Go to My Account › Change password</li>
                                        <li>Enter your old password</li>
                                        <li>Enter your new password</li>
                                        <li>Click on “Update” button to Change your password</li>
                                    </ul>
                                </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </Card>
            </Col>
        </Row>
        </div>
    )    
}

export default AccountFaq;