import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import {useLocation} from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Modal
} from 'react-bootstrap';
import '../../assets/css/pages/form.css';
import '../../assets/css/pages/address.css';
import { SpinnerRoundFilled } from 'spinners-react';
import ValidationHelper from '../../helper/validation';
import {LoaderMedium} from './../../components/loader';
//Axios Call
import {post_api_call, error_api_call, get_api_call} from '../../actions/action-creators/common';
import {update_pincode} from '../../actions/action-creators/state-update';
import {SetMetaData} from './../../helper/page-title';
import {alertResponseMessage} from '../../actions/action-creators/response';

const MyAddress = () => {
  SetMetaData("My Address", "", "");
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentStepNew, setCurrentStepNew] = useState(1);//1=>Submit Pincode,2=>Create New Address
  const addInputValue = {
    name: '',
    pincode: '',
    address_1: '',
    address_2: '',
    city: '',
    state_id: '',
    landmark: '',
    // mobile_number: '',
    alternative_number: '',
    address_type: {id: 1, type: 'Home (7 am – 9 pm delivery)'}
  };
  const editInputValue = {
    name: '',
    pincode: '',
    address_1: '',
    address_2: '',
    city: '',
    state_id: '',
    landmark: '',
    // mobile_number: '',
    alternative_number: '',
    address_id: ''
  };
  const [stateData, setStateData] = useState([]);
  const [toggleModalState, setToggleModal] = useState({add: false, edit: false});
  //Add
  const [inputNewFields, setNewFields] = useState(addInputValue);
  const [isErrorNew, setIsErrorNew] = useState('');
  const [submitDisabledNew, setSubmitDisabledNew] = useState(true);
  const [isNewAddProcess, setIsNewAddProcess] = useState(false);
  //Edit
  const [inputEditFields, setEditFields] = useState(editInputValue);
  const [isErrorEdit, setIsErrorEdit] = useState('');
  const [submitDisabledEdit, setSubmitDisabledEdit] = useState(true);
  const [isEditProcess, setIsEditProcess] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [fullName, setFullName] = useState("");
  //Loader
  const [pageLoading, setPageLoading] = useState(true);
  const [addressToSet, setAddressToSet] = useState('');
  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      color: 'var(--black)',
      textAlign: "left",
    }),
    control: (provided, state) => ({
      ...provided,
      border: '1px solid var(--secondary)',
      borderRadius: '0px .25rem .25rem 0px',
      color: 'var(--black)',
    })
  };
  const currentPage = document.location.pathname;
  const [addressData, setAddressData] = useState([]);

  useEffect(() => {
    fetchUserData();
    fetchAddress();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  const fetchAddress = async() => {
    try{
      const addressList = await get_api_call('address');
      // console.log(addressList, 'addressList')
      if(addressList.status === 200){
        setAddressData(addressList.data);
        if(currentPage === '/checkout/address'
          && addressList.data.length === 0
        ){
          toggleModal('add', true);
        }
      }
      setPageLoading(false);
    }catch(e){
      error_api_call({error: `account/address|fetchAddress|${e}`});
    }
  };
  //Fetch User Details
  const fetchUserData = async() => {
    try{
      const userDetails = await get_api_call('user');
      // console.log(userDetails, 'userDetails')
      if(userDetails.status === 200){
        setMobileNumber(userDetails.data.mobile_number);
        const userFullName = `${userDetails.data.first_name} ${userDetails.data.last_name ? userDetails.data.last_name : ''}`;
        setFullName(userFullName);
        setNewFields({...inputNewFields, name: userFullName});
      }
    }catch(e){
      error_api_call({error: `account/address|fetchUserData|${e}`});
    }
  };
  //Toggle New and Edit Modal
  const toggleModal = (type, state, data=null) => {
    if(state && type === 'add'){
      if(addressData.length > 9){
        alertResponseMessage({alert_type: 2, message: "Only 10 Addresses can be Added! Please Delete Old address to add New Address"});
        return false;
      }
      setNewFields({...addInputValue, name: fullName});
      setCurrentStepNew(1);
    }else if(state && type === 'edit'){
      if(stateData.length === 0){
        fetchStateList();
      }
      var editData = {...data};
      editData.address_id = data.id;
      editData.alternative_number = data.alternative_number;
      editData.state_id = {id: data.state_id, state: data.state};
      setEditFields(editData);
    }

    setToggleModal({...toggleModalState, [type]: state});
  }
  //Update Input Box: Add New Address Form
  const _onChangeNewInput = (attrName, value) => {
    // console.log(attrName, 'addd', value)
    try{
      setIsErrorNew('');
      let fields = {...inputNewFields};
      if(currentStepNew === 1){//Submit Pincode
        if(attrName === 'pincode' 
          && value.length < 7
        ){
          value = value.replace(/[^0-9]/g, '');
          fields[attrName] = value;
        }
      }else if(attrName !== 'pincode'){
        if(attrName === 'name'
          || attrName === 'city'
        ){
          value = value.replace(/[^a-zA-Z ]/g, '')
                      .replace('  ', ' ');
          fields[attrName] = value;
        }else if(attrName === 'mobile_number'
          || attrName === 'alternative_number'
        ){
          if(value.length < 11){
            value = value.replace(/[^0-9]/g, '');
            fields[attrName] = value;
          }
        }else{
          if(attrName !== 'state_id'
            && attrName !== 'address_type'
          ){
            if(value === '' || /^[0-9a-zA-Z/ -]+$/.test(value)){
              fields[attrName] = value;
            }
            // value = value.replace(/ /g, '');
            // value = value.replace('  ', ' ');
          }else{
            fields[attrName] = value;
          }
        }
      }
      setNewFields({...fields});
    }catch(e){
      error_api_call({error: `account/address|_onChangeNewInput|${JSON.stringify(inputNewFields)}|${e}`});
    }
  }
  //Input Validation:Use to Toggle Button: Disable-true|false
  useEffect(()=>{
    //Validate Input
    const _validateNewInputs = () => {
      let fields = {...inputNewFields};
      try{
        var validators = {pincode: 'required|digit:6'};
        var validation = [];
        if(currentStepNew !== 1){
          fields.state_id = fields.state_id !== '' ? fields.state_id.state : '';
          validators['name'] = 'required|min:2|max:50';
          // validators['mobile_number'] = 'required|digit:10';
          validators['alternative_number'] = 'optional|digit|min:10|max:10';
          validators['address_1'] = 'required|min:6|max:70';
          validators['address_2'] = 'required|min:6|max:70';
          validators['city'] = 'required|min:2|max:40';
          validators['state_id'] = 'required';
        }
        // console.log(fields, 'fieldssssss', validators)
        validation = ValidationHelper.validation(fields, validators).error;
        setSubmitDisabledNew(validation.length ? true : false);
        return validation.length ? false : true;
      }catch(e){
        error_api_call({error: `account/address|_validateNewInputs|${JSON.stringify(inputNewFields)}|${e}`});
      }
    };
    _validateNewInputs();
    return () => {
    }
  }, [inputNewFields]);// eslint-disable-line react-hooks/exhaustive-deps
  //Add New Address
  const _addAddress = async() => {
    //Validation:Already done
    //Show Loader
    try{
      setIsNewAddProcess(true);
      setIsErrorNew('');
      if(currentStepNew === 1){
        const pincodeApiData = await get_api_call(`check-serviceable-pincode/${inputNewFields.pincode}?check_estimation=0`);
        // console.log(pincodeApiData, 'pincodeApiData')
        if(pincodeApiData.status === 200){
          if(currentPage === '/checkout/address'){
            dispatch(update_pincode({data: {is_servicable: pincodeApiData.is_servicable, pincode: inputNewFields.pincode}}, cb_pincode => {}));
          }
          if(pincodeApiData.is_servicable !== 1){
            setIsErrorNew('Sorry! Provided Pincode is not Serviceable');
          }else{
            setSubmitDisabledNew(true);
            setCurrentStepNew(2);
            if(stateData.length === 0){
              fetchStateList();
            }
          }
        }
      }else{
        var formData = {...inputNewFields};
        formData.state_id = formData.state_id.id;
        formData.address_type = formData.address_type.id;
        formData.name = formData.name.trim();
        const newAddressData = await post_api_call('address', formData);
        // console.log(newAddressData, 'newAddressData.status')
        if(newAddressData.status === 200){
          toggleModal('add', false);
          alertResponseMessage({alert_type: 1, message: "Address Added Successfully"});
          //Redirection
          if(currentPage === '/checkout/address'){
            // dispatch(update_pincode({data: {is_servicable: 1, pincode: formData.pincode}}, cb_pincode => {}));
            // window.location.href = `/checkout?add_reference_id=${newAddressData.data.id}`;
            _redirectToCheckoutPage(newAddressData.data.id);
          }else{
            fetchAddress();
          }
        }
      }
      setIsNewAddProcess(false);
    }catch(e){
      // console.log(e)
      setIsErrorNew('Error Adding Address! Please Try Again');
      setIsNewAddProcess(false);
      error_api_call({error: `account/address|_addAddress|${JSON.stringify(inputNewFields)}|${e}`});
    }
  }
  //Fetch State List
  const fetchStateList = async() => {
    try{
        const stateList = await get_api_call('state');
        if(stateList.status === 200){
          setStateData(stateList.data);
        }else{
          setIsErrorNew('Error fetching State List');
        }
    }catch(e){
      setIsErrorNew('Error fetching State List');
      error_api_call({error: `account/address|fetchStateList|${JSON.stringify(inputNewFields)}|${e}`});
    }
  }
  //Delete Address
  const _deleteAddress = async(address_id) => {
    try{
      if(window.confirm("Are you sure? You want to delete this Address?") === true) {
        const deleteAddData =  await post_api_call('address-delete', {address_id});
        if(deleteAddData.status === 200){
          alertResponseMessage({alert_type: 1, message: "Address Deleted Successfully"});
          fetchAddress();
        }
      }
    }catch(e){
      alertResponseMessage({alert_type: 2, message: "Error Deleting Address"});
      error_api_call({error: `account/address|_deleteAddress|${JSON.stringify(inputNewFields)}|${e}`});
    }
  }
  //Input Validation:Use to Toggle Button: Disable-true|false
  useEffect(()=>{
    //Validate Input
    const _validateEditInputs = () => {
      let fields = {...inputEditFields};
      try{
        var validators = {};
        var validation = [];
        fields.state_id = fields.state_id !== '' ? fields.state_id.state : '';
        validators['name'] = 'required|min:2|max:50';
        // validators['mobile_number'] = 'required|digit:10';
        validators['alternative_number'] = 'optional|digit|min:8|max:10';
        validators['address_1'] = 'required|min:6|max:70';
        validators['address_2'] = 'required|min:15|max:70';
        validators['city'] = 'required|min:2|max:40';
        validators['state_id'] = 'required';
        // console.log(fields, 'fieldssssss', validators)
        validation = ValidationHelper.validation(fields, validators).error;
        // console.log(validation, 'validationssssssss')
        setSubmitDisabledEdit(validation.length ? true : false);
        return validation.length ? false : true;
      }catch(e){
        error_api_call({error: `account/address|_validateEditInputs|${JSON.stringify(inputNewFields)}|${e}`});
      }
    };
    _validateEditInputs();
    return () => {
    }
  }, [inputEditFields]);// eslint-disable-line react-hooks/exhaustive-deps
  //Update Input Box: Add New Address Form
  const _onChangeEditInput = (attrName, value) => {
    // console.log(attrName, 'addd', value)
    try{
      setIsErrorEdit('');
      let fields = {...inputEditFields};
      if(attrName === 'name'
        || attrName === 'city'
      ){
        value = value.replace(/[^a-zA-Z ]/g, '')
                    .replace('  ', ' ');
        fields[attrName] = value;
      }else if(attrName === 'mobile_number'
        || attrName === 'alternative_number'
      ){
        if(value.length < 11){
          value = value.replace(/[^0-9]/g, '');
          fields[attrName] = value;
        }
      }else{
        if(attrName !== 'state_id'){
          if(value === '' || /^[0-9a-zA-Z/ -]+$/.test(value)){
            fields[attrName] = value;
          }
          // value = value.replace(/ /g, '');
          // value = value.replace('  ', ' ');
        }else{
          fields[attrName] = value;
        }
      }
      setEditFields({...fields});
    }catch(e){
      error_api_call({error: `account/address|_onChangeEditInput|${JSON.stringify(inputNewFields)}|${e}`});
    }
  }
  //Update Address
  const _updateAddress = async() => {
    //Validation:Already done
    try{
      setIsEditProcess(true);
      setIsErrorEdit('');
      var formData = {...inputEditFields};
      formData.state_id = formData.state_id.id;
      formData.name = formData.name.trim();
      const updateAddressData = await post_api_call('address-update', formData);
      // console.log(updateAddressData, 'updateAddressData.status')
      if(updateAddressData.status === 200){
        toggleModal('edit', false);
        alertResponseMessage({alert_type: 1, message: "Address Updated Successfully"});
        //Redirection
        if(currentPage === '/checkout/address'){
          dispatch(update_pincode({data: {is_servicable: 1, pincode: formData.pincode}}, cb_pincode => {}));
          // window.location.href = `/checkout?add_reference_id=${formData.address_id}`;
          _redirectToCheckoutPage(formData.address_id);
        }else{
          fetchAddress();
        }
      }
  
      setIsEditProcess(false);
    }catch(e){
      // console.log(e)
      setIsErrorEdit('Error Updating Address! Please Try Again');
      setIsEditProcess(false);
      error_api_call({error: `account/address|_updateAddress|${JSON.stringify(inputEditFields)}|${e}`});
    }
  }
  //Set Address
  const _setAddress = async(e, pincode, address_id) => {
    try{
      e.preventDefault();
      e.target.disabled = true;
      setAddressToSet(address_id);
      const pincodeApiData = await get_api_call(`check-serviceable-pincode/${pincode}?check_estimation=0`);
      if(pincodeApiData.status === 200){
        dispatch(update_pincode({data: {is_servicable: pincodeApiData.is_servicable, pincode}}, cb_pincode => {}));
        if(pincodeApiData.is_servicable !== 1){
          alertResponseMessage({alert_type: 2, message: "Sorry! Provided Address is not Serviceable at this moment"});
        }else{
          _redirectToCheckoutPage(address_id);
          // window.location.href = `/checkout?add_reference_id=${address_id}`;
        }
      }
      e.target.disabled = false;
      setAddressToSet('');
    }catch(e){
      e.target.disabled = false;
      setAddressToSet('');
      // console.log(e)
      alertResponseMessage({alert_type: 2, message: "Sorry! Error Using Selected Address, Please try after some times"});
      error_api_call({error: `account/address|_setAddress|${JSON.stringify({pincode, address_id})}|${e}`});
    }
  }
  //Redirect to Checkout Page
  const _redirectToCheckoutPage = (address_id) => {
    const urlQuryString = location.search;
    const urlParam = new URLSearchParams(urlQuryString);
    const couponParam = urlParam.get('coupon_code');
    const pageParam = urlParam.get('page') || 'medicine';
    var redirectionHref = `/checkout?page=${pageParam}&add_reference_id=${address_id}`;
    if(couponParam){
      redirectionHref = `${redirectionHref}&coupon_code=${couponParam}`;
    }
    window.location.href = redirectionHref;
  }

  return (
    <div className="c-app c-default-layout align-items-center custom-form">
      <Container style={{minHeight: '66vh'}}>
        {pageLoading 
					?
						<div className="text-center mt-5">
							<LoaderMedium size={65} />
						</div>
					:
          <Row className="justify-content-center my-address">
              <div className="col-md-12 col-12 col-sm-12">
                  <div className="section-title mt-5 mb-5">
                      <h3>{currentPage === '/checkout/address'
                            ? 'Select Address' 
                            : addressData.length < 2
                              ? 'My Address'
                              : 'My Addresses'
                          }</h3>
                  </div>
              </div>
              <div className="col-md-12 col-12 col-sm-12">
                  <Row className='justify-content-center'>
                    <div className="col-md-3 col-12 col-sm-12 mb-3">
                      <Card className="p-2 text-center">
                          <Card.Body className='p-5'>
                              <button
                                  className='btn btn-address'
                                  onClick={() => toggleModal('add', true)}
                              >
                                  <i className='fa fa-plus-square-o add-fa'></i>
                                  <p className='mt-2'><b>Add Address</b></p>
                              </button>
                          </Card.Body>
                      </Card>
                    </div>
                    {addressData.map((address, index) => (
                      <div className="col-md-3 col-12 col-sm-12 address-card mb-3" key={`addrees${index}`}>
                          <Card className="p-2">
                              <Card.Body>
                                <div className="scroll-150">
                                  <p><b>{address.name}</b></p>
                                  <p>{address.address_1}</p>
                                  <p>{address.address_2}</p>
                                  {address.landmark && (
                                    <p>(Landmark: {address.landmark})</p>
                                  )}
                                  <p>{address.city}, {address.state}-{address.pincode}</p>
                                  <p><i className='fa fa-phone'></i> {mobileNumber}{address.alternative_number ? `,${address.alternative_number}` : ''}</p>
                                </div>
                                {currentPage === '/checkout/address' && (
                                  <div className='text-center address-btn-link'>
                                    <Button
                                      type="submit"
                                      // disabled={false}
                                      // onClick={() => window.location.href = `/checkout?add_reference_id=${address.id}`}
                                      onClick={(e) => _setAddress(e, address.pincode, address.id)}
                                      color={"primary"}
                                      className='mt-2'
                                    >
                                      {addressToSet === address.id && (
                                        <LoaderMedium size={23} color={'#fff'} />
                                      )}
                                      Use this Address</Button>
                                  </div>
                                )}
                              </Card.Body>
                              <div>
                                <p className='float-end'>
                                  <button
                                    className='btn btn-address'
                                    onClick={() => toggleModal('edit', true, address)}
                                  ><i className='fa fa-edit'></i></button>
                                  <button
                                    className='btn btn-address'
                                    onClick={() => _deleteAddress(address.id)}
                                  >
                                    <i className='fa fa-trash'></i>
                                  </button>
                                </p>
                              </div>
                          </Card>
                      </div>))}
                  </Row>
              </div>
          </Row>
        }
      </Container>
      {/* Add Address */}
      <Modal
          onHide={() => toggleModal('add', false)}
          backdrop="static"
          keyboard={false}
          show={toggleModalState.add}
          size="xs"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
      >
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter" className="font-20">
                  Add Address
              </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center address-modl">
              <div style={{padding: '20px 10px'}}>
                <InputGroup className="mb-4">
                  <InputGroup.Text><i className="fa fa-map-pin"></i><em>*</em></InputGroup.Text>
                  <Form.Control 
                    type={"text"}
                    placeholder="Pincode" 
                    autoComplete="current-pincode" 
                    value={inputNewFields.pincode}
                    onChange={(event) => _onChangeNewInput('pincode', event.target.value)}
                    maxLength={6}
                    // readOnly={isEmailOtpProcess ? true : false}
                    readOnly={currentStepNew !== 1 || isNewAddProcess ? true : false}
                  />
                </InputGroup>
                {currentStepNew === 2 && (
                  <>
                    {/* Name */}
                    <div className="mb-3">
                      <InputGroup>
                        <InputGroup.Text><i className="fa fa-user"></i><em>*</em></InputGroup.Text>
                        <Form.Control 
                          type={"text"}
                          placeholder="Full Name"
                          autoComplete="first-name" 
                          value={inputNewFields.name === "" ? fullName : inputNewFields.name}
                          onChange={(event) => _onChangeNewInput('name', event.target.value)}
                          maxLength={50}
                          readOnly={isNewAddProcess ? true : false}
                        />
                      </InputGroup>
                    </div>
                    {/* Mobile Number */}
                    <div className="mb-3">
                      <InputGroup>
                          <InputGroup.Text><i className="fa fa-phone"></i><em>*</em></InputGroup.Text>
                          <Form.Control 
                          type="text" 
                          placeholder="Mobile Number" 
                          autoComplete="mobile_number" 
                          value={mobileNumber}
                          // onChange={(event) => _onChangeNewInput('mobile_number', event.target.value)}
                          maxLength={10}
                          // readOnly={isNewAddProcess ? true : false}
                          readOnly={true}
                          />
                      </InputGroup>
                    </div>
                    {/* Alternate Number */}
                    <div className="mb-3">
                      <InputGroup>
                          <InputGroup.Text><i className="fa fa-phone"></i><em>&nbsp;</em></InputGroup.Text>
                          <Form.Control 
                          type="text" 
                          placeholder="Alternate Number"
                          autoComplete="alternative_number"
                          value={inputNewFields.alternative_number}
                          onChange={(event) => _onChangeNewInput('alternative_number', event.target.value)}
                          maxLength={10}
                          readOnly={isNewAddProcess ? true : false}
                          />
                      </InputGroup>
                    </div>
                    {/* Address 1 */}
                    <div className="mb-3">
                      <InputGroup>
                        <InputGroup.Text><i className="fa fa-map-marker"></i><em>*</em></InputGroup.Text>
                        <Form.Control
                          as="textarea" 
                          type={"text"}
                          placeholder="Flat no., House no., Building Name, Company Name or Apartment name"
                          autoComplete="address-new" 
                          value={inputNewFields.address_1}
                          onChange={(event) => _onChangeNewInput('address_1', event.target.value)}
                          maxLength={70}
                          readOnly={isNewAddProcess ? true : false}
                        />
                      </InputGroup>
                    </div>
                    {/* Address 2 */}
                    <div className="mb-3">
                      <InputGroup>
                        <InputGroup.Text><i className="fa fa-map-marker"></i><em>*</em></InputGroup.Text>
                        <Form.Control
                          as="textarea" 
                          type={"text"}
                          placeholder="Area, Street, Sector, Village"
                          autoComplete="address-new" 
                          value={inputNewFields.address_2}
                          onChange={(event) => _onChangeNewInput('address_2', event.target.value)}
                          maxLength={70}
                          readOnly={isNewAddProcess ? true : false}
                        />
                      </InputGroup>
                    </div>
                    {/* City */}
                    <div className="mb-3">
                      <InputGroup>
                        <InputGroup.Text><i className="fa fa-map-marker"></i><em>*</em></InputGroup.Text>
                        <Form.Control 
                          type={"text"}
                          placeholder="City"
                          autoComplete="city" 
                          value={inputNewFields.city}
                          onChange={(event) => _onChangeNewInput('city', event.target.value)}
                          maxLength={40}
                          readOnly={isNewAddProcess ? true : false}
                        />
                      </InputGroup>
                    </div>
                    {/* State */}
                    <div className="mb-4 mt-3">
                      <InputGroup>
                        <InputGroup.Text><i className="fa fa-globe" style={{fontSize: 10}}></i><em>*</em></InputGroup.Text>
                        <div className='select-address'>
                          <Select
                            options={[...stateData]}
                            getOptionLabel={(option)=>option.state}
                            getOptionValue={(option)=>option.id}
                            onChange={(e) => _onChangeNewInput('state_id', e)}
                            isClearable={false}
                            styles={CustomStyle}
                            placeholder="Select State"
                            value={inputNewFields.state_id}
                            // menuColor='red'
                          />
                        </div>
                      </InputGroup>
                    </div>
                    {/* Landmark */}
                    <div className="mb-3">
                      <InputGroup>
                        <InputGroup.Text><i className="fa fa-map-signs"></i></InputGroup.Text>
                        <Form.Control 
                          type={"text"}
                          placeholder="Landmark"
                          autoComplete="land-mark" 
                          value={inputNewFields.landmark}
                          onChange={(event) => _onChangeNewInput('landmark', event.target.value)}
                          maxLength={50}
                          readOnly={isNewAddProcess ? true : false}
                        />
                      </InputGroup>
                    </div>
                    {/* Address Type */}
                    <div className="mb-4 mt-3">
                      <InputGroup>
                        <InputGroup.Text><i className="fa fa-building" style={{fontSize: 10}}></i><em>*</em></InputGroup.Text>
                        <div className='select-address'>
                          <Select
                            options={[{id: 1, type: 'Home (7 am – 9 pm delivery)'},
                                      {id: 2, type: 'Office/Commercial (10 AM - 6 PM delivery)'}]}
                            getOptionLabel={(option)=>option.type}
                            getOptionValue={(option)=>option.id}
                            onChange={(e) => _onChangeNewInput('address_type', e)}
                            isClearable={false}
                            styles={CustomStyle}
                            placeholder="Address Type"
                            value={inputNewFields.address_type}
                          />
                        </div>
                      </InputGroup>
                    </div>
                  </>
                )}
                <Row className='mt-5'>
                  {isErrorNew !== '' &&
                    (<Col xs="12" className="text-center">
                        <div className="alert alert-danger" role="alert">
                            {isErrorNew}
                        </div>
                    </Col>)
                  }
                  <Col xs="12" className="text-center">
                  {isNewAddProcess ? 
                    <Button
                      title="Submit"
                      disabled={true}
                      color={'primary'}
                    >
                      <SpinnerRoundFilled color="#fff" size={20} />
                      {currentStepNew === 2
                      ? 'Adding...'
                      : 'Submiting...'
                      }
                    </Button>
                    : 
                    <Button
                      type="submit"
                      disabled={submitDisabledNew}
                      onClick={() => _addAddress()}
                      color={"primary"}
                      className="px-4"
                    >{currentStepNew === 2
                      ? 'Add Address'
                      : 'Submit'
                      }</Button>
                  }
                  </Col>
                </Row>
              </div>
          </Modal.Body>
          {/* <Modal.Footer className="input-group-addon">
              <a target="_blank"><p className="visit-link text-center m-0">Visit</p></a>
          </Modal.Footer> */}
      </Modal>
      {/* Edit Address */}
      <Modal
        onHide={() => toggleModal('edit', false)}
        backdrop="static"
        keyboard={false}
        show={toggleModalState.edit}
        size="xs"
        aria-labelledby="contained-modal-title-vcenter"
        // centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="font-20">
            Edit Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center address-modl">
          <div style={{padding: '20px 10px'}}>
            <div className="mb-3">
              <InputGroup>
                <InputGroup.Text><i className="fa fa-map-pin"></i><em>*</em></InputGroup.Text>
                <Form.Control 
                  type={"text"}
                  placeholder="Pincode" 
                  autoComplete="current-pincode" 
                  value={inputEditFields.pincode}
                  onChange={(event) => _onChangeEditInput('pincode', event.target.value)}
                  maxLength={6}
                  readOnly={true}
                />
              </InputGroup>
            </div>
            {/* Name */}
            <div className="mb-3">
              <InputGroup>
                <InputGroup.Text><i className="fa fa-user"></i><em>*</em></InputGroup.Text>
                <Form.Control 
                  type={"text"}
                  placeholder="Full Name"
                  autoComplete="first-name"
                  value={inputEditFields.name }
                  onChange={(event) => _onChangeEditInput('name', event.target.value)}
                  maxLength={50}
                  readOnly={isEditProcess ? true : false}
                />
              </InputGroup>
            </div>
            {/* Mobile Number */}
            <div className="mb-3">
              <InputGroup>
                  <InputGroup.Text><i className="fa fa-phone"></i><em>*</em></InputGroup.Text>
                  <Form.Control 
                  type="text" 
                  placeholder="Mobile Number" 
                  autoComplete="mobile_number" 
                  value={mobileNumber}
                  // onChange={(event) => _onChangeEditInput('mobile_number', event.target.value)}
                  maxLength={10}
                  readOnly={true}
                  // readOnly={isEditProcess ? true : false}
                  />
              </InputGroup>
            </div>
            {/* Alternate Number */}
            <div className="mb-3">
              <InputGroup>
                  <InputGroup.Text><i className="fa fa-phone"></i><em>&nbsp;</em></InputGroup.Text>
                  <Form.Control 
                  type="text" 
                  placeholder="Alternate Number"
                  autoComplete="alternative_number"
                  value={inputEditFields.alternative_number ? inputEditFields.alternative_number : ''}
                  onChange={(event) => _onChangeEditInput('alternative_number', event.target.value)}
                  maxLength={10}
                  readOnly={isEditProcess ? true : false}
                  />
              </InputGroup>
            </div>
            {/* Address 1 */}
            <div className="mb-3">
              <InputGroup>
                <InputGroup.Text><i className="fa fa-map-marker"></i><em>*</em></InputGroup.Text>
                <Form.Control
                  as="textarea" 
                  type={"text"}
                  placeholder="Flat no., House no., Building Name, Company Name or Apartment name"
                  autoComplete="address-new" 
                  value={inputEditFields.address_1}
                  onChange={(event) => _onChangeEditInput('address_1', event.target.value)}
                  maxLength={70}
                  readOnly={isEditProcess ? true : false}
                />
              </InputGroup>
            </div>
            {/* Address 2 */}
            <div className="mb-3">
              <InputGroup>
                <InputGroup.Text><i className="fa fa-map-marker"></i><em>*</em></InputGroup.Text>
                <Form.Control
                  as="textarea" 
                  type={"text"}
                  placeholder="Area, Street, Sector, Village"
                  autoComplete="address-new" 
                  value={inputEditFields.address_2}
                  onChange={(event) => _onChangeEditInput('address_2', event.target.value)}
                  maxLength={70}
                  readOnly={isEditProcess ? true : false}
                />
              </InputGroup>
            </div>
            {/* City */}
            <div className="mb-3">
              <InputGroup>
                <InputGroup.Text><i className="fa fa-map-marker"></i><em>*</em></InputGroup.Text>
                <Form.Control 
                  type={"text"}
                  placeholder="City"
                  autoComplete="city" 
                  value={inputEditFields.city}
                  onChange={(event) => _onChangeEditInput('city', event.target.value)}
                  maxLength={40}
                  readOnly={isEditProcess ? true : false}
                />
              </InputGroup>
            </div>
            {/* State */}
            <div className="mb-4 mt-3">
              <InputGroup>
                <InputGroup.Text><i className="fa fa-globe" style={{fontSize: 10}}></i><em>*</em></InputGroup.Text>
                <div className='select-address'>
                  <Select
                    options={[...stateData]}
                    getOptionLabel={(option)=>option.state}
                    getOptionValue={(option)=>option.id}
                    onChange={(e) => _onChangeEditInput('state_id', e)}
                    isClearable={false}
                    styles={CustomStyle}
                    placeholder="Select State"
                    value={inputEditFields.state_id}
                    // menuColor='red'
                  />
                </div>
              </InputGroup>
            </div>
            {/* Landmark */}
            <div className="mb-3">
              <InputGroup>
                <InputGroup.Text><i className="fa fa-map-signs"></i></InputGroup.Text>
                <Form.Control 
                  type={"text"}
                  placeholder="Landmark"
                  autoComplete="land-mark" 
                  value={inputEditFields.landmark}
                  onChange={(event) => _onChangeEditInput('landmark', event.target.value)}
                  maxLength={50}
                  readOnly={isEditProcess ? true : false}
                />
              </InputGroup>
            </div>
            <Row className='mt-5'>
              {isErrorEdit !== '' &&
                (<Col xs="12" className="text-center">
                    <div className="alert alert-danger" role="alert">
                        {isErrorEdit}
                    </div>
                </Col>)
              }
              <Col xs="12" className="text-center">
              {isEditProcess ? 
                <Button
                  title="Submit"
                  disabled={true}
                  color={'primary'}
                >
                  <SpinnerRoundFilled color="#fff" size={20} />
                  Updating...
                </Button>
                : 
                <Button
                  type="submit"
                  disabled={submitDisabledEdit}
                  onClick={() => _updateAddress()}
                  color={"primary"}
                  className="px-4"
                >Update Address</Button>
              }
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default MyAddress
