import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useLocation} from 'react-router-dom';
import {
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  Form,
  InputGroup,
  Row
} from 'react-bootstrap';
import '../../assets/css/pages/form.css';
import {get_api_call, post_api_call, error_api_call} from '../../actions/action-creators/common';
import { SpinnerRoundFilled } from 'spinners-react';
import {LoaderMedium} from './../../components/loader';
import {alertResponseMessage} from '../../actions/action-creators/response';
import {update_verify_state} from '../../actions/action-creators/auth';
import {SetMetaData} from './../../helper/page-title';
const resendStatus = {
  1: "Resend OTP",
  2: "Sending OTP...",
  3: "Request OTP in"
};

const Verification = () => {
    SetMetaData("Verification", "", "");
    const location = useLocation();
    //Store Data
    const authReduxData = useSelector(state => state.auth);
    // console.log('authReduxData', authReduxData)
    const dispatch = useDispatch()
    //State
    const [inputFields, setFields] = useState({email_otp: '',
                                              mobile_otp: ''});
    const [pageLoader, setPageLoader] = useState(true);
    const [verifiedState, setVerifiedState] = useState({email: 0, mobile: 0});
    const [userData, setUserData] = useState({});
    const [secureOtp, setsecureOtp] = useState({email: true, mobile: true});
    const [isMobOtpProcess, setIsMobOtpProcess] = useState(false);
    const [isEmailOtpProcess, setIsEmailOtpProcess] = useState(false);
    const [isMobError, setIsMobError] = useState('');
    const [isEmailError, setIsEmailError] = useState('');
    //To stop user check
    const [userCheck, setUserCheck] = useState(true);
    //Resend OTP
    const [resendEmailOtp, setResendEmailOtp] = useState({status: 1, count: 0});
    const [emailOtpCounter, setEmailOtpCounter] = useState(20);
    const [resendMobileOtp, setResendMobileOtp] = useState({status: 1, count: 0});
    const [mobileOtpCounter, setMobileOtpCounter] = useState(20);
    //Check if user is verified or not
    useEffect(() => {
      if(authReduxData.user_info !== null
        && userCheck
      ){
        const authData = JSON.parse(authReduxData.user_info);
        console.log(authData.is_verified, 'jjjj');
        // if(authData.is_verified !== 1
        //   || authData.is_email_verified !== 1
        // ){
        if(authData.is_verified === 0
          || authData.is_verified === 2
        ){
          const checkUser = async() => {
            try{
              const userDetails = await get_api_call('user-details?type=verify', {});
              // console.log('userDetails', userDetails.data)
              if(userDetails.status === 200){
                console.log('userDetails', userDetails.data)
                setUserData(userDetails.data);
                setVerifiedState({mobile: userDetails.data.is_verified === 1 || userDetails.data.is_verified === 3 ? 1 : 0,
                                  email: userDetails.data.email
                                          ? ((userDetails.data.is_verified === 2 || userDetails.data.is_verified === 3) ? 1 : 0)
                                          : 1});
                                          console.log(userDetails.data.email, ' userDetails.data.email ')
                // if(userDetails.data.is_verified !== 1
                //   || userDetails.data.is_email_verified !== 1
                // ){
                if(userDetails.data.is_verified !== 3){
                  // setUserData(userDetails.data);
                  // setVerifiedState({mobile: userDetails.data.is_verified,
                  //                   email: userDetails.data.is_email_verified});
                  setPageLoader(false);
                }
                // else{
                  //Update is_verified state
                  //Redirect
                  // window.location.href = redirectUri ? `/${redirectUri}` : "/";
                // }
                setUserCheck(false);
              }
              // else{
              //   setPageLoader(false);
              // }
            }catch(e){
              error_api_call({error: `auth/verification|urlHandling|${e}`});
              alertResponseMessage({alert_type: 2, message: "Something Went wrong, Please try after some times"});
            }
          };
          checkUser();
        }else{
          //Redirect
          // window.location.href = redirectUri ? `/${redirectUri}` : "/";
          setVerifiedState({mobile: 1,
                          email: 1});
        }
      }
    }, [authReduxData]);// eslint-disable-line react-hooks/exhaustive-deps
    //Redirect after user verification
    useEffect(() => {
      let timer = setTimeout(() => {
        _redirectAfterVerify();
      }, 1500);
      return () => {
        clearTimeout(timer);
      };
    }, [verifiedState]);// eslint-disable-line react-hooks/exhaustive-deps
    //Resend Counter
    useEffect(() => {
      let timer = '';
      if(resendEmailOtp.status === 3
        && emailOtpCounter > 0
      ){
        timer = setInterval(() => {
          setEmailOtpCounter(emailOtpCounter-1);
          if(emailOtpCounter === 1){
            setResendEmailOtp({...resendEmailOtp, status: 1});
          }
        }, 1000);
      }
      
      return () => {
        clearTimeout(timer);
      };
    }, [emailOtpCounter]);// eslint-disable-line react-hooks/exhaustive-deps
    //Resend Counter
    useEffect(() => {
      let timer = '';
      if(resendMobileOtp.status === 3
        && mobileOtpCounter > 0
      ){
        timer = setInterval(() => {
          setMobileOtpCounter(mobileOtpCounter-1);
          if(mobileOtpCounter === 1){
            setResendMobileOtp({...resendMobileOtp, status: 1});
          }
        }, 1000);
      }
      
      return () => {
        clearTimeout(timer);
      };
    }, [mobileOtpCounter]);// eslint-disable-line react-hooks/exhaustive-deps
    //Toggle Show-Hide OTP
    const _showHideOtp = (field) => {
      setsecureOtp({...secureOtp, [field]: !secureOtp[field]})
    };
    //Update Input Box
    const _onChangeText = (attrName, value) => {
      try{
        if(attrName === 'mobile_otp'){
          setIsMobError('');
        }else{
          setIsEmailError('');
        }
        let fields = {...inputFields};
        // value.replace(/ /g, '');
        if(value.length < 6){
          value = value.replace(/[^0-9]/g, '');
        }
        fields[attrName] = value;
        setFields({...fields});
      }catch(e){
        error_api_call({error: `auth/verification|_onChangeText|${JSON.stringify(inputFields)}|${e}`});
        alertResponseMessage({alert_type: 2, message: "Something Went wrong, Please try after some times"});
      }
    }
    //Mobile Verification
    const _mobileVerification = async() => {
      try{
        if(!isMobOtpProcess){
          setIsMobOtpProcess(true);
          console.log('inputFields', inputFields);
          const mobileVerifyData = await post_api_call('mobile-verification', 
                                          {otp: inputFields.mobile_otp});
                                          console.log('mobileVerifyData', mobileVerifyData)
                                          // return;
			    if(mobileVerifyData.status === 200){
            dispatch(update_verify_state(mobileVerifyData.data, cb_verify => {
              if(cb_verify.status === 200){
                alertResponseMessage({alert_type: 1, message: mobileVerifyData.message});
                setVerifiedState({...verifiedState, mobile: 1});
                //Update is_verified state
                //Redirect
              }
            }));
          }else{
            setIsMobError(mobileVerifyData.message);
            setIsMobOtpProcess(false);
          }
        }
      }catch(e){
        setIsMobOtpProcess(false);
        setIsMobError('Something Went wrong, Please try after some times');
        alertResponseMessage({alert_type: 2, message: "Something Went wrong, Please try after some times"});
        error_api_call({error: `auth/verification|_mobileVerification|${JSON.stringify(inputFields)}|${e}`});
      }
    }
    //Email Verification
    const _emailVerification = async() => {
      try{
        if(!isEmailOtpProcess){
          setIsEmailError('');
          setIsEmailOtpProcess(true);
          console.log('inputFields', inputFields);
          const emailVerifyData = await post_api_call('email-verification', 
                                          {otp: inputFields.email_otp});
                                          console.log('emailVerifyData', emailVerifyData)
			    if(emailVerifyData.status === 200){
            dispatch(update_verify_state(emailVerifyData.data, cb_verify => {
              if(cb_verify.status === 200){
                alertResponseMessage({alert_type: 1, message: emailVerifyData.message});
                setVerifiedState({...verifiedState, email: 1});
                //Update is_verified state
                //Redirect
              }
            }));
          }else{
            setIsEmailError(emailVerifyData.message);
            setIsEmailOtpProcess(false);
          }
        }
      }catch(e){
        alertResponseMessage({alert_type: 2, message: "Something Went wrong, Please try after some times"});
        setIsEmailError('Something Went wrong, Please try after some times');
        setIsEmailOtpProcess(false);
        error_api_call({error: `auth/verification|_emailVerification|${JSON.stringify(inputFields)}|${e}`});
      }
    }
    //Redirect after user verification
    const _redirectAfterVerify = () => {
      console.log(verifiedState, 'verifiedState')
      console.log(authReduxData, 'authReduxData')
        try{
          if(verifiedState.mobile === 1
            && verifiedState.email === 1
          ){
            const urlQuryString = location.search;
            const urlParam = new URLSearchParams(urlQuryString);
            const urlRedirect = urlParam.get('redirect_uri');
            console.log(urlRedirect, 'urlRedirect')
            // setTimeout(() => {
              // window.location.href = urlRedirect !== '' && urlRedirect !== null ? `${urlRedirect}` : "/my-account";
              window.location.href = urlRedirect !== '' && urlRedirect !== null ? `${urlRedirect}` : "/order-medicines";
            // }, 300);
          }
        }catch(e){
          alertResponseMessage({alert_type: 2, message: "Something Went wrong, Please try after some times"});
          error_api_call({error: `auth/verification|_redirectAfterVerify|${e}`});
        }
    };
    //Send Email OTP
    const _resendOtpEmail = async() => {
      console.log('ddsdsdada')
      const resendCount = resendEmailOtp.count+1;
      setResendEmailOtp({...resendEmailOtp, status: 2});
      try{
        const sendOtpData = await get_api_call('resend-otp/email', {});
        console.log('sendOtpData', sendOtpData)
        if(sendOtpData.status === 200){
          alertResponseMessage({alert_type: 1, message: "OTP Sent Successfully"});
          console.log(resendCount, 'resendCountresendCountresendCount')
          setResendEmailOtp({status: 3, count: resendCount});
          setEmailOtpCounter(19);
        }else{
          setResendEmailOtp({...resendEmailOtp, status: 1});
        }
      }catch(e){
        alertResponseMessage({alert_type: 2, message: "Something Went wrong, Please try after some times"});
        error_api_call({error: `auth/verification|_resendOtpEmail|${e}`});
      }
    }
    //Send Mobile OTP
    const _resendOtpMobile = async() => {
      console.log('ddsdsdada')
      const resendCount = resendMobileOtp.count+1;
      setResendMobileOtp({...resendMobileOtp, status: 2});
      try{
        const sendOtpData = await get_api_call('resend-otp/mobile', {});
        console.log('sendOtpData', sendOtpData)
        if(sendOtpData.status === 200){
          alertResponseMessage({alert_type: 1, message: "OTP Sent Successfully"});
          console.log(resendCount, 'resendCountresendCountresendCount')
          setResendMobileOtp({status: 3, count: resendCount});
          setMobileOtpCounter(19);
        }else{
          setResendMobileOtp({...resendMobileOtp, status: 1});
        }
      }catch(e){
        alertResponseMessage({alert_type: 2, message: "Something Went wrong, Please try after some times"});
        error_api_call({error: `auth/verification|_resendOtpMobile|${e}`});
      }
    }

    return (
      <div className="c-app c-default-layout align-items-center custom-form">
        <Container style={{minHeight: '66vh'}}>
          <Row className="justify-content-center">
            <Col md="4">
              {pageLoader
                ? <div className="p-2 text-center" style={{margin: '100px 0px'}}>
                    <LoaderMedium size={65} />
                  </div>
                : <CardGroup>
                    <div className="p-2" style={{margin: '35px 0px 30px'}}>
                      {verifiedState.mobile === 0 && (
                        <Card className='mb-5'>
                          <Card.Body>
                            {/* <form name="login" id="login"> */}
                            {/* Mobile Verification */}
                            <h5 className="text-center">Mobile Number Verification</h5>
                            <p className="text-muted text-center">OTP is sent to your Registered Mobile Number.
                              <br/>
                              <b>{userData.mobile_number}</b>
                            </p>  
                            <InputGroup className="mb-4">
                              <InputGroup.Text><i className="fa fa-lock"></i><em>*</em></InputGroup.Text>
                              <Form.Control 
                                type={secureOtp.mobile ? "password" : "text"}
                                placeholder="Mobile OTP" 
                                autoComplete="current-password" 
                                value={inputFields.mobile_otp}
                                onChange={(event) => _onChangeText('mobile_otp', event.target.value)}
                                maxLength={6}
                                readOnly={isMobOtpProcess ? true : false}
                              />
                              {inputFields.mobile_otp !== "" &&
                                <i className={`fa icon-eye ms-3 ${secureOtp.mobile ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => _showHideOtp('mobile')}></i>
                              }
                            </InputGroup>
                            <Row>
                              {isMobError &&
                                (<Col xs="12" className="text-center">
                                    <div className="alert alert-danger" role="alert">
                                        {isMobError}
                                    </div>
                                </Col>)
                                }
                              <Col xs="12" className="text-center">
                                {isMobOtpProcess ? 
                                    <Button
                                        title="Verify"
                                        disabled={true}
                                        color={'primary'}
                                    >
                                        <SpinnerRoundFilled color="#fff" size={20} /> Verifying...
                                    </Button>
                                    : 
                                    <Button
                                      type="submit"
                                      disabled={inputFields.mobile_otp.length > 3 ? false : true}
                                      onClick={() => _mobileVerification()}
                                      color={"primary"}
                                      className="px-4"
                                    >Verify</Button>
                                }
                              </Col>
                              <Col xs="12" className="text-right">
                                {resendMobileOtp.count < 3 ? (
                                  <p className="link">
                                    <button
                                      className='btn p-0 link-custom'
                                      onClick={() => _resendOtpMobile()}
                                      disabled={resendMobileOtp.status === 1 ? false : true}
                                    >
                                      {resendStatus[resendMobileOtp.status]} {resendMobileOtp.status === 3 ? `${mobileOtpCounter} Sec` : ''}
                                    </button>
                                  </p>
                                ):(
                                  <p className="link" style={{color: 'green'}}>OTP Sent Successfully</p>
                                )}
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      )}
                      {verifiedState.email === 0 && (
                        <Card className='mb-2'>
                          <Card.Body>
                            {/* Email Verification */}
                            <h5 className="text-center">Email Verification</h5>
                            <p className="text-muted text-center">OTP is sent to your Registered Email Address, please check your inbox and spam folder too.
                              <br/>
                              <b>{userData.email}</b>
                            </p>
                            <InputGroup className="mb-4">
                              <InputGroup.Text><i className="fa fa-lock"></i><em>*</em></InputGroup.Text>
                              <Form.Control 
                                type={secureOtp.email ? "password" : "text"}
                                placeholder="Email OTP" 
                                autoComplete="current-email-otp" 
                                value={inputFields.email_otp}
                                onChange={(event) => _onChangeText('email_otp', event.target.value)}
                                maxLength={6}
                                readOnly={isEmailOtpProcess ? true : false}
                              />
                              {inputFields.email_otp !== "" &&
                                <i className={`fa icon-eye ms-3 ${secureOtp.email ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => _showHideOtp('email')}></i>
                              }
                            </InputGroup>
                            <Row>
                              {isEmailError &&
                                (<Col xs="12" className="text-center">
                                    <div className="alert alert-danger" role="alert">
                                        {isEmailError}
                                    </div>
                                </Col>)
                                }
                              <Col xs="12" className="text-center">
                                {isEmailOtpProcess ? 
                                    <Button
                                        title="Verify"
                                        disabled={true}
                                        color={'primary'}
                                    >
                                        <SpinnerRoundFilled color="#fff" size={20} /> Verifying...
                                    </Button>
                                    : 
                                    <Button
                                    type="submit"
                                        disabled={inputFields.email_otp.length > 3 ? false : true}
                                        onClick={() => _emailVerification()}
                                        color={"primary"}
                                        className="px-4"
                                    >Verify</Button>
                                }
                              </Col>
                              <Col xs="12" className="text-right">
                                {resendEmailOtp.count < 3 ? (
                                  <p className="link">
                                    <button
                                      className='btn p-0 link-custom'
                                      onClick={() => _resendOtpEmail()}
                                      disabled={resendEmailOtp.status === 1 ? false : true}
                                    >
                                      {resendStatus[resendEmailOtp.status]} {resendEmailOtp.status === 3 ? `${emailOtpCounter} Sec` : ''}
                                    </button>
                                  </p>
                                ):(
                                  <p className="link" style={{color: 'green'}}>OTP Sent Successfully</p>
                                )}
                              </Col>
                            </Row>
                            {/* </form> */}
                          </Card.Body>
                        </Card>
                      )}
                    </div>
                </CardGroup>
              }
            </Col>
          </Row>
        </Container>
      </div>
    )
}

export default Verification
