import {
    CART_COUNT,
    PINCODE_DATA,
    // DEVICE_DATA
} from "../action-types/state-update";

const initialState = {
    cart_count: 0,
    pincode_data: null,
    // device_data: {is_initial: 1, device_id: ''}//{device_id: ''}
}

const ReducerStateUpdate = (state = initialState, { type, payload }) => {
    switch (type) {
      case CART_COUNT:
        return {
          ...state,
          cart_count: payload
        };
      case PINCODE_DATA:
        return {
          ...state,
          pincode_data: JSON.stringify(payload)
        };
      // case DEVICE_DATA:
      //   return {
      //     ...state,
      //     device_data: JSON.stringify(payload)
      //   };
      default:
        return state;
    }
  };

export default ReducerStateUpdate;