/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react';

export const Footer = () => {
	const [visible, setVisible] = useState(false);
  
	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 300){
		setVisible(true)
		} 
		else if (scrolled <= 300){
		setVisible(false)
		}
	};

	const scrollToTop = () =>{
		window.scrollTo({
		top: 0, 
		behavior: 'smooth'
		/* you can also use 'auto' behaviour
			in place of 'smooth' */
		});
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisible);

		return () => window.removeEventListener('scroll', toggleVisible)

	}, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
		<>
		<footer id="footer" className="section">

			<div className="container">

				<div className="row">
					{/* <div className="col-md-12">
						<div className="newsletter">
							<p>Sign Up for the <strong>NEWSLETTER</strong></p>
							<form>
								<input className="input" type="email" placeholder="Enter Your Email"/>
								<button className="newsletter-btn"><i className="fa fa-envelope"></i> Subscribe</button>
							</form>
							<ul className="newsletter-follow">
								<li>
									<a href="/"><i className="fa fa-facebook"></i></a>
								</li>
								<li>
									<a href="/"><i className="fa fa-twitter"></i></a>
								</li>
								<li>
									<a href="/"><i className="fa fa-instagram"></i></a>
								</li>
								<li>
									<a href="/"><i className="fa fa-pinterest"></i></a>
								</li>
							</ul>
						</div>
					</div> */}
					<div className="col-md-12">
						<div className="row justify-content-center">
							<div className="col-md-3 col-xs-6">
								<div className="footer">
									<h3 className="footer-title">About Us</h3>
									<p>"{process.env.REACT_APP_NAME}" is a one-stop platform to Order Medicines & Book Lab Test.</p>
									<ul className="footer-links">
										{/* <li><a href="#"><i className="fa fa-map-marker"></i> Road</a></li> */}
										{/* <li><a href="#"><i className="fa fa-phone"></i>+91-</a></li> */}
										<li><a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}><i className="fa fa-envelope"></i>{process.env.REACT_APP_SUPPORT_EMAIL}</a></li>
									</ul>
								</div>
							</div>
							<div className="col-md-3 col-xs-6">
								<div className="footer">
									<h3 className="footer-title">Information</h3>
									<ul className="footer-links">
										<li><a href="/about-us">About Us</a></li>
										<li><a href="/contact-us">Contact Us</a></li>
										<li><a href="/privacy-policy">Privacy Policy</a></li>
										<li><a href="/terms-conditions">Terms &amp; Conditions</a></li>
									</ul>
								</div>
							</div>
							<div className="col-md-3 col-xs-6">
								<div className="footer">
									<h3 className="footer-title">Queries</h3>
									<ul className="footer-links">
										<li><a href="/faqs">FAQ</a></li>
										{/* <li><a href="/faq-payment">Payments</a></li> */}
										<li><a href="/faq-shipping">Shipping</a></li>
										<li><a href="/cancellation-returns">Cancellation & Returns</a></li>
									</ul>
								</div>
							</div>
							<div className="col-md-3 col-xs-6">
								<div className="footer">
									<h3 className="footer-title">Social</h3>
									<ul className="footer-links">
										<li><i className="lni lni-facebook-oval me-2"></i><a href="https://www.facebook.com" target="_blank" rel="noreferrer">Facebook</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
		<a
            href="#"
            id="scrollToTopp"
            className={`back-to-top d-flex align-items-center justify-content-center ${visible ? 'active' : ''}`}
            rel="noreferrer"
            onClick={scrollToTop} 
        ><i className="fa fa-arrow-up"></i></a>
		</>
    )
}
