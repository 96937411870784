import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
// import {
//   Link
// } from "react-router-dom";
import {stringToUrl} from '../../helper/custom-function';
import {LoaderMedium} from '../loader';
import { popperConfigDrop } from "../../config/popper";
// import moment from 'moment';
// import dompurify from 'dompurify';
import { calculatePercent } from "../../helper/custom-function";
import noImage from '../../assets/images/no-image.png';
import labIcon from '../../assets/images/icon/test-results.png';

function ListingRow(props){
    const dropCount = props.display_type === 'cart_test'
                        ? [1, 2, 3, 4, 5]
                        : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    // const htmlSanitizer = dompurify.sanitize;
    return (
        <>
        {/* // <a href="/deal-details/123"> */}
        {/* {(props.display_type !== 'cart' 
            || (props.display_type === 'cart' 
                && props.cart_data[props.data.product_detail_id] > 0)
        ) && ( */}
            <div className="card mb-15 p-2 cart-listing">
                <div className="row">
                    <div className="col-md-1 col-3">
                        <a href={`${props.url}/${props.data[props.urlFetchKey]}/${stringToUrl(props.data.name)}`} target="_blank" rel="noreferrer">
                            <div className="deal-image">
                                {/* {(props.display_type !== 'cart_test' && props.display_type !== 'checkout') */}
                                {(props.display_type === 'cart_medicine'
                                    || props.display_type === 'checkout'
                                    || props.display_type === 'cart_alter_med'
                                )
                                    ?
                                        <img className="img-thumbnail" src={noImage} alt="Medicine"/>
                                        // <>{props.data.thumbnail !== null && props.data.thumbnail !== ''
                                        //     ? <img className="img-thumbnail" src={props.data.thumbnail} alt={props.data.name} />
                                        //     : <img className="img-thumbnail" src={noImage} alt={props.data.name} />
                                        // }</>
                                    :
                                        <img className="img-thumbnail" src={labIcon} alt="Lab Test"/>
                                }
                            </div>
                        </a>
                    </div>
                    <div className="col-md-11 col-9">
                        <div className="row">
                            <div className="col-md-11 col-10 p-0">
                                <a href={`${props.url}/${props.data[props.urlFetchKey]}/${stringToUrl(props.data.name)}`} target="_blank" rel="noreferrer">
                                    <h6 className="mb-0">{props.data.name}</h6>
                                </a>
                                {props.display_type === 'cart_alter_med' && <span>{props.data.manufacturer}</span>}
                                {props.display_type === 'cart_test'
                                    ?
                                        <h6 className="fade-title text-lef text-start">{props.data.lab_name}</h6>
                                    :
                                        <h6 className="fade-title text-lef text-start">{props.data.packet_name} of {props.data.unit_name} {props.data.unit_type}</h6>
                                }
                            </div>
                            <div className="col-md-1 col-2 display-table deal-share">
                                {/* {props.display_type === 'cart' && (
                                    <a href={`/book-lab-test/detail/${props.data.id}/${stringToUrl(props.data.name)}`} target="_blank" rel="noreferrer">
                                        <span><i className="fa fa fa-external-link"></i></span>
                                    </a>
                                )} */}
                            </div>
                            
                            <div className="col-md-11 col-10">
                                <div className="row">
                                    <div className="col-md-8 col-12 p-0">
                                        <p className="fade-title text-start pt-3" style={{fontWeight: 600}}>{props.data.measure}</p>
                                        <h4 className="product-price pt-2">₹{props.data.price}{" "}
                                        {props.data.mrp > 0 && props.data.mrp > props.data.price &&  <del className="product-old-price">₹{props.data.mrp}</del>}
                                        {calculatePercent(props.data.price, props.data.mrp, true)}
                                        </h4>
                                    </div>

                                    <div className="col-md-4 col-12 p-0">
                                        {(props.display_type === 'checkout')&& (
                                            <>Quantity: <b>{props.data.quantity}</b></>
                                        )}
                                        {(props.display_type === 'cart_test'
                                            || props.display_type === 'cart_medicine'
                                            || props.display_type === 'cart_alter_med'
                                        ) && (
                                            <>
                                            <div className="add-to-cart display-i-flex">
                                            {(props.itemLoader[props.data[props.addCartKey]] 
                                                && props.itemLoader[props.data[props.addCartKey]] === true)
                                                ?
                                                    <button 
                                                        className="add-to-cart-btn"
                                                        onClick={(e) => props.addToCart(e, props.data[props.addCartKey], 1)}
                                                        disabled={true}
                                                    >
                                                        <LoaderMedium size={25}/>
                                                    </button>
                                                :
                                                    <>
                                                        {/* Final */}
                                                        {props.cartData[props.data[props.addCartKey]] > 0
                                                            ?
                                                            <>
                                                            <Dropdown className="drop-btn">
                                                                <Dropdown.Toggle variant="success" className="add-to-cart-btn text-start">
                                                                    {props.cartData[props.data[props.addCartKey]]} 
                                                                    {props.display_type === 'cart_test'
                                                                        ?
                                                                            <>&nbsp;Patient{props.cartData[props.data[props.addCartKey]] > 1 ? 's' : ''}</>
                                                                        :
                                                                            <>&nbsp;Quantity</>
                                                                    }
                                                                    
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu popperConfig={popperConfigDrop}>
                                                                    <Dropdown.Header>{props.display_type === 'cart_test' ? 'Book For' : 'Quantity'}</Dropdown.Header>
                                                                    {dropCount.map((book_count, index_book) => (
                                                                        <Dropdown.Item
                                                                            key={`book_count${props.data[props.addCartKey]}_${index_book}`}
                                                                            active={props.cartData[props.data[props.addCartKey]] === book_count ? true : false}
                                                                            onClick={(e) => props.addToCart(e, props.data[props.addCartKey], book_count)}
                                                                            disabled={props.cartData[props.data[props.addCartKey]] === book_count ? true : false}
                                                                        >{book_count}
                                                                        {props.display_type === 'cart_test'
                                                                            ?
                                                                                <>&nbsp;Patient{book_count > 1 ? 's' : ''}</>
                                                                            :   <>&nbsp;Qty</>
                                                                        }
                                                                        </Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            {/* <div className="custom-dropdown pt-3">
                                                                <p className="select-custom"><span>{props.cartData[props.data.id]} Patient{props.cartData[props.data.id] > 1 ? 's' : ''}</span><i className="fa fa-chevron-down"></i></p>
                                                                <div className="dropdown-content">
                                                                    {dropCount.map((book_count, index_book) => (
                                                                        <button
                                                                            key={`book_count${props.data.id}_${index_book}`}
                                                                            onClick={(e) => props.addToCart(e, props.data.id, book_count)}
                                                                        >
                                                                            {book_count} Patient{book_count > 1 ? 's' : ''}
                                                                        </button>
                                                                    ))}
                                                                </div>
                                                            </div> */}
                                                            
                                                            {/* <div className="select-wrapper">
                                                                <select>
                                                                    <option disabled>Book For</option>
                                                                    {dropCount.map((book_count, index_book) => (
                                                                        <option
                                                                            key={`book_count${props.data.id}_${index_book}`}
                                                                            active={props.cartData[props.data.id] === book_count ? true : false}
                                                                            onClick={(e) => props.addToCart(e, props.data.id, book_count)}
                                                                        >{book_count} Patient{book_count > 1 ? 's' : ''}</option>
                                                                    ))}
                                                                </select>
                                                            </div> */}
                                                            <button
                                                                className={`delete ms-5 ${props.data.available_type === 0 ? 'remove' : ''}`}
                                                                onClick={(e) => props.addToCart(e, props.data[props.addCartKey], 0)}
                                                            ><i className="fa fa-trash"></i></button>
                                                            </>
                                                            :
                                                            <button 
                                                                className="add-to-cart-btn"
                                                                onClick={(e) => props.addToCart(e, props.data[props.addCartKey], 1)}

                                                            >
                                                                <i className="fa fa-shopping-cart"></i>&nbsp;&nbsp;Add to Cart
                                                            </button>
                                                        }
                                                    </>
                                            }



                                                 {/*<div className="add-to-cart-custom" style={{display: "inline-flex"}}>
                                                    <div className="cart-btn-wrap">
                                                       {props.data.available_type !== 0 && (
                                                            <button
                                                                className="plus"
                                                                onClick={(e) => props.addToCart(e, props.data.product_id, props.data.product_detail_id, props.cart_data[props.data.product_detail_id]-1, 'minus')}
                                                            >
                                                                <i className="fa fa-minus"></i></button>
                                                        )}&nbsp;&nbsp;
                                                            <p className="qty">
                                                                <span>
                                                                    {(props.item_loader[props.data.product_detail_id] 
                                                                        && props.item_loader[props.data.product_detail_id] === true)
                                                                        ? <LoaderMedium size={18}/>
                                                                        : (<>{props.cart_data[props.data.product_detail_id]}</>)
                                                                    }
                                                                </span>
                                                        </p>&nbsp;&nbsp;
                                                        {props.data.available_type === 1 && (
                                                            <button
                                                                className="minus"
                                                                onClick={(e) => props.addToCart(e, props.data.product_id, props.data.product_detail_id, props.cart_data[props.data.product_detail_id]+1, 'plus')}
                                                            ><i className="fa fa-plus"></i></button>
                                                        )}
                                                        {props.data.available_type !== 1 && (
                                                            <button
                                                                className={`minus ${props.data.available_type === 0 ? 'remove' : ''}`}
                                                                onClick={(e) => props.addToCart(e, props.data.product_id, props.data.product_detail_id, 0, 'minus')}
                                                            ><i className="fa fa-trash"></i></button>
                                                        )}
                                                    </div>
                                                </div> */}
                                            </div>
                                            <>{props.data.error !== '' && <p className="error mb-0 pt-1">{props.data.error}</p>}</>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                           
                            <div className="col-md-1 col-2">
                            </div>
                        </div>
                    </div>
                </div>
                {props.display_type === 'cart_medicine' 
                    && typeof props.data.compo_data !== "undefined"
                    && props.data.compo_data.length > 0
                    &&
                    (<div className="row">
                        {/* <p>Alternatives for PAN D CAP<br/>with same composition & strength</p> */}
                        <div className="text-end">
                            <button
                                className="btn btn-sky"
                                onClick={() => props.toggleModal('alternate', true, props.data.compo_data, props.data.name)}
                            >{props.data.compo_data.length} Generic {props.data.compo_data.length > 1 ? 'Alternates' : 'Alternate'} Available <i className="fa fa-chevron-right"></i>
                            </button>
                        </div>
                    </div>)}
            </div>
        {/* )} */}
        </>
        // {/* // </a> */}
    );
}

export default ListingRow;