import React , {useState, useEffect} from 'react';
import { useSelector} from 'react-redux';
// import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import logo from '../assets/images/logo.png';
const winWidth = window.innerWidth;

function Header() {
    let oldScrollY = 0;
    const cartReduxData = useSelector(state => state.stateUpdate);
    const isAuth = localStorage.getItem("token") ? true : false;
    //Query Default
    var defaultKeyword = '';
    var defaultSelect = 'medicines';
    // const urlQuryString = window.location.href;
    const urlPath = window.location.pathname;
    const urlQuryString = window.location.search
    const urlParam = new URLSearchParams(urlQuryString);
    const urlTypeQuery = urlParam.get('keyword');
    const searchType = urlParam.get('search_type');
    if(searchType !== null){
        defaultSelect = searchType;
    }else{
        const urlPathArray = urlPath.split('/');
        if(urlPathArray.length > 1 &&
            urlPathArray[1] === 'book-lab-test'
        ){
            defaultSelect = 'test';
        }
    }
    console.log(defaultSelect, 'defaultSelect')
    
    if(urlTypeQuery && urlTypeQuery !== ''){
        defaultKeyword = urlTypeQuery;
    }
    const [keyword, setKeyword] = useState(defaultKeyword);
    const [searchSelect, setSearchSelect] = useState(defaultSelect);
    const [showLogo, setShowLogo] = useState(true);
    // const [dropdownToggle, setDropdownToggle] = useState(false);
    const searchProduct = (e) => {
        e.preventDefault();
        if(searchSelect === 'test'){
            window.location.href = `/book-lab-test/search?keyword=${keyword}`;
        }else{
            window.location.href = `/order-medicines/search?keyword=${keyword}`;
        }
    }
    const updateInput = (e, type) => {
        if(type === 'input'){
            setKeyword(e.target.value);
        }else if(type === 'select'){
            setSearchSelect(e.target.value);
        }
    }
    // const showDropdown = (e)=>{
    //     setDropdownToggle(!dropdownToggle);
    // }
    // const hideDropdown = e => {
    //     setDropdownToggle(false);
    // }  
	const controlHeader = () => {        
        oldScrollY = window.scrollY;
    };

    const toggleHeaderSty = () => {
        const scrolled = document.documentElement.scrollTop;
        let selectHeader = document.querySelector('#header');
        if(selectHeader){
                if (scrolled > 100){
                    selectHeader.classList.add('header-scrolled');
                    if(winWidth < 400){
                        if(window.scrollY > oldScrollY) {
                            setShowLogo(false);
                        } else {
                            setShowLogo(true);
                        }
                    }
                }
                // else if (scrolled <= 100){
                //     setShowLogo(true);
                //     selectHeader.classList.remove('header-scrolled');
                // }
                else{
                    setShowLogo(true);
                    selectHeader.classList.remove('header-scrolled');
                }
        }
        
    };
    //Toggle Mobile NavBar
    // const toggleMobNav = () => {
    //     try{
    //         const selectNavBar = document.querySelector('#navbar');
    //         const selectMobIcon = document.querySelector('.mobile-nav-toggle');
    //         selectNavBar.classList.toggle('navbar-mobile');
    //         selectMobIcon.classList.toggle('bi-list');
    //         selectMobIcon.classList.toggle('bi-x');
    //     }catch(e){
    //         // console.log('error opening mob navbar');
    //     }
    // };
    //Activate Mobile Dropdown
    const activateMobDrop = (evt) => {
        try{
            evt.preventDefault();
            // if (evt.target !== this) return;
            const selectNavBarId = document.querySelector('#navbar');
            if(selectNavBarId
                && selectNavBarId.classList.contains('navbar-mobile')
            ){
                const nextSib = evt.target.nextElementSibling;
                if(nextSib){
                    nextSib.classList.toggle('dropdown-active');
                }
            }
        }catch(e){
            // console.log(e);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', toggleHeaderSty);
        window.addEventListener('scroll', controlHeader);
        const selectMobDrop = document.querySelectorAll('.navbar .dropdown > a');
        if(selectMobDrop.length){
            for (var i = 0 ; i < selectMobDrop.length; i++) {
                selectMobDrop[i].addEventListener('click', activateMobDrop ) ; 
            }
        }

        return () => {
            window.removeEventListener('scroll', toggleHeaderSty);
            window.removeEventListener('scroll', controlHeader);
            if(selectMobDrop.length){
                for (var i = 0 ; i < selectMobDrop.length; i++) {
                    selectMobDrop[i].removeEventListener('click', activateMobDrop ) ; 
                }
            }
        }
  }, []);
  return (
    <header>
        <div className="top-nav">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <a href={`https://wa.me/${process.env.REACT_APP_WHATSAPP_NUM}`} target="_blank">
                            <p>
                                <span className="btn-whatsapp-pulse btn-whatsapp-pulse-border"><i className="lni lni-whatsapp"></i></span>
                                <span>Order Medicines on WhatsApp <span className="mob-txt">{process.env.REACT_APP_WHATSAPP_NUM}</span></span>
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div id="header">
            <div className="container-fluid container-lg">
                <div className="row">
                    <div className={`col-lg-2 col-md-2 col-5 col-sm-3 ${showLogo ? 'd-block' : 'd-none'}`}>
                        <div className="header-logo">
                            <a href="/" className="logo align-items-center mt-1">
                                <img src={logo} alt="JanUpchaar"/>
                                <div className='ms-1 text-start' style={{display: 'inline-grid'}}>
                                    <span>JanUpchaar</span>
                                </div>
                            </a>
                            {/* <div className="menu-toggle">
                            <a href="#" className="d-md-none d-block">
                                <i className="fa fa-bars"></i>
                            </a>
                            </div> */}
                            {/* <Dropdown className="menu-toggle float-start d-lg-none d-block">
                                <Dropdown.Toggle id="dropdown-basic">
                                <i className="fa fa-bars"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {isAuth
                                        ?
                                        <>
                                            <Dropdown.Item href="/my-account">My Profile</Dropdown.Item>
                                            <Dropdown.Item href="/my-order">My Orders</Dropdown.Item>
                                            <Dropdown.Item href="/my-address">My Addresses</Dropdown.Item>
                                            <Dropdown.Item href="/change-password">Change Password</Dropdown.Item>
                                            <Dropdown.Item href="/logout">Logout</Dropdown.Item>
                                        </>
                                        :
                                        <>
                                            <Dropdown.Item href="/login">Login/Register</Dropdown.Item>
                                        </>
                                    }
                                </Dropdown.Menu>
                            </Dropdown> */}
                            {/* <div className="header-ctn float-end d-lg-none d-block">
                                <div>
                                <a href="/my-cart">
                                    <i className="fa fa-shopping-cart"></i>
                                    <div className="qty" style={{right: -13}}>{cartReduxData.cart_count}</div>
                                </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-8 col-sm-7 text-center order-1 order-sm-0">
                        <div className="header-search ms-md-3">
                            <form>
                                <div>
                                    <select 
                                        className="input-select"
                                        onChange={(e) => updateInput(e, 'select')}
                                        value={searchSelect}
                                    >
                                        <option value="medicines">Medicines</option>
                                        <option value="test">Lab Test</option>
                                    </select>
                                </div>
                                <div className="search-box">
                                    <input className="input" placeholder="Search here" maxLength={200} value={keyword} onChange={(e) => updateInput(e, 'input')}/>
                                    <button className="search-btn" onClick={(event) => searchProduct(event)}>Search</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6 clearfix pt-2 pt-lg-0 d-md-none d-block">
                        <div className="header-ctn-wrapper justify-content-center">
                            <div className="header-ctn header-dropdown">
                                <div className="">
                                    <a href="/order-medicines">
                                        <button className="btn btn-success btn-glow">
                                            <i className="fa fa fa-capsules"></i>&nbsp;
                                            <span>Order Medicines</span>
                                        </button>
                                    </a>
                                </div>
                                <div className="">
                                    <a href="/book-lab-test">
                                        <button className="btn btn-success btn-glow">
                                            <i className="fa fa-vials"></i>&nbsp;
                                            <span>Book Lab Test</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className={`col-lg-5 col-md-2 col-7 col-sm-2 clearfix pt-2 pt-md-3 pt-md-2 pt-lg-0  order-0 order-sm-1 justify-content-md-center justify-content-end align-items-center d-grid ${showLogo ? 'd-block' : 'd-none'}`}>
                        <div className="header-ctn-wrapper">
                            <div className="header-ctn header-dropdown">
                                <div className="d-lg-block d-none">
                                    <a href="/order-medicines">
                                        <button className="btn btn-success btn-glow">
                                            <i className="fa fa fa-capsules"></i>&nbsp;
                                            <span>Order Medicines</span>
                                        </button>
                                    </a>
                                </div>
                                <div className="d-lg-block d-none">
                                    <a href="/book-lab-test">
                                        <button className="btn btn-success btn-glow">
                                            <i className="fa fa-vials"></i>&nbsp;
                                            <span>Book Lab Test</span>
                                        </button>
                                    </a>
                                </div>
                                {!isAuth && (
                                    <>
                                        <div>
                                            <a href="/login">
                                                <i className="fa fa-user"></i>
                                                <span className="d-none d-lg-block">Login/Register</span>
                                            </a>
                                        </div>
                                    </>
                                )}
                                {/* <div>
                                    <a href="/my-cart">
                                        <i className="fa fa-shopping-cart"></i>
                                        <span>My Cart</span>
                                        <div className="qty">{cartReduxData.cart_count}</div>
                                    </a>
                                </div> */}
                                {isAuth && (
                                    <>
                                        {/* <div>
                                            <a href="/my-account">
                                                <i className="fa fa-user-circle"></i>
                                                <span>My Account</span>
                                            </a>
                                        </div> */}
                                        <div>
                                        <Dropdown
                                            className="menu-toggle"
                                            // show={dropdownToggle}
                                            // onMouseEnter={showDropdown} 
                                            // onMouseLeave={hideDropdown}
                                        >
                                            <Dropdown.Toggle>
                                                <div className="p-1 d-flex d-lg-grid" style={{display: 'grid'}}>
                                                    <i className="fa fa-user-circle"></i>
                                                    <span style={{display: 'inline-flex'}}><span className="d-none d-lg-block">My Account</span> &nbsp;<i className="fa fa-caret-down"></i></span>
                                                </div>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <>
                                                    <Dropdown.Item href="/my-account">My Profile</Dropdown.Item>
                                                    <Dropdown.Item href="/my-order/medicine">My Medicine Orders</Dropdown.Item>
                                                    <Dropdown.Item href="/my-order/lab">My Lab Orders</Dropdown.Item>
                                                    <Dropdown.Item href="/my-address">My Addresses</Dropdown.Item>
                                                    <Dropdown.Item href="/change-password">Change Password</Dropdown.Item>
                                                    <Dropdown.Item href="/logout">Logout</Dropdown.Item>
                                                </>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        </div>
                                    </>
                                )}
                                <div>
                                    <a href="/my-cart">
                                        <i className="fa fa-shopping-cart"></i>
                                        <span className="d-none d-lg-block">My Cart</span>
                                        <div className="qty">{cartReduxData.cart_count}</div>
                                    </a>
                                </div>
                                {/* {isAuth && (
                                    <div>
                                        <a href="/logout">
                                            <i className="fa fa-power-off"></i>
                                            <span>Logout</span>
                                        </a>
                                    </div>
                                )} */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="menu-toggle">
                        <a href="#">
                            <i className="fa fa-bars"></i>
                            <span>Menu</span>
                        </a>
                    </div> */}
                </div>
                {/* <div className="row">
                    <div className="col-6">

                    </div>
                </div> */}
            </div>
        </div>
    </header>
  );
}

export default Header;