import React , {useState, useEffect} from 'react';
import './../../assets/css/pages/details.css';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import {post_api_call, error_api_call} from '../../actions/action-creators/common';
import {update_cart_count} from '../../actions/action-creators/state-update';
import {LoaderMedium} from './../../components/loader';
import {SetMetaData} from './../../helper/page-title';
import { Card, Tabs, Tab } from 'react-bootstrap';
import NotFound from './../404';
import TestRow from './../../components/list/test-row';
import {calculatePercent, urlToString} from './../../helper/custom-function';
import {alertResponseMessage} from '../../actions/action-creators/response';
import "./../../assets/css/pages/detail.css";

export default function TestDetail(){
    const dispatch = useDispatch();
    const deviceID = localStorage.getItem("device_id");
    let params = useParams();
    const testIdParam = params.id || '';
    const dropCount = [0, 1, 2, 3, 4, 5];
    var pageTitle = params.url || 'Book Lab Test';
	pageTitle = urlToString(pageTitle);
	SetMetaData(pageTitle, "", "");
    const [pageLoading, setPageLoader] = useState(true);
    //Main Product Data
    const [mTestData, setMainTestData] = useState({});
    const [itemLoaderMain, setMainItemLoader] = useState(false);
    const [cartDataMain, setCartMainData] = useState({});
    //Similar Test
    const [matchingTest, setMatchingTest] = useState([]);
    const [cartDataMatch, setCartMatchData] = useState({});
    //Loader
    const [itemLoader, setItemLoader] = useState({});
    const [tabKey, setTabKey] = useState("test_list");
    const [showNotFoundPage, setShowNotFoundPage] = useState(false);
    const limit = 5;

	//Get Product Details  //Load Matching Lab Test
    const fetchAPIData = async(type) => {
        try{
            if(type === 'test_details'){
                const testApiData = await post_api_call(`lab-test/${testIdParam}`, {device_id: deviceID});
                if(testApiData.status === 200){                    
                    setMainTestData(testApiData.data);
                    setCartMainData({[testIdParam]: testApiData.data.cart_quantity ? parseInt(testApiData.data.cart_quantity) : 0})
                }else if(testApiData.status === 404){
                    setShowNotFoundPage(true);
                }
                setPageLoader(false);
            }else if(type === 'matching_test'){
                //Load Matching Test
                const matchingTestList = await post_api_call(`lab-test-similar/${testIdParam}`,
                                                        {limit,
                                                        // page: 1,
                                                        order_by: 'low',
                                                        device_id: deviceID
                                                    });
                                                    
                if(matchingTestList.status === 200 
                    && matchingTestList.data.length > 0
                ){
                    var cartDetails = {};
                    matchingTestList.data.forEach(test_data => {
                        cartDetails[test_data.id] = test_data.cart_quantity ? parseInt(test_data.cart_quantity) : 0;
                    });

                    setCartMatchData({...cartDataMatch, ...cartDetails});
                    setMatchingTest(matchingTestList.data);
                }
            }
        }catch(e){
            alertResponseMessage({alert_type: 2, message: "Error Loading Test Details! Check back after some times"});
            // console.log(e);
            // setProductLoading(false);
            error_api_call({error: `test/detail|fetchAPIData|${e}`});
        }
    }
    //Update Item Cart Quantity
    const addToCart = async(e, id, qty, add_type="match") => {
        e.preventDefault();
		try{
			// e.target.disabled = true;
            if(add_type === 'main'){
                setMainItemLoader(true);
            }else{
                setItemLoader({...itemLoader, [id]: true});
            }
			
			//Update Cart Item API Call
			const cartApiData = await post_api_call('update-cart-test', 
													{device_id: deviceID,
														id,
														quantity: qty
													});
			if(cartApiData.status === 200){
				//Notify Msg
				const message = cartApiData.message; 
				if(cartApiData.success_status === 1
					|| cartApiData.success_status === 2
				){
					//Success
					alertResponseMessage({alert_type: 1, message});
				}else{
					//Error
					alertResponseMessage({alert_type: 2, message});
				}

                if(add_type === 'main'){
                    setCartMainData({...cartDataMain, [id]: qty});
                }else{
                    setCartMatchData({...cartDataMatch, [id]: qty});
                }

				if(cartApiData.data.cart_count !== ''){
					dispatch(update_cart_count({cart_count: cartApiData.data.cart_count}, cb_cart => {}));
				}
			}

            if(add_type === 'main'){
                setMainItemLoader(false);
            }else{
                setItemLoader({...itemLoader, [id]: false});
            }
			// e.target.disabled = false;
		}catch(e){
			// console.log(e)
			// e.target.disabled = false;
            alertResponseMessage({alert_type: 2, message: "Error Updating Cart"});
			error_api_call({error: `test/detail|addToCart|${e}`});
        }
	}
	//Intial Fetching
	useEffect(() => {
        fetchAPIData('test_details');
        fetchAPIData('matching_test');
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="container-fluid container-lg">
                {/* Show Loader */}
                {pageLoading 
					?
						<div className="text-center mt-5">
							<LoaderMedium size={65} />
						</div>
					:
                        <>
                            {mTestData && typeof mTestData.title !== "undefined" && mTestData.title !== '' && (
                                <div className="mb-5 mt-4">
                                    <div className="row justify-content-around">
                                        {/* <div className={`col-md-${(matchingTest.length > 0) ? '7' : '12'} col-12 col-sm-12`}> */}
                                        <div className={`col-md-7 col-12 col-sm-12`}>
                                            <div className="row card">
                                                <div className={`col-md-12 col-12 col-sm-12`}>
                                                    <div className="product pro-detail p-3 pb-0 me-md-3 m-2">
                                                        <div className="row">
                                                            <div className="col-md-1 col-2 col-sm-2">
                                                                <img className="icon" src={mTestData.organ_icon} alt={mTestData.title} />
                                                            </div>
                                                            <div className="col-md-11 col-10 col-sm-10">
                                                                <p className="product-brand mb-2">{mTestData.lab_name}</p>
                                                            
                                                                <h1 className="product-title">{mTestData.title}</h1>
                                                                {/* Parameters or Test List */}
                                                                <div className="mb-2 mt-3 param-list">
                                                                    {mTestData.test_list_count < 2 //For Single Test (Not Pacakge)
                                                                        ?
                                                                            (mTestData.test_list.length > 0 
                                                                                && typeof mTestData.test_list[0].parameters !== "undefined"
                                                                                && mTestData.test_list[0].parameters.length > 0
                                                                            )
                                                                            ?
                                                                            <>
                                                                                Includes
                                                                                {/* <div className="ps-4"> */}
                                                                                    {mTestData.test_list[0].parameters.slice(0,3).map((param, index_param) => (
                                                                                        <span key={`param${index_param}${param.id}`}>
                                                                                            {index_param !== 0 ? ',' : ''}{' ' + param.parameter}
                                                                                        </span>
                                                                                    ))}
                                                                                    {/* {mTestData.test_list[0].parameters.length > 3 && ( */}
                                                                                        <a href="#test-details" className="param-link"><span>&nbsp;{'...'} (<b>{mTestData.test_list[0].parameters.length}</b> Parameter{mTestData.test_list[0].parameters.length > 1 ? 's' : ''}) &nbsp;<i className="fa fa-chevron-right"></i>
                                                                                        </span></a>
                                                                                    {/* )} */}
                                                                                {/* </div> */}
                                                                            </>
                                                                            :
                                                                            <>Includes {mTestData.test_list_count} Test</>
                                                                        :
                                                                            // <div className={`test-param ${mTestData.test_list_count > 3  ? 'h-100' : 'h-75'}`}>
                                                                            <>
                                                                                Includes
                                                                                {/* <div className="ps-4"> */}
                                                                                    {mTestData.test_list.slice(0,3).map((test, index_test) => (
                                                                                        <span key={`test${index_test}${test.id}`}>
                                                                                            {index_test !== 0 ? ',' : ''}{' ' + test.name}
                                                                                        </span>
                                                                                    ))}
                                                                                    <a href="#test-details" className="param-link">
                                                                                        <span>
                                                                                            {/* {mTestData.test_list_count > 3 && ( */}
                                                                                            &nbsp;{'...'} (<b>{mTestData.test_list_count}</b> Test{mTestData.test_list_count > 1 ? 's' : ''}
                                                                                            {/* )} */}
                                                                                            &nbsp;<b>{' - '}{mTestData.param_count}</b> Parameter{mTestData.param_count > 1 ? 's' : ''})&nbsp;<i className="fa fa-chevron-right"></i>
                                                                                        </span>
                                                                                    </a>
                                                                                {/* </div> */}
                                                                            </>
                                                                    }
                                                                </div>
                                                                {/* Price */}
                                                                {(mTestData.price !== null && mTestData.mrp !== null) && (
                                                                    <>
                                                                        <h4 className="product-price">
                                                                            ₹{mTestData.price} {mTestData.mrp > 0 && mTestData.mrp > mTestData.price &&  <del className="product-old-price">₹{mTestData.mrp}</del>}
                                                                            {calculatePercent(mTestData.price, mTestData.mrp, true)}
                                                                        </h4>
                                                                    </>
                                                                )}
                                                                {/* Add to Cart */}
                                                                <div className="divider">
                                                                    <div className="add-to-cart">
                                                                    {(itemLoaderMain === true)
                                                                        ?
                                                                            <button 
                                                                                className="add-to-cart-btn"
                                                                                onClick={(e) => addToCart(e, mTestData.id, 1, 'main')}
                                                                                disabled={true}
                                                                            >
                                                                                <LoaderMedium size={25}/>
                                                                            </button>
                                                                        :
                                                                            <>
                                                                                {/* Final */}
                                                                                {cartDataMain[mTestData.id] > 0
                                                                                    ?
                                                                                    <Dropdown className="drop-btn">
                                                                                        <Dropdown.Toggle variant="success" className="add-to-cart-btn text-start">
                                                                                            {cartDataMain[mTestData.id]} Patient{cartDataMain[mTestData.id] > 1 ? 's' : ''}
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>
                                                                                            <Dropdown.Header>Book For</Dropdown.Header>
                                                                                            {dropCount.map((book_count, index_book) => (
                                                                                                <Dropdown.Item
                                                                                                    key={`book_count${mTestData.id}_${index_book}`}
                                                                                                    active={cartDataMain[mTestData.id] === book_count ? true : false}
                                                                                                    onClick={(e) => addToCart(e, mTestData.id, book_count, 'main')}
                                                                                                >{book_count} Patient{book_count > 1 ? 's' : ''}</Dropdown.Item>
                                                                                            ))}
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                    :
                                                                                    <button 
                                                                                        className="add-to-cart-btn"
                                                                                        onClick={(e) => addToCart(e, mTestData.id, 1, 'main')}

                                                                                    >
                                                                                        <i className="fa fa-shopping-cart"></i>&nbsp;&nbsp;Add to Cart
                                                                                    </button>
                                                                                }
                                                                                
                                                                            </>
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-12 col-sm-12 item-desc mb-3" id="test-details">
                                                    <h2 className='mt-4 ms-3'>Lab Test Description</h2>
                                                    <Tabs
                                                        activeKey={tabKey}
                                                        onSelect={(k) => setTabKey(k)}
                                                    >
                                                        {/* Test Includes */}
                                                        <Tab eventKey="test_list" title={"Tests Included"}>
                                                            <div className="responsive ps-4 pe-4 pt-3 mb-3">
                                                                <p><b>Package Includes {mTestData.test_list_count} Test{mTestData.test_list_count > 1 ? 's' : ''} ({mTestData.param_count} Parameter{mTestData.param_count > 1 ? 's' : ''})</b> :</p>
                                                                {/* <ul className="list-group-bullet ms-4"> */}
                                                                <div className="ms-3">
                                                                    {mTestData.test_list.map((test, index_test) => (
                                                                        <div key={`test${index_test}${test.id}`} className="mt-3">
                                                                            <span>
                                                                                <b>{test.name}</b>
                                                                                {(test.parameters
                                                                                    && test.parameters.length > 0)
                                                                                    &&
                                                                                    <> ({test.parameters.length} parameter{test.parameters.length > 1 ? 's' : ''})</>
                                                                                }
                                                                            </span>
                                                                            {(test.parameters
                                                                                && test.parameters.length > 0)
                                                                                && (
                                                                                    <ul className="list-group-bullet ms-4">
                                                                                        {test.parameters.map((t_param, index__param) => (
                                                                                            <li key={`test_param${index__param}${t_param.id}`}>
                                                                                                {t_param.parameter}
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                {/* </ul> */}
                                                            </div>
                                                        </Tab>
                                                        {/* Description */}
                                                        <Tab eventKey="desc" title={"Description"}>
                                                            <div className="responsive">
                                                                <p className="ps-4 pe-4 pt-3"></p>
                                                            </div>
                                                        </Tab>
                                                    </Tabs>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Matching Lab Test */}
                                        {matchingTest.length > 0 && (
                                            <div className="col-md-5 col-12 col-sm-12 match-pro">
                                                <Card className="pro-hr mt-4 row mt-md-0">
                                                    <div className="title mb-3">
                                                        <h2>Similar Health Packages</h2>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <TestRow
                                                            data={matchingTest}
                                                            addToCart={addToCart}
                                                            cartData={cartDataMatch}
                                                            itemLoader={itemLoader}
                                                            fetchKey={"product"}
                                                            mdCol={10}
                                                        />
                                                    </div>
                                                </Card>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {showNotFoundPage && (
                                <NotFound type="product" />
                            )}
                        </>
                }
            </div>
        </>
    )
}
