import React from "react";
import {
    Card,
    Col,
    Row
  } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import {SetMetaData} from './../../helper/page-title';

function PaymentFaq(){
    SetMetaData("FAQ-Payment", "", "");

    return (
        <div className="container">
        <Row className="justify-content-center">
            <Col md="9" sm="12">
                <Card className="m-2 mt-4 mb-5">
                    <div className="p-3 static-pages">
                        <h5 className="text-center pt-3">FAQ- Payments</h5>
                        <br/>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>How do I pay for a purchase on {process.env.REACT_APP_NAME}?</Accordion.Header>
                                <Accordion.Body>
                                You can pay using your Netbanking account, credit and debit cards, or cash on delivery (COD) if applicable. For online payment, we have integrated Paytm, the most trusted Payment Partner. Please note that COD is available at selected PIN codes.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What is Cash on Delivery?</Accordion.Header>
                                <Accordion.Body>
                                With COD you can pay in cash at the time of actual delivery of the product at your doorstep, without requiring you to make any advance payment online. You can pay the cash to our logistics partner who delivers the order to you. Please note that this option is only available at selected PIN codes.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>I got charged for my order but I got a message/alert that says Payment Failed. What should I do?</Accordion.Header>
                                <Accordion.Body>
                                Due to technical issues this can happen but don`t worry You will get the total order amount refunded within 1-3 working days. You can Place the Order Again. If you still have an issue, contact us, send an email at <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}><b>{process.env.REACT_APP_SUPPORT_EMAIL}</b></a>.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={''}>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header> Is it safe to make payment on {process.env.REACT_APP_NAME} or use my credit/debit card?</Accordion.Header>
                                <Accordion.Body>
                                Paytm Payment Gateway is integrated for all the transactions on {process.env.REACT_APP_NAME}. It is a secure and trusted payment gateways, you can read <a href="https://paytm.com/about-us/our-policies/" target={"_blank"} rel="noreferrer" className="link-danger">Paytm Terms-Conditions & Policies here</a>.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </Card>
            </Col>
        </Row>
        </div>
    )    
}

export default PaymentFaq;