import React from "react";
import {
    Card,
    Col,
    Row
  } from 'react-bootstrap';
import {SetMetaData} from './../../helper/page-title';

function AboutUs(){
    SetMetaData("About Us", "", "");

    return (
        <div className="container">
            <Row className="justify-content-center">
                <Col md="9" sm="12">
                    <Card className="m-2 mt-4 mb-5">
                        <div className="p-3 static-pages">
                            <h5 className="text-center pt-3">About Us</h5>
                            <br/>
                            <p>"{process.env.REACT_APP_NAME}" is a one-stop platform to Order Medicines & Book Lab Test. We are trying to make healthcare a hassle-free experience for you. Get your allopathic, ayurvedic, homeopathic medicines, vitamins & nutrition supplements and other health-related products delivered at home.</p>
                            <p className="text-center">In case of any queries, feel free to Contact Us. Just Drop an email at <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}><b>{process.env.REACT_APP_SUPPORT_EMAIL}</b></a>, We will get back to you within 24 hrs.</p>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    )    
}

export default AboutUs;