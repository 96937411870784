import Dropdown from 'react-bootstrap/Dropdown';
import { popperConfigDrop } from "../../config/popper";
import {LoaderMedium} from '../loader';
import {calculatePercent, stringToUrl} from '../../helper/custom-function';
import Slider from "react-slick";

function TestCardSlider(props){
    const dropCount = [0, 1, 2, 3, 4, 5];

    return (
        <>
        <Slider {...props.sliderItemSetting}>
            {props.data.map((data, index) => (
                <div className={`col-md-${props.mdCol ? props.mdCol : 2} col-12 col-sm-6 pe-2 ps-2 mb-3`} key={index}>
                    <div className="product product-test" style={{background: 'white', minHeight: 350}}>
                        <div className="p-1">
                            {(data.price !== null && data.mrp !== null) && (
                                calculatePercent(data.price, data.mrp)
                            )}
                        </div>
                        <a href={`/book-lab-test/detail/${data.id}/${stringToUrl(data.title)}`}>
                            <div className="product-body">
                                {/* <p className="product-category mb-2">{data.lab_name}</p> */}
                                <h3 className="product-name">{data.title}</h3>
                                <img src={data.organ_icon} alt={data.title} />
                                {/* Test */}
                                <div className="test-param">
                                {data.test_list_count < 2
                                    ?
                                        (data.test_list.length > 0 
                                            && typeof data.test_list[0].parameters !== "undefined"
                                            && data.test_list[0].parameters.length > 0
                                        )
                                        ?
                                        <>
                                            Includes <b>{data.test_list[0].parameters.length}</b> Parameter{data.test_list[0].parameters.length > 1 ? 's' : ''}
                                            <div className="ps-4">
                                                <ul>
                                                {data.test_list[0].parameters.slice(0,3).map((param, index_param) => (
                                                    <li key={`param${index_param}${param.id}`}>
                                                        {param.parameter}
                                                    </li>
                                                ))}
                                                </ul>
                                                {data.test_list[0].parameters.length > 3 && (<span className="more">&nbsp;&nbsp;+ More</span>)}
                                            </div>
                                        </>
                                        :
                                        <>Includes <b>{data.test_list_count}</b> Test</>
                                    :
                                        // <div className={`test-param ${data.test_list_count > 3  ? 'h-100' : 'h-75'}`}>
                                        <>
                                            Includes <b>{data.test_list_count}</b> Test{data.test_list_count > 1 ? 's' : ''}
                                            <div className="ps-4">
                                                <ul>
                                                {data.test_list.slice(0,3).map((test, index_test) => (
                                                    <li key={`test${index_test}${test.id}`}>
                                                        {test.name}
                                                    </li>
                                                ))}
                                                </ul>
                                                {data.test_list_count > 3 && (<span className="more">&nbsp;&nbsp;+ More</span>)}
                                            </div>
                                        </>
                                }
                                </div>
                                {/* <div className="product-rating">
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                </div> */}
                                {/* <div className="product-btns">
                                    <button className="add-to-wishlist"><i className="fa fa-heart-o"></i><span className="tooltipp">add to wishlist</span></button>
                                    <button className="add-to-compare"><i className="fa fa-exchange"></i><span className="tooltipp">add to compare</span></button>
                                    <button className="quick-view"><i className="fa fa-eye"></i><span className="tooltipp">quick view</span></button>
                                </div> */}
                            </div>
                            </a>
                            {(data.price !== null || data.mrp !== null) && (
                                <h4 className="product-price text-center">
                                    ₹{data.price} {data.mrp > 0 && data.mrp > data.price &&  <del className="product-old-price">₹{data.mrp}</del>}
                                </h4>
                            )}
                            <div className="add-to-cart">
                                {(props.itemLoader[data.id] 
                                    && props.itemLoader[data.id] === true)
                                    ?
                                        <button 
                                            className="add-to-cart-btn"
                                            onClick={(e) => props.addToCart(e, data.id, 1)}
                                            disabled={true}
                                        >
                                            <LoaderMedium size={25}/>
                                        </button>
                                    :
                                        <>
                                            {/* Final */}
                                            {props.cartData[data.id] > 0
                                                ?
                                                <Dropdown className="drop-btn">
                                                    <Dropdown.Toggle variant="success" className="add-to-cart-btn text-start">
                                                        {props.cartData[data.id]} Patient{props.cartData[data.id] > 1 ? 's' : ''}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu popperConfig={popperConfigDrop}>
                                                        <Dropdown.Header>Book For</Dropdown.Header>
                                                        {dropCount.map((book_count, index_book) => (
                                                            <Dropdown.Item
                                                                key={`book_count${data.id}_${index_book}`}
                                                                active={props.cartData[data.id] === book_count ? true : false}
                                                                onClick={(e) => props.addToCart(e, data.id, book_count)}
                                                                disabled={props.cartData[data.id] === book_count ? true : false}
                                                            >{book_count} Patient{book_count > 1 ? 's' : ''}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                :
                                                <button 
                                                    className="add-to-cart-btn"
                                                    // onClick={(e) => props.addToCart(e, data.lab_test_m_id, props.activeData[data.lab_test_m_id].product_variation_id, 1)}
                                                    onClick={(e) => props.addToCart(e, data.id, 1)}

                                                >
                                                    {(props.itemLoader[data.id] 
                                                        && props.itemLoader[data.id] === true)
                                                        ? <LoaderMedium size={25}/>
                                                        : (<><i className="fa fa-shopping-cart"></i>&nbsp;&nbsp;Add to Cart</>)
                                                    }
                                                    {/* <i className="fa fa-shopping-cart"></i>&nbsp;&nbsp;Add to Cart */}
                                                </button>
                                            }
                                            {/* <div className="custom-dropdown pt-3">
                                                <button type="button" className="select-custom add-to-cart-btn"><span>{props.cartData[data.id]}</span><i className="fa fa-chevron-down"></i></button>
                                                <div className="dropdown-content dropdown-menu">
                                                <h6 className="dropdown-header">Book For</h6>
                                                    {dropCount.map((book_count, index_book) => (
                                                        <button
                                                            key={`book_count${data.id}_${index_book}`}
                                                            onClick={(e) => props.addToCart(e, data.id, book_count)}>
                                                            {book_count} patient{book_count > 1 ? 's' : ''}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div> */}
                                        </>
                                }
                            </div>
                            <div style={{display: 'flow-root', background: '#f5f5f5', bottom: 0, position: 'absolute', width: '100%'}}>
                                <p className="input-info m-2">{data.lab_name}</p>
                            </div>
                    </div>
                </div>
            ))}
        </Slider>
        </>
    );
}

export default TestCardSlider;