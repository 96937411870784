import React , {useState, useEffect} from 'react';
import './../../assets/css/pages/details.css';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import {post_api_call, error_api_call} from '../../actions/action-creators/common';
import {update_cart_count} from '../../actions/action-creators/state-update';
import {LoaderMedium} from './../../components/loader';
import {SetMetaData} from './../../helper/page-title';
import { Card, Tabs, Tab } from 'react-bootstrap';
import NotFound from './../404';
import ProductRow from './../../components/list/product/product-row';
import {calculatePercent, urlToString} from './../../helper/custom-function';
import {alertResponseMessage} from '../../actions/action-creators/response';
import "./../../assets/css/pages/detail.css";
import noImage from './../../assets/images/no-image.png';

export default function ProductDetail(){
    const dispatch = useDispatch();
    const deviceID = localStorage.getItem("device_id");
    let params = useParams();
    const productIdParam = params.id || '';
    const dropCount = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    var pageTitle = params.url || 'Order Medicines';
	pageTitle = urlToString(pageTitle);
	SetMetaData(pageTitle, "", "");
    const [pageLoading, setPageLoader] = useState(true);
    //Main Product Data
    const [mProductData, setMainTestData] = useState({});
    const [itemLoaderMain, setMainItemLoader] = useState(false);
    const [cartDataMain, setCartMainData] = useState({});
    //Similar Test
    const [matchingTest, setMatchingTest] = useState([]);
    const [cartDataMatch, setCartMatchData] = useState({});
    //Loader
    const [itemLoader, setItemLoader] = useState({});
    const [tabKey, setTabKey] = useState("compo");
    const [showNotFoundPage, setShowNotFoundPage] = useState(false);
    const limit = 10;

	//Get Product Details  //Load Matching Lab Test
    const fetchAPIData = async(type) => {
        try{
            if(type === 'product_details'){
                const testApiData = await post_api_call(`product/${productIdParam}`, {device_id: deviceID});
                if(testApiData.status === 200){                    
                    setMainTestData(testApiData.data);
                    setCartMainData({[testApiData.data.product_detail_id]: testApiData.data.cart_quantity ? parseInt(testApiData.data.cart_quantity) : 0})
                }else if(testApiData.status === 404){
                    setShowNotFoundPage(true);
                }
                setPageLoader(false);
            }else if(type === 'matching_medicines'){
                //Load Matching Test
                const matchingTestList = await post_api_call(`similar-composition/${productIdParam}`,
                                                        {limit,
                                                        // page: 1,
                                                        order_by: 'low',
                                                        device_id: deviceID
                                                    });
                                                    
                if(matchingTestList.status === 200 
                    && matchingTestList.data.length > 0
                ){
                    var cartDetails = {};
                    matchingTestList.data.forEach(test_data => {
                        cartDetails[test_data.product_detail_id] = test_data.cart_quantity ? parseInt(test_data.cart_quantity) : 0;
                    });

                    setCartMatchData({...cartDataMatch, ...cartDetails});
                    setMatchingTest(matchingTestList.data);
                }
            }
        }catch(e){
            alertResponseMessage({alert_type: 2, message: "Error Loading Test Details! Check back after some times"});
            // console.log(e);
            // setProductLoading(false);
            error_api_call({error: `product/detail|fetchAPIData|${e}`});
        }
    }
    //Update Item Cart Quantity
    const addToCart = async(e, id, qty, add_type="match") => {
        e.preventDefault();
		try{
			// e.target.disabled = true;
            if(add_type === 'main'){
                setMainItemLoader(true);
            }else{
                setItemLoader({...itemLoader, [id]: true});
            }
			
			//Update Cart Item API Call
			const cartApiData = await post_api_call('update-cart', 
													{device_id: deviceID,
														id,
														quantity: qty
													});
			if(cartApiData.status === 200){
				//Notify Msg
				const message = cartApiData.message; 
				if(cartApiData.success_status === 1
					|| cartApiData.success_status === 2
				){
					//Success
					alertResponseMessage({alert_type: 1, message});
				}else{
					//Error
					alertResponseMessage({alert_type: 2, message});
				}

                if(add_type === 'main'){
                    setCartMainData({...cartDataMain, [id]: qty});
                }else{
                    setCartMatchData({...cartDataMatch, [id]: qty});
                }

				if(cartApiData.data.cart_count !== ''){
					dispatch(update_cart_count({cart_count: cartApiData.data.cart_count}, cb_cart => {}));
				}
			}

            if(add_type === 'main'){
                setMainItemLoader(false);
            }else{
                setItemLoader({...itemLoader, [id]: false});
            }
			// e.target.disabled = false;
		}catch(e){
			// console.log(e)
			// e.target.disabled = false;
            alertResponseMessage({alert_type: 2, message: "Error Updating Cart"});
			error_api_call({error: `product/detail|addToCart|${e}`});
        }
	}
	//Intial Fetching
	useEffect(() => {
        fetchAPIData('product_details');
        fetchAPIData('matching_medicines');
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="container-fluid container-lg">
                {/* Show Loader */}
                {pageLoading 
					?
						<div className="text-center mt-5">
							<LoaderMedium size={65} />
						</div>
					:
                        <>
                            {mProductData && typeof mProductData.name !== "undefined" && mProductData.name !== '' && (
                                <div className="mb-5 mt-4">
                                    <div className="row justify-content-around">
                                        {/* <div className={`col-md-${(matchingTest.length > 0) ? '7' : '12'} col-12 col-sm-12`}> */}
                                        <div className={`col-md-7 col-12 col-sm-12`}>
                                            <div className="row card">
                                                <div className={`col-md-12 col-12 col-sm-12`}>
                                                    <div className="product pro-detail p-3 pb-0 me-md-3 m-2">
                                                        <div className="row">
                                                            <div className="col-md-1 col-2 col-sm-2">
                                                                <img className="icon" src={noImage} alt={mProductData.name} />
                                                            </div>
                                                            <div className="col-md-11 col-10 col-sm-10">
                                                                <p className="product-brand mb-2">Mfg/Mkt: {mProductData.manufacturer}</p>
                                                            
                                                                <h1 className="product-title">{mProductData.name}</h1>
                                                                {/* Parameters or Test List */}
                                                                <div className="mb-2 mt-3 param-list">
                                                                    <u>Composition</u>: &nbsp;
                                                                    {mProductData.composition.map((compo, index_compo) => (
                                                                        <span key={`test${index_compo}${compo.id}`}>
                                                                            {index_compo !== 0 ? '+' : ''}{' ' + compo.name}
                                                                        </span>
                                                                    ))}
                                                                </div>
                                                                <p className="product-category mb-2">{mProductData.packet_name} of {mProductData.unit_name} {mProductData.unit_type}</p>
                                                                {/* Price */}
                                                                {(mProductData.price !== null && mProductData.mrp !== null) && (
                                                                    <>
                                                                        <h4 className="product-price">
                                                                            ₹{mProductData.price} {mProductData.mrp > 0 && mProductData.mrp > mProductData.price &&  <del className="product-old-price">₹{mProductData.mrp}</del>}
                                                                            {calculatePercent(mProductData.price, mProductData.mrp, true)}
                                                                        </h4>
                                                                    </>
                                                                )}
                                                                {/* <p className="product-category mb-2">{mProductData.packet_name} of {mProductData.unit_name} {mProductData.unit_type}</p> */}
                                                                {/* Add to Cart */}
                                                                <div className="divider">
                                                                    <div className="add-to-cart">
                                                                    {(itemLoaderMain === true)
                                                                        ?
                                                                            <button 
                                                                                className="add-to-cart-btn"
                                                                                onClick={(e) => addToCart(e, mProductData.product_detail_id, 1, 'main')}
                                                                                disabled={true}
                                                                            >
                                                                                <LoaderMedium size={25}/>
                                                                            </button>
                                                                        :
                                                                            <>
                                                                                {/* Final */}
                                                                                {cartDataMain[mProductData.product_detail_id] > 0
                                                                                    ?
                                                                                    <Dropdown className="drop-btn">
                                                                                        <Dropdown.Toggle variant="success" className="add-to-cart-btn text-start">
                                                                                            {cartDataMain[mProductData.product_detail_id]} Quantity
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>
                                                                                            <Dropdown.Header>Quantity</Dropdown.Header>
                                                                                            {dropCount.map((book_count, index_book) => (
                                                                                                <Dropdown.Item
                                                                                                    key={`medicine_${mProductData.id}_${index_book}`}
                                                                                                    active={cartDataMain[mProductData.product_detail_id] === book_count ? true : false}
                                                                                                    onClick={(e) => addToCart(e, mProductData.product_detail_id, book_count, 'main')}
                                                                                                >{book_count} Qty{book_count > 1 ? 's' : ''}</Dropdown.Item>
                                                                                            ))}
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                    :
                                                                                    <button 
                                                                                        className="add-to-cart-btn"
                                                                                        onClick={(e) => addToCart(e, mProductData.product_detail_id, 1, 'main')}

                                                                                    >
                                                                                        <i className="fa fa-shopping-cart"></i>&nbsp;&nbsp;Add to Cart
                                                                                    </button>
                                                                                }
                                                                                
                                                                            </>
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-12 col-sm-12 item-desc mb-3" id="test-details">
                                                    <h2 className='mt-4 ms-3'>Medicine Description</h2>
                                                    <Tabs
                                                        activeKey={tabKey}
                                                        onSelect={(k) => setTabKey(k)}
                                                    >
                                                        {/* Composition */}
                                                        <Tab eventKey="compo" title={"Composition"}>
                                                            <div className="responsive">
                                                                <ul className="list-group-bullet pt-3 mb-3 ms-4">
                                                                {/* <div className="ms-3"> */}
                                                                    {mProductData.composition.map((compo, index_compo) => (
                                                                        <li key={`test${index_compo}${compo.id}`}>
                                                                            {index_compo !== 0 ? '+' : ''}{' ' + compo.name}
                                                                        </li>
                                                                    ))}
                                                                {/* </div> */}
                                                                </ul>
                                                            </div>
                                                        </Tab>
                                                        {/* Manufacturer */}
                                                        <Tab eventKey="manu" title={"Manufacturer"}>
                                                            <div className="responsive ps-4 pe-4 pt-3 mb-3">
                                                                {mProductData.manufacturer}
                                                            </div>
                                                        </Tab>
                                                        {/* Description */}
                                                        <Tab eventKey="desc" title={"Description"}>
                                                            <div className="responsive">
                                                                <p className="ps-4 pe-4 pt-3"></p>
                                                            </div>
                                                        </Tab>
                                                    </Tabs>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Matching Lab Test */}
                                        {matchingTest.length > 0 && (
                                            <div className="col-md-5 col-12 col-sm-12 match-pro">
                                                <Card className="pro-hr mt-4 row mt-md-0 m-0 ms-md-2">
                                                    <div className="title mb-3">
                                                        <h2>Medicines with Same Composition</h2>
                                                    </div>
                                                    <div className="h-500 responsive-y">
                                                        <div className="row justify-content-center">
                                                            <ProductRow
                                                                data={matchingTest}
                                                                addToCart={addToCart}
                                                                cartData={cartDataMatch}
                                                                itemLoader={itemLoader}
                                                                fetchKey={"product"}
                                                                mdCol={10}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {showNotFoundPage && (
                                <NotFound type="product" />
                            )}
                        </>
                }
            </div>
        </>
    )
}
