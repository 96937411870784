import React, {useState, useEffect} from 'react';
import {
  Card,
  Container,
  Row,
} from 'react-bootstrap';
import '../../assets/css/pages/form.css';
import {LoaderMedium} from './../../components/loader';
//Axios Call
import {error_api_call, get_api_call} from '../../actions/action-creators/common';
import {SetMetaData} from './../../helper/page-title';

const MyProfile = () => {
  SetMetaData("My Profile", "", "");
  const [userData, setUserData] = useState({});
  //Loader
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    fetchUserData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  const fetchUserData = async() => {
    try{
      const userDetails = await get_api_call('user?type=profile');
      console.log(userDetails, 'userDetails')
      if(userDetails.status === 200){
        setUserData(userDetails.data);
      }
      setPageLoading(false);
    }catch(e){
      error_api_call({error: `account/profile|fetchUserData|${e}`});
    }
  };

  return (
    <div className="c-app c-default-layout align-items-center custom-form">
      <Container style={{minHeight: '66vh'}} className="mb-5">
        {pageLoading 
            ?
                <div className="text-center mt-5">
                    <LoaderMedium size={65} />
                </div>
            :
            <Row className="justify-content-center my-address">
                <div className="col-md-12 col-12 col-sm-12">
                    <div className="section-title mt-5">
                        <h3>My Profile</h3>
                    </div>
                </div>
                <div className="col-md-12 col-12 col-sm-12">
                    <Row className='justify-content-center'>
                        <div className="col-md-6 col-12 col-sm-12 address-card">
                            <Card className="p-2">
                                <Card.Body>
                                    <table className='table table-borderless'>
                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td><b>{userData.first_name} {userData.last_name}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Mobile Number</td>
                                                <td><b>{userData.mobile_number}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td><b>{userData.email}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Card.Body>
                            </Card>
                        </div>
                    </Row>
                </div>
            </Row>
        }
      </Container>
    </div>
  )
}

export default MyProfile
