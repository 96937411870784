import React, {useEffect, useState} from "react";
// import AsyncStorage from '@react-native-async-storage/async-storage';
import { useDispatch } from 'react-redux';
import {
    Col,
    Container,
    Row
  } from 'react-bootstrap';
import {logout} from './../../actions/action-creators/auth';
import {LoaderMedium} from './../../components/loader';
import {alertResponseMessage} from './../../actions/action-creators/response';
import {post_api_call} from '../../actions/action-creators/common';

function Logout() {
    const dispatch = useDispatch();
    const [redirectUrl, setRedirectUrl] = useState(false);
    // const deviceToken = useSelector((state) => state.info.device_token);
    useEffect(()=>{
        _logout();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //Redirect to Respective Page if error
    useEffect(() => {
      let timer = setTimeout(() => {
          if(redirectUrl === true){
              window.location.href = '/';
          }
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }, [redirectUrl]);// eslint-disable-line react-hooks/exhaustive-deps
    const _logout = async() => {
        try{
    //         const fcmToken = deviceToken ? deviceToken : await AsyncStorage.getItem('device_token');
            dispatch(logout({}, async cb_data => {
                if(cb_data.status === 200){
                    alertResponseMessage({alert_type: 1, message: "Logged out Successfully"});
                    await post_api_call('logout', {fetch_type: 'logout'});
                    setRedirectUrl(true);
                    // window.location.href = "/";
    //                 ToastAndroid.show(
    //                     `Logged Out Successfully!`,
    //                     ToastAndroid.LONG,
    //                     ToastAndroid.BOTTOM
    //                 );
    //                 props.navigation.dispatch(StackActions.popToTop());
                }else{
                    alertResponseMessage({alert_type: 2, message: "Error logging out"});
    //                 ToastAndroid.show(
    //                     `Error Logging Out! Please try again`,
    //                     ToastAndroid.LONG,
    //                     ToastAndroid.BOTTOM
    //                 );
                }
            }));
        }catch(e){
            return;
    //         ToastAndroid.show(
    //             `Error Logging Out! Please try again`,
    //             ToastAndroid.LONG,
    //             ToastAndroid.BOTTOM
    //         );
        }
    }

    return (
        <div className="c-app c-default-layout align-items-center custom-form">
            <Container style={{minHeight: '66vh'}}>
                <Row className="justify-content-center">
                    <Col md="4">
                        <div className="p-2 text-center" style={{margin: '100px 0px'}}>
                            <LoaderMedium size={65} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Logout;